import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { calculateReserveAPY, ComputedReserveData } from '@sturdyfi/sturdy-js';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { useDynamicPoolDataContext } from '..';
import { useIncentivesDataContext } from '../hooks/use-incentives-data-context';

export interface ReserveAPYContext {
  reserves: ComputedReserveData[];
}

const ReserveAPYDataContext = React.createContext({} as ReserveAPYContext);

export function ReserveAPYDataProvider({ children }: { children: ReactNode }) {
  const { network } = useProtocolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { reserveReward } = useIncentivesDataContext();

  const [computedReserves, setComputedReserves] = useState<ComputedReserveData[]>([]);

  const getReserveAPY = async () => {
    reserveReward.length == 0 && setComputedReserves(reserves);

    if (
      reserves.length &&
      reserveReward.length &&
      reserveReward.some((rwd) => rwd.underlyingAsset == reserves[0].underlyingAsset)
    ) {
      const results = await calculateReserveAPY(network, reserves, reserveReward);
      if (results.length) {
        setComputedReserves(results);
      }
    }
  };

  useEffect(() => {
    getReserveAPY();
  }, [reserves, reserveReward]);

  return (
    <ReserveAPYDataContext.Provider
      value={{
        reserves: computedReserves,
      }}
    >
      {children}
    </ReserveAPYDataContext.Provider>
  );
}

export const useReserveAPYDataContext = () => useContext(ReserveAPYDataContext);
