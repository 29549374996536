import React from 'react';
import { useIntl } from 'react-intl';
// import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

// import { BorrowRateMode } from '../../../../libs/pool-data-provider/graphql';
// import CustomSwitch from '../../../../components/basic/CustomSwitch';
import MobileCardWrapper from '../../../../components/wrappers/MobileCardWrapper';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
// import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
// import NoData from '../../../../components/basic/NoData';
import Link from '../../../../components/basic/Link';
import DefaultButton from '../../../../components/basic/DefaultButton';
// import BorrowInterestHelpModal from '../../../../components/HelpModal/BorrowInterestHelpModal';
// import AMPLWarning from '../../../../components/AMPLWarning';

import defaultMessages from '../../../../defaultMessages';
import messages from './messages';

import { CollateralTableItem } from './types';

export default function CollateralMobileCard({
  id,
  symbol,
  userId,
  isFreezed,
  underlyingBalance,
  underlyingBalanceInUSD,
}: CollateralTableItem) {
  const intl = useIntl();
  // const { currentTheme, isCurrentThemeDark } = useThemeContext();

  // const swiperWidth = 50;
  // const swiperHeight = 24;

  let tokenSymbol = symbol === 'WFTM' ? 'FTM' : symbol;
  const hasDeposit = underlyingBalance.toString() > '0';

  return (
    <>
      <MobileCardWrapper symbol={tokenSymbol}>
        <Row title={intl.formatMessage(messages.secondTableColumnTitle)} withMargin={true}>
          <Value
            value={Number(underlyingBalance)}
            subValue={Number(underlyingBalanceInUSD)}
            subSymbol="USD"
          />
        </Row>
        <Row
          title={intl.formatMessage(messages.provideMore)}
          className="Row__center"
          withMargin={true}
        >
          <Link to={`/provide/${tokenSymbol}-${id}`} className="ButtonLink" disabled={false}>
            <DefaultButton
              title={intl.formatMessage(messages.provide)}
              color="dark"
              disabled={false}
            />
          </Link>
        </Row>

        <Row title={intl.formatMessage(messages.withdrawYourCollateral)} className="Row__center">
          <Link
            to={`/withdraw-collateral/${tokenSymbol}-${id}`}
            className="ButtonLink"
            disabled={!hasDeposit}
          >
            <DefaultButton
              title={intl.formatMessage(defaultMessages.withdraw)}
              color="dark"
              transparent={true}
              disabled={!hasDeposit}
            />
          </Link>
        </Row>
      </MobileCardWrapper>

      {/* {symbol === 'AMPL' && <AMPLWarning />} */}
    </>
  );
}
