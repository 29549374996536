import React, { ReactNode } from 'react';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import ContentWrapperWithTopLine from '../ContentWrapperWithTopLine';

import staticStyles from './style';

interface FaucetMainWrapperProps {
  children: ReactNode;
  contentTitle: string;
}

export default function FaucetMainWrapper({ children, contentTitle }: FaucetMainWrapperProps) {
  const { currentTheme, sm, isCurrentThemeDark } = useThemeContext();

  return (
    <div className="FaucetMainWrapper">
      <div className="FaucetMainWrapper__left-inner">
        {!sm && (
          <ContentWrapperWithTopLine title={contentTitle} className="" background="white">
            <div className="FaucetMainWrapper__content">{children}</div>
          </ContentWrapperWithTopLine>
        )}

        {sm && <div className="FaucetMainWrapper__mobile--content">{children}</div>}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .FaucetMainWrapper {
          &__caption {
            color: ${currentTheme.textDarkBlue.hex};
          }
          .FaucetMainWrapper__changeMarket-inner {
            // color: ${isCurrentThemeDark ? currentTheme.cream.hex : currentTheme.darkBlue};
            color: ${currentTheme.darkBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}
