import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  calculateHealthFactorFromBalancesBigUnits,
  valueToBigNumber,
  API_ETH_MOCK_ADDRESS,
  Network,
} from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import {
  useStaticPoolDataContext,
  useReserveAPYDataContext,
} from '../../../../libs/pool-data-provider';
import { useTxBuilderContext } from '../../../../libs/tx-provider';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import NoDataPanel from '../../../../components/NoDataPanel';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import DepositCurrencyWrapper from '../../components/ProvideCollateralWrapper';
import { getAssetInfo, isAssetStable } from '../../../../helpers/markets/assets';
import { useProvideCollateralContext } from '../../../../components/wrappers/ScreensWrapper';

import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import { mapChainIdToName } from 'src/libs/web3-data-provider';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { AmplitudeEventType, sendAmplitudeEvent } from 'src/helpers/amplitude';
import { getDefaultNetworkNameByString } from 'src/config';
import GeneralLevSwapInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v2/GeneralLevSwap';
import ApproveButton from 'src/components/ApproveButton';
import { TokenIcon } from '../../../../helpers/markets/assets';
import staticStyles from './style';

function LeverageConfirmation({
  currencySymbol,
  poolReserve,
  amount,
  user,
  userReserve,
  walletBalance,
  walletEthBalance,
  leverageCount,
  levBorrowAssetSymbol,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const { currentTheme, xl } = useThemeContext();
  const { marketRefPriceInUsd, network } = useStaticPoolDataContext();
  const { networkConfig, currentMarketData } = useProtocolDataContext();
  const { reserves } = useReserveAPYDataContext();
  const { chainId } = useWeb3React<providers.Web3Provider>();
  const {
    convexFRAX3CRVLevSwap,
    convexDAIUSDCUSDTSUSDLevSwap,
    convexFRAXUSDCLevSwap,
    convexIRONBANKLevSwap,
    convexMIM3CRVLevSwap,
    convexTUSDFRAXBPLevSwap,
  } = useTxBuilderContext();
  const [hiddenActionWrapper, setHiddenActionWrapper] = useState(true);

  const [leverageUpFactor, setLeverageUpFactor] = useState(0);

  useEffect(() => {
    setLeverageUpFactor(leverageCount ? leverageCount : 0);
  }, [poolReserve, leverageCount]);

  const currentWalletNetwork = mapChainIdToName(chainId as number) as Network;
  const aTokenData = getAtokenInfo({
    address: poolReserve.aTokenAddress,
    symbol: currencySymbol,
    decimals: poolReserve.decimals,
  });
  const assetDetails = getAssetInfo(poolReserve.symbol);
  const symbolToLevSwap: { [key: string]: { [key: string]: GeneralLevSwapInterface } } = {
    [Network.mainnet]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
    },
    [Network.fork]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      MIM_3CRV_LP: convexMIM3CRVLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
    },
  };

  const borrowAsset = reserves.find(
    ({ symbol, borrowingEnabled, isActive }) =>
      symbol.toUpperCase() === levBorrowAssetSymbol?.toUpperCase() &&
      borrowingEnabled &&
      isActive &&
      isAssetStable(symbol)
  );
  // const borrowAssets = reserves.filter(
  //   ({ symbol, borrowingEnabled, isActive }) =>
  //     borrowingEnabled && isActive && isAssetStable(symbol)
  // );

  if (!amount || !borrowAsset) {
    return null;
  }
  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  const { tokenSymbol } = useProvideCollateralContext();
  let blockingError = '';
  if (
    (tokenSymbol === networkConfig.baseAsset && walletEthBalance.lt(amount)) ||
    (tokenSymbol !== networkConfig.baseAsset && walletBalance.lt(amount))
  ) {
    blockingError = intl.formatMessage(messages.errorWalletBalanceNotEnough, {
      poolReserveSymbol: assetDetails.formattedSymbol || assetDetails.symbol,
    });
  }

  const amountInETH = amount.multipliedBy(poolReserve.price.priceInEth);
  const amountInUsd = amountInETH.dividedBy(marketRefPriceInUsd);

  const ltv = valueToBigNumber(poolReserve.baseLTVasCollateral).multipliedBy(10000).toString();
  const borrowAmount = amountInETH
    .dividedBy(borrowAsset.price.priceInEth)
    .multipliedBy(leverageUpFactor - 1)
    .plus(0.5)                                        //rounding up
    .plus(0.5)                                        //rounding up
    .toString();

  const handleGetBeforeTransactions = async () => {
    let levSwap = convexFRAX3CRVLevSwap;
    for (const [key, value] of Object.entries(symbolToLevSwap?.[currentWalletNetwork])) {
      if (networkConfig.collateralAssets?.[key]?.includes(tokenSymbol)) {
        levSwap = value;
        break;
      }
    }

    return await levSwap.beforeEnterPosition({
      _user: user.id,
      _debtTokenAddress: borrowAsset.variableDebtTokenAddress,
      _amount: borrowAmount,
    });
  };

  const handleGetTransactions = async () => {
    let levSwap = convexFRAX3CRVLevSwap;
    for (const [key, value] of Object.entries(symbolToLevSwap?.[currentWalletNetwork])) {
      if (networkConfig.collateralAssets?.[key]?.includes(tokenSymbol)) {
        levSwap = value;
        break;
      }
    }

    const leverage = leverageCount ? leverageCount : 1 - 1;
    return await levSwap.enterPositionWithFlashloan({
      _user: user.id,
      _asset:
        tokenSymbol === networkConfig.baseAsset
          ? API_ETH_MOCK_ADDRESS
          : networkConfig.collateralAddresses?.[tokenSymbol] || poolReserve.underlyingAsset,
      _amount: amount.toString(),
      _leverage: leverageCount ? ((leverageCount - 1) * 10000).toString() : '0',
      _slippage: currentMarketData.flashloanSlippage
        ? (currentMarketData.flashloanSlippage * 10000).toString()
        : '0',
      _stableAsset: borrowAsset.underlyingAsset,
    });
  };

  const notShowHealthFactor = user.totalBorrowsETH !== '0' && poolReserve.usageAsCollateralEnabled;

  const sendAmplitudeEventByType = (eventType: string) => () => {
    sendAmplitudeEvent(user.id, eventType, {
      network: getDefaultNetworkNameByString(),
      reserve: poolReserve.symbol,
      amount: +amount,
      value: +amountInUsd,
    });
  };

  const handleApprovalTxConfirmed = (confirmed: boolean) => {
    if (confirmed) {
      setHiddenActionWrapper(false);
    }
  };

  return (
    <DepositCurrencyWrapper
      currencySymbol={currencySymbol}
      walletBalance={walletBalance}
      poolReserve={poolReserve}
      user={user}
      userReserve={userReserve}
    >
      <PoolTxConfirmationView
        mainTxName={intl.formatMessage(defaultMessages.deposit)}
        caption={intl.formatMessage(messages.caption)}
        boxTitle={intl.formatMessage(defaultMessages.deposit)}
        boxDescription={intl.formatMessage(messages.boxDescription)}
        approveDescription={intl.formatMessage(messages.approveDescription)}
        getTransactionsData={handleGetTransactions}
        blockingError={blockingError}
        aTokenData={aTokenData}
        onMainTxConfirmed={sendAmplitudeEventByType(AmplitudeEventType.leverage)}
        onSubmitTransaction={sendAmplitudeEventByType(AmplitudeEventType.uncertain_leverage)}
        hideActionWrapper={hiddenActionWrapper}
      >
        <Row title={intl.formatMessage(messages.valueRowTitle)} withMargin={notShowHealthFactor}>
          <Value
            symbol={tokenSymbol}
            value={amount.toString()}
            tokenIcon={true}
            subValue={amountInUsd.toString()}
            subSymbol="USD"
            tooltipId={currencySymbol}
          />
        </Row>
        <Row title={intl.formatMessage(messages.leverageUp)} withMargin={notShowHealthFactor}>
          <span>{leverageUpFactor.toFixed(2)}x</span>
        </Row>
        <Row title={intl.formatMessage(messages.borrowAsset)} withMargin={notShowHealthFactor}>
          <TokenIcon
            tokenSymbol={borrowAsset.symbol}
            tokenFullName={borrowAsset.symbol}
            width={xl ? 16 : 18}
            height={xl ? 16 : 18}
          />
        </Row>
        <Row title={intl.formatMessage(messages.borrowEnabled)} withMargin={notShowHealthFactor}>
          <ApproveButton
            txNetwork={network}
            getTransactionsData={handleGetBeforeTransactions}
            onApprovalTxConfirmed={handleApprovalTxConfirmed}
          />
        </Row>
      </PoolTxConfirmationView>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </DepositCurrencyWrapper>
  );
}

export default routeParamValidationHOC({
  withAmount: true,
  withWalletBalance: true,
  withLeverage: true,
})(LeverageConfirmation);
