import React from 'react';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import Link from '../../../../basic/Link';
import Value from '../../../../basic/Value';
import Row from '../../../../basic/Row';
import { getAssetInfo, TokenIcon } from '../../../../../helpers/markets/assets';

import staticStyles from './style';
import { isAssetStable } from 'src/helpers/markets/assets';

interface CardProps {
  link: string;
  symbol: string;
  id: string;
  value: string;
  underlyingAsset: string;
  isStable?: boolean;
}

export default function Card({ link, symbol, id, value, underlyingAsset, isStable }: CardProps) {
  const { currentTheme, xl, sm } = useThemeContext();

  const asset = getAssetInfo(symbol);

  const iconSize = xl && !sm ? 20 : sm ? 24 : 25;

  return (
    <Link className="Card ButtonLink" to={link} color="dark">
      <Row className="Card__content">
        <TokenIcon
          tokenSymbol={symbol}
          height={iconSize}
          width={iconSize}
          tokenFullName={asset.shortSymbol || asset.formattedName}
        />
        <Value
          value={value}
          maximumValueDecimals={isAssetStable(symbol) || isStable ? 2 : 6}
          minimumValueDecimals={2}
          tooltipId={`${underlyingAsset}-${id}`}
          maximumSubValueDecimals={2}
        />
      </Row>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .Card {
          .TokenIcon .TokenIcon__name {
            b {
              color: ${currentTheme.cream.hex};
            }
          }
          &:hover {
            .TokenIcon .TokenIcon__name {
              b {
                color: ${currentTheme.white.hex};
              }
            }
          }
          &__content {
            border-bottom: 1px solid ${currentTheme.mainBg.hex};
          }
        }
      `}</style>
    </Link>
  );
}
