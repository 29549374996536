import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import goToTop from '../../../helpers/goToTop';
import { getAssetInfo, TokenIcon } from '../../../helpers/markets/assets';

import staticStyles from './style';

interface MobileCardWrapperProps {
  onClick?: () => void;
  disabled?: boolean;
  withGoToTop?: boolean;
  symbol: string;
  className?: string;
  subSymbolComponent?: ReactNode;
  children: ReactNode;
  hideBottomLine?: boolean;
  useSymbolAsName?: boolean;
}

export default function MobileCardWrapper({
  symbol,
  onClick,
  disabled,
  withGoToTop,
  className,
  subSymbolComponent,
  children,
  hideBottomLine,
  useSymbolAsName,
}: MobileCardWrapperProps) {
  const { currentTheme } = useThemeContext();

  const asset = getAssetInfo(symbol);

  return (
    <div
      className={classNames('MobileCardWrapper', className, {
        MobileCardWrapper__noBottomLine: hideBottomLine,
      })}
      onClick={() => {
        !disabled && onClick && onClick();
        withGoToTop && goToTop();
      }}
    >
      <div className="MobileCardWrapper__symbol--inner">
        <TokenIcon
          tokenSymbol={symbol}
          height={36}
          width={36}
          tokenFullName={useSymbolAsName ? symbol : asset.name}
          onColumn={true}
        />
        {subSymbolComponent}
      </div>

      <div className="MobileCardWrapper__content">{children}</div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';
        .MobileCardWrapper {
          background: ${currentTheme.whiteElement.hex};
          p {
            color: ${currentTheme.textDarkBlue.hex};
          }

          // &__symbol--inner {
          //   border-bottom: 1px solid ${currentTheme.mainBg.hex};
          // }

          .TokenIcon__onColumn {
            border-bottom: solid 1px ${currentTheme.white.hex};
            @include respond-to(sm) {
              border-bottom: solid 1px ${currentTheme.textDarkBlue.hex};
            }
            padding-bottom: 12px;
            padding-top: 12px;
          }

          // border: 2px solid ${currentTheme.cream.hex};
          &:hover {
            // border: 2px solid ${currentTheme.darkBlueLighter.hex};
            box-shadow: 0px 0px 3px 3px ${currentTheme.gray.hex};
          }

          .ValuePercent__value {
            color: ${currentTheme.textDarkBlue.hex} !important;

            span {
              color: ${currentTheme.textDarkBlue.hex} !important;
            }
          }
        }
        .MobileCardWrapper.MobileCardWrapper__noBottomLine {
          .TokenIcon__onColumn {
            border-bottom: none;
            padding-bottom: 0px;
            padding-top: 0px;
          }
        }
      `}</style>
    </div>
  );
}
