import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import Row from 'src/components/basic/Row';
import ContentWrapper from 'src/components/wrappers/ContentWrapper';
import Value from 'src/components/basic/Value';
import HealthFactor from 'src/components/HealthFactor';
import MaxLTVHelpModal from 'src/components/HelpModal/MaxLTVHelpModal';
import ValuePercent from 'src/components/basic/ValuePercent';
import RepayWithdrawWrapper from 'src/components/wrappers/RepayWithdrawWrapper';
// import CollateralCompositionBar from 'src/components/compositionBars/CollateralCompositionBar';
import { isAssetStable } from 'src/helpers/markets/assets';

import messages from './messages';
import staticStyles from './style';

interface WithdrawScreenWrapperProps {
  title: string;
  currencySymbol: string;
  balanceInProtocol: string;
  balanceInProtocolInUSD: string;
  healthFactor: string;
  loanToValue: string;
  children: ReactNode;
}

export default function WithdrawScreenWrapper({
  title,
  currencySymbol,
  balanceInProtocol,
  balanceInProtocolInUSD,
  healthFactor,
  loanToValue,
  children,
}: WithdrawScreenWrapperProps) {
  const intl = useIntl();
  const { sm } = useThemeContext();

  return (
    <>
      <RepayWithdrawWrapper className="WithdrawScreenWrapper" title={title}>
        <Row title={intl.formatMessage(messages.balanceInSturdy)} color="white" weight="light">
          <Value
            value={Number(balanceInProtocol)}
            subValue={Number(balanceInProtocolInUSD)}
            color="white"
            symbol={currencySymbol}
            subSymbol="USD"
            maximumValueDecimals={isAssetStable(currencySymbol) ? 4 : 5}
            minimumValueDecimals={isAssetStable(currencySymbol) ? 4 : 4}
            maximumSubValueDecimals={2}
            minimumSubValueDecimals={2}
          />
        </Row>
        <HealthFactor
          className={classNames({ WithdrawScreenWrapper__healthFactor: !sm })}
          value={healthFactor}
          titleColor="white"
          titleLightWeight={true}
          isColumn={!sm}
        />
        <Row
          title={
            <MaxLTVHelpModal
              text={intl.formatMessage(messages.loanToValue)}
              color="white"
              lightWeight={true}
            />
          }
          color="white"
          weight="light"
          isColumn={!sm}
        >
          <ValuePercent value={loanToValue} color="white" />
        </Row>

        {/* <CollateralCompositionBar isColumn={(lg && !md) || sm} /> */}
      </RepayWithdrawWrapper>

      <ContentWrapper withFullHeight={true} withBackButton={true}>
        {children}
      </ContentWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
