import { providers } from 'ethers';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Network } from '@sturdyfi/sturdy-js';
import { CustomMarket, marketsData } from '../../ui-config';
import {
  getNetworkConfig,
  getProvider,
  MarketDataType,
  NetworkConfig,
} from '../../helpers/markets/markets-data';
import { mapNameToChainID } from '../web3-data-provider';
import { availableMarkets } from '../../config';

const LS_KEY = 'selectedMarket';

export interface ProtocolContextData {
  currentMarket: CustomMarket;
  setCurrentMarket: (market: CustomMarket) => void;
  currentMarketData: MarketDataType;
  isTestnet: boolean;
  // currently selected one
  chainId: number;
  network: Network;
  networkConfig: NetworkConfig;
  jsonRpcProvider: providers.Provider;
  // previousMarket?: CustomMarket;
  // setPreviousMarket: (market: CustomMarket) => void;
  uiProviderDataFromFB: any;
}

const PoolDataContext = React.createContext({} as ProtocolContextData);

/**
 * @returns the last accessed market if it's still available, the first market if not.
 */
const getInitialMarket = () => {
  const cachedMarket = localStorage.getItem(LS_KEY) as CustomMarket | undefined;
  if (cachedMarket && availableMarkets.includes(cachedMarket)) return cachedMarket;
  return availableMarkets[0];
};

export function ProtocolDataProvider({ children }: PropsWithChildren<{}>) {
  const [currentMarket, setCurrentMarket] = useState<CustomMarket>(getInitialMarket());
  const [uiProviderDataFromFB, setUiProviderDataFromFB] = useState<any>(undefined);
  // const [previousMarket, setPreviousMarket] = useState<CustomMarket>(getInitialMarket());

  const currentMarketData = marketsData[currentMarket];
  const network = currentMarketData.network;

  const isTestnet = ![Network.mainnet, Network.polygon, Network.avalanche].includes(network);

  const handleSetMarket = (market: CustomMarket) => {
    localStorage.setItem(LS_KEY, market);
    setCurrentMarket(market);
  };

  useEffect(() => {
    fecthProviderDataFromFB();
  }, [network]);

  const fecthProviderDataFromFB = async () => {
    setUiProviderDataFromFB(undefined);
    const url = `https://us-central1-stu-dashboard-a0ba2.cloudfunctions.net/getInitUiData?chain=${network}`;
    const res = await (await fetch(url)).json();
    setUiProviderDataFromFB(res);
  };

  return (
    <PoolDataContext.Provider
      value={{
        isTestnet,
        network,
        currentMarket,
        chainId: mapNameToChainID(marketsData[currentMarket].network),
        setCurrentMarket: handleSetMarket,
        currentMarketData: currentMarketData,
        // previousMarket,
        // setPreviousMarket,
        networkConfig: getNetworkConfig(network),
        jsonRpcProvider: getProvider(network),
        uiProviderDataFromFB,
      }}
    >
      {children}
    </PoolDataContext.Provider>
  );
}

export const useProtocolDataContext = () => useContext(PoolDataContext);
