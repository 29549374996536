import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: 'Asset',
  deposit: 'Deposit',
  collateral: 'Collateral',
  actions: 'Actions',
  yourWalletBalance: 'Wallet balance',
  yourBalanceinSturdy: 'Balance in Sturdy',
  APY: 'APY',
  depositAndWithdraw: 'Deposit/Withdraw',

  dividerTitle: 'To make a deposit, transfer any of these assets to your wallet.',
  connectWallet: 'To make a deposit, please connect a wallet',
});
