import React from 'react';
import { useIntl } from 'react-intl';
import { normalize } from '@aave/math-utils';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useDynamicPoolDataContext } from '../../../libs/pool-data-provider';
import IncentiveClaimItem from '../../IncentiveClaimItem';

import messages from './messages';
import staticStyles from './style';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import { REWARD_TYPE } from 'src/helpers/markets/markets-data';

export default function StableRewardWrapper() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();

  const { user } = useDynamicPoolDataContext();
  const { userReward } = useIncentivesDataContext();

  // Only display assets for which user has claimable rewards
  const rewardsFiltered = userReward?.filter(
    (entry) =>
      Number(entry.totalUnclaimedRewards) > 0 &&
      entry.rewardUserDatas[0].rewardType === REWARD_TYPE.STABLE
  );

  if (!user || !rewardsFiltered || Object.keys(rewardsFiltered).length === 0) return null;

  return (
    <div className="StableRewardWrapper">
      <p className="StableRewardWrapper__title">
        {intl.formatMessage(messages.availableReward, { type: 'Stable' })}
      </p>

      <div className="StableRewardWrapper__rewards">
        {rewardsFiltered.map((reward) => {
          const claimableRewards = normalize(Number(reward.totalUnclaimedRewards), 18);
          return (
            <IncentiveClaimItem
              key={reward.id}
              symbol={reward.rewardUserDatas[0].rewardTokenSymbol || ''}
              claimableRewards={claimableRewards}
              incentiveControllerAddress={reward.id}
              type={REWARD_TYPE.STABLE}
            />
          );
        })}
      </div>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .StableRewardWrapper__title {
          color: ${currentTheme.textDarkBlue.hex};
        }
      `}</style>
    </div>
  );
}
