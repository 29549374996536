/* Generated by ts-generator ver. 0.0.8 */
/* tslint:disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import { IBaseUniswapAdapter } from './IBaseUniswapAdapter';

export class IBaseUniswapAdapterFactory {
  static connect(address: string, signerOrProvider: Signer | Provider): IBaseUniswapAdapter {
    return new Contract(address, _abi, signerOrProvider) as IBaseUniswapAdapter;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amountOut',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'reserveIn',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'reserveOut',
        type: 'address',
      },
    ],
    name: 'getAmountsIn',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amountIn',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'reserveIn',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'reserveOut',
        type: 'address',
      },
    ],
    name: 'getAmountsOut',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
