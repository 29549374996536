import { ComputedReserveData, Network } from '@sturdyfi/sturdy-js';
import { mapChainIdToName } from 'src/libs/web3-data-provider';
import { NetworkConfig } from '../../helpers/markets/markets-data';
import { isAssetStable } from '../../helpers/markets/assets';

function isCollateral(symbol: string, chainId?: number) {
  const currentWalletNetwork = mapChainIdToName(chainId as number) as Network;

  if (currentWalletNetwork === Network.ftm_test) {
    const allowableTokens = ['WBTC', 'WETH', 'BOO'];
    return allowableTokens.includes(symbol.toLocaleUpperCase());
  }
  return false;
}

export function isFaucetAvailable(symbol: string, chainId?: number) {
  const network = mapChainIdToName(chainId as number) as Network;

  if (network === Network.goerli) {
    return symbol === 'DAI';
  } else if (network === Network.ftm_test) {
    return isAssetStable(symbol) || isCollateral(symbol, chainId);
  }
  return false;
}

export function getFaucetTokenAddress(
  networkConfig: NetworkConfig,
  reserve: ComputedReserveData,
  chainId?: number
) {
  return isCollateral(reserve.symbol, chainId)
    ? networkConfig.collateralAddresses?.[reserve.symbol] || reserve.underlyingAsset
    : reserve.underlyingAsset;
}
