import { defineMessages } from 'react-intl';

export default defineMessages({
  yourCollateral: 'Your collateral',
  secondTableColumnTitle: 'Balance',

  provide: 'Provide',

  provideMore: 'Provide More',
  withdrawYourCollateral: 'Withdraw your collateral',
});
