import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import TableItemWrapper from '../../BasicTable/TableItemWrapper';
import TableColumn from '../../BasicTable/TableColumn';
import FreezedWarning from '../../FreezedWarning';
import { TokenIcon, getAssetInfo } from '../../../helpers/markets/assets';

import staticStyles from './style';

interface TableItemProps {
  symbol: string;
  symbols?: readonly string[];
  url?: string;
  isFreezed?: boolean;
  isBorrow?: boolean;
  children?: ReactNode;
  darkOnDarkMode?: boolean;
}

export default function TableItem({
  symbol,
  symbols = [],
  url,
  isFreezed,
  isBorrow,
  children,
  darkOnDarkMode,
}: TableItemProps) {
  const history = useHistory();
  const { currentTheme } = useThemeContext();
  const asset = getAssetInfo(symbol);
  const fullName = !!asset.symbolsArray && asset.symbolsArray.length > 2 ? asset.name : symbol;

  return (
    <TableItemWrapper
      className={classNames('TableItem', {
        TableItem__withHeight: darkOnDarkMode,
        TableItem__borrow: isBorrow,
      })}
      onClick={() => url && history.push(url)}
      disabled={isFreezed}
      withGoToTop={true}
      darkOnDarkMode={darkOnDarkMode}
    >
      <TableColumn className="TableItem__token-inner">
        <span className="TableItem__assetColor" />
        {symbols.length > 1 && !asset.bottomDescription ? (
          <div className="d-flex">
            {symbols.map((symbol) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }} key={symbol}>
                  <TokenIcon
                    tokenSymbol={symbol}
                    height={26}
                    width={26}
                    tokenFullName={fullName}
                    className="TableItem__tokenIcon"
                    style={{ marginRight: '8px' }}
                  />
                  {/* <span className="or-breaker">or</span> */}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="d-flex">
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <TokenIcon
                tokenSymbol={symbol}
                height={35}
                width={35}
                tokenFullName={fullName}
                className="TableItem__tokenIcon"
              />
              {asset.bottomDescription && (
                <div className="TableItem__tokenIcon" style={{ marginTop: 5 }}>
                  <p className="TokenIcon__name ">
                    <b>{asset.bottomDescription}</b>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </TableColumn>
      <div className="TableItem__content">
        {children}

        {isFreezed && (
          <TableColumn>
            <FreezedWarning symbol={symbol} className="TableItem__freezedWarning" />
          </TableColumn>
        )}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .or-breaker {
          margin-right: 8px;
          color: ${currentTheme.cream.hex};
        }

        .TableItem__tokenIcon {
          .TokenIcon__name {
            color: ${currentTheme.cream.hex};
          }
        }

        .TableItem .TableCol {
          .Value.Value__dark .Value__line .Value__value {
            color: ${currentTheme.cream.hex} !important;
          }
        }
      `}</style>
    </TableItemWrapper>
  );
}
