import { defineMessages } from 'react-intl';

export default defineMessages({
  useDeleverage: 'Deleverage',
  useFlashloan: 'Flashloan',
  newHF: 'New health factor',
  safer: 'Safer',
  riskier: 'Riskier',
  leverageUp: 'Leverage amount',
  collateralDeposit: 'Collateral deposit',
  liquidationPrice: 'Liquidation price',
  leveragedAPY: 'APY',
  description: 'Which stable coin would you like to repay?',
  flashloanDescription: 'Would you like to deleverage with flashloan?',
  yes: 'Yes',
  no: 'No',
  slippage: 'Slippage tolerance:',
  selectToken: 'Select Token',
  availableToRepay: 'Available to repay',
});
