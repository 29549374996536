import React from 'react';
import classNames from 'classnames';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import staticStyles from './style';

interface ExpandButtonProps {
  active: boolean;
  positionVertical: 'top' | 'bottom';
  positionHorizontal: 'left' | 'right';
  onClick?: () => void;
  className?: string;
}

export default function ExpandButton({
  active,
  positionVertical,
  positionHorizontal,
  onClick,
  className,
}: ExpandButtonProps) {
  const { currentTheme } = useThemeContext();

  return (
    <div
      className={classNames(
        'ExpandButton',
        `ExpandButton__${positionVertical}`,
        `ExpandButton__${positionHorizontal}`,
        { ExpandButton__active: active },
        className
      )}
      onClick={onClick}
    >
      <span />
      <span />

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .ExpandButton {
          span {
            background: ${currentTheme.white.hex};
          }
        }
      `}</style>
    </div>
  );
}
