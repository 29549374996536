import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ComputedReserveData, valueToBigNumber, BigNumber } from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import { PERMISSION } from '@sturdyfi/contract-helpers';

import {
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
  useReserveAPYDataContext,
} from 'src/libs/pool-data-provider';
import ScreenWrapper from 'src/components/wrappers/ScreenWrapper';
import Preloader from 'src/components/basic/Preloader';
import AssetsFilterPanel from 'src/components/AssetsFilterPanel';
import NoDataPanel from 'src/components/NoDataPanel';
import DepositAssetsTable from '../../components/DepositAssetsTable';
import DepositMobileCard from '../../components/DepositAssetsTable/DepositMobileCard';
import DepositBorrowMainWrapper from 'src/components/wrappers/DepositBorrowMainWrapper';

import { DepositTableItem } from '../../components/DepositAssetsTable/types';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import { isAssetStable } from 'src/helpers/markets/assets';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import PermissionWarning from 'src/ui-config/branding/PermissionWarning';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

// import defaultMessages from 'src/defaultMessages';
import messages from './messages';

export default function DepositsMain() {
  const intl = useIntl();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { user } = useDynamicPoolDataContext();
  const { reserves } = useReserveAPYDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  const { sm } = useThemeContext();

  const [searchValue, setSearchValue] = useState('');
  const [showOnlyStableCoins, setShowOnlyStableCoins] = useState(false);

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);

  const { walletData } = useWalletBalanceProviderContext();

  if (!walletData) {
    return <Preloader withText={true} />;
  }

  const filteredReserves = reserves.filter(
    (reserve) =>
      reserve.symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      reserve.isActive &&
      (reserve.borrowingEnabled || reserve.usageAsCollateralEnabled) &&
      (!showOnlyStableCoins || isAssetStable(reserve.symbol))
  );

  if (sortDesc) {
    // @ts-ignore
    filteredReserves.sort((a, b) => a[sortName] - b[sortName]);
  } else {
    // @ts-ignore
    filteredReserves.sort((a, b) => b[sortName] - a[sortName]);
  }

  const aincentivesAPR = (reserve: ComputedReserveData) => {
    if (reserve.usageAsCollateralEnabled) {
      return reserve.aIncentivesAPY;
    } else {
      const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];
      return reserveIncentiveData ? reserveIncentiveData.aIncentives.incentiveAPR : '0';
    }
  };

  const vincentivesAPR = (reserve: ComputedReserveData) => {
    if (reserve.usageAsCollateralEnabled) {
      return reserve.vIncentivesAPY;
    } else {
      const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];
      return reserveIncentiveData ? reserveIncentiveData.vIncentives.incentiveAPR : '0';
    }
  };

  const sincentivesAPR = (reserve: ComputedReserveData) => {
    if (reserve.usageAsCollateralEnabled) {
      return reserve.sIncentivesAPY;
    } else {
      const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];
      return reserveIncentiveData ? reserveIncentiveData.sIncentives.incentiveAPR : '0';
    }
  };

  const listData = (withFilter: boolean) => {
    const { networkConfig } = useProtocolDataContext();
    const data = (reserves: ComputedReserveData[]) =>
      reserves.map<DepositTableItem>((reserve) => {
        const userReserves =
          user?.reservesData.filter((userRes) => userRes.reserve.symbol === reserve.symbol) || [];
        const userReserve = userReserves?.length > 1 ? userReserves[1] : userReserves?.[0];

        // const userReserve = user?.reservesData.find(
        //   (userRes) => userRes.reserve.symbol === reserve.symbol
        // );
        const underlyingAsset = reserve.usageAsCollateralEnabled
          ? networkConfig.collateralAddresses?.[reserve.symbol] || reserve.underlyingAsset
          : reserve.underlyingAsset;

        const walletBalance =
          walletData[underlyingAsset] === '0'
            ? valueToBigNumber('0')
            : valueToBigNumber(walletData[underlyingAsset] || '0').dividedBy(
                valueToBigNumber('10').pow(reserve.decimals)
              );
        const walletBalanceInUSD = walletBalance
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
          .toString();
        const liquidityRate = reserve.usageAsCollateralEnabled
          ? reserve.borrowingEnabled
            ? Number(reserve.liquidityRate)
            : reserve.aprDetails.totalApr
            ? reserve.aprDetails.totalApr
            : '-1'
          : reserve.variableDepositAPY;

        return {
          ...reserve,
          walletBalance,
          walletBalanceInUSD,
          underlyingBalance: userReserve ? userReserve.underlyingBalance : '0',
          underlyingBalanceInUSD: userReserve ? userReserve.underlyingBalanceUSD : '0',
          liquidityRate: liquidityRate,
          avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
          usageAsCollateralEnabled: reserve.usageAsCollateralEnabled,
          borrowingEnabled: reserve.borrowingEnabled,
          interestHistory: [],
          aincentivesAPR: aincentivesAPR(reserve),
          vincentivesAPR: vincentivesAPR(reserve),
          sincentivesAPR: sincentivesAPR(reserve),
          isFreezed: reserve.isFrozen
        };
      });

    if (withFilter) {
      if (sortDesc) {
        return (
          data(filteredReserves)
            .sort((a, b) => +b.walletBalanceInUSD - +a.walletBalanceInUSD)
            // @ts-ignore
            .sort((a, b) => a[sortName] - b[sortName])
        );
      } else {
        return (
          data(filteredReserves)
            .sort((a, b) => +b.walletBalanceInUSD - +a.walletBalanceInUSD)
            // @ts-ignore
            .sort((a, b) => b[sortName] - a[sortName])
        );
      }
    } else {
      return data(reserves);
    }
  };

  return (
    <PermissionWarning requiredPermission={PERMISSION.DEPOSITOR}>
      <ScreenWrapper
        // pageTitle={intl.formatMessage(defaultMessages.deposit)}
        isTitleOnDesktop={true}
        withMobileGrayBg={true}
        className="ScreenWrapper__side_by_side"
      >
        {sm && (
          <AssetsFilterPanel
            optionTitleLeft={intl.formatMessage(messages.optionTitleLeft)}
            optionTitleRight={intl.formatMessage(messages.optionTitleRight)}
            switchValue={showOnlyStableCoins}
            switchOnToggle={setShowOnlyStableCoins}
            searchValue={searchValue}
            searchOnChange={setSearchValue}
          />
        )}

        <DepositBorrowMainWrapper
          contentTitle={''}
          itemsTitle={intl.formatMessage(messages.myBorrows)}
          items={<></>}
          isShowRightPanel={false}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showOnlyStableCoins={showOnlyStableCoins}
          setShowOnlyStableCoins={setShowOnlyStableCoins}
          withSwitchMarket={true}
          totalValue={0}
        >
          {!!listData(true).length ? (
            <>
              {!sm ? (
                <>
                  <DepositAssetsTable
                    listData={listData(true)}
                    userId={user?.id}
                    sortName={sortName}
                    setSortName={setSortName}
                    sortDesc={sortDesc}
                    setSortDesc={setSortDesc}
                  />
                </>
              ) : (
                <>
                  {listData(true).map((item, index) => (
                    <DepositMobileCard userId={user?.id} {...item} key={index} />
                  ))}
                </>
              )}
            </>
          ) : (
            <NoDataPanel title={intl.formatMessage(messages.noDataTitle)} />
          )}
        </DepositBorrowMainWrapper>
      </ScreenWrapper>
    </PermissionWarning>
  );
}
