import { defineMessages } from 'react-intl';

export default defineMessages({
  marketSize: 'Total deposited',
  totalBorrowed: 'Total borrowed',
  depositAPY: 'Deposit APY',
  borrowAPY: 'Borrow APY',

  deposit: 'Deposit',
  borrow: 'Borrow',
  variable: 'Variable',
  stable: 'Stable',
});
