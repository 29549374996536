import { API_ETH_MOCK_ADDRESS, Network } from '@sturdyfi/sturdy-js';
import {
  ENABLE_CACHING_BACKEND,
  FORK_RPC_URL,
  FORK_WS_RPC_URL,
  POLYGON_FORK_RPC_URL,
  POLYGON_FORK_WS_RPC_URL,
  AVALANCHE_FORK_RPC_URL,
  AVALANCHE_FORK_WS_RPC_URL,
} from '../config';
import { BaseNetworkConfig, REWARD_TYPE } from '../helpers/markets/markets-data';
import polygonBridgeLogo from './branding/images/polygonLogo.svg';
import avalancheBridgeLogo from './branding/images/avalancheLogo.svg';

const mainnet_config: BaseNetworkConfig = {
  publicJsonRPCUrl: 'https://eth-mainnet.alchemyapi.io/v2/RhxuLhevPpy4TfK14RXr37g2mdw7bWJv',
  addresses: {
    walletBalanceProvider: '0x9787bDC2Ff7F39Ff981ecc347DfAcF6D57b8783E',
    uiPoolDataProvider: '0x7BE637cA0e8B5623345C365A9a8248E5f3550A29',
    uiIncentiveDataProvider: '0x46c05BB4205BfC2E063EaB565Fde488561a0a281',
  },
  protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/sturdyfi/sturdy-ethereum',
  rewardTokenSymbol: 'BRICK',
  rewardTokenAddress: '0x150A51977Bc273dfe84B5530D45ED44D175E88f1',
  rewardTokenDecimals: 18,
  incentivePrecision: 18,
  baseAsset: 'ETH',
  baseAssetGas: '0.1',
  baseAssetWrappedAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  collateralAssets: {
    stETH: ['ETH', 'stETH'],
    FRAX_3CRV_LP: ['FRAX_3CRV_LP'],
    DAI_USDC_USDT_SUSD_LP: ['DAI_USDC_USDT_SUSD_LP'],
    IRON_BANK_LP: ['IRON_BANK_LP'],
    FRAX_USDC_LP: ['FRAX_USDC_LP'],
    MIM_3CRV_LP: ['MIM_3CRV_LP'],
    TUSD_FRAXBP_LP: ['TUSD_FRAXBP_LP'],
  },
  collateralAddresses: {
    stETH: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
    FRAX_3CRV_LP: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
    DAI_USDC_USDT_SUSD_LP: '0xC25a3A3b969415c80451098fa907EC722572917F',
    IRON_BANK_LP: '0x5282a4eF67D9C33135340fB3289cc1711c13638C',
    FRAX_USDC_LP: '0x3175Df0976dFA876431C2E9eE6Bc45b65d3473CC',
    MIM_3CRV_LP: '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
    TUSD_FRAXBP_LP: '0x33baeDa08b8afACc4d3d07cf31d49FC1F1f3E893',
  },
  collateralAssetFromSymbol: {
    stETH: 'stETH',
    'cFRAX3CRV-f': 'FRAX_3CRV_LP',
    ccrvPlain3andSUSD: 'DAI_USDC_USDT_SUSD_LP',
    cib3CRV: 'IRON_BANK_LP',
    ccrvFRAX: 'FRAX_USDC_LP',
    'cMIM-3LP3CRV-f': 'MIM_3CRV_LP',
    'cTUSDFRAXBP3CRV-f': 'TUSD_FRAXBP_LP',
  },
  collateralRewardAddresses: [
    {
      token: '0xD533a949740bb3306d119CC777fa900bA034cd52',
      symbol: 'CRV',
      type: REWARD_TYPE.VARIABLE,
    },
  ],
  explorerLink: 'https://etherscan.io',
  rpcOnly: true,
  usdMarket: false,
  isTestnet: false,
  chainName: 'Ethereum',
  currency: {
    name: 'Ethereum Coin',
    symbol: 'ETH',
    decimals: 18,
  },
} as const;

const polygon_config: BaseNetworkConfig = {
  publicJsonRPCUrl: 'https://polygon-rpc.com',
  publicJsonRPCWSUrl: 'wss://polygon-rpc.com',
  addresses: {
    walletBalanceProvider: '0x34aa032bC416Cf2CdC45c0C8f065b1F19463D43e',
    uiPoolDataProvider: '0x3b4108475a8092967225564C05a1E74e9F7A45D6',
    uiIncentiveDataProvider: '0xC5093EDAC52f4DD68b42433eA8754B26eAbb1A48',
  },
  cachingServerUrl: 'https://cache-api-polygon.aave.com/graphql',
  cachingWSServerUrl: 'wss://cache-api-polygon.aave.com/graphql',
  protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/aave-v2-matic',
  baseAsset: 'MATIC',
  baseAssetWrappedAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  // incentives hardcoded information
  rewardTokenSymbol: 'WMATIC',
  rewardTokenAddress: API_ETH_MOCK_ADDRESS,
  rewardTokenDecimals: 18,
  incentivePrecision: 18,
  explorerLink: 'https://polygonscan.com',
  rpcOnly: !ENABLE_CACHING_BACKEND,
  bridge: {
    brandColor: '130, 71, 229',
    name: 'Polygon PoS Bridge',
    url: 'https://wallet.matic.network/bridge/',
    logo: polygonBridgeLogo,
  },
} as const;

const avalanche_config: BaseNetworkConfig = {
  publicJsonRPCUrl: 'https://api.avax.network/ext/bc/C/rpc',
  publicJsonRPCWSUrl: 'wss://api.avax.network/ext/bc/C/rpc',
  addresses: {
    walletBalanceProvider: '0x73e4898a1Bfa9f710B6A6AB516403A6299e01fc6',
    uiPoolDataProvider: '0x7d9d970CaE574912221d25107A6728f0d17Cb901',
    uiIncentiveDataProvider: '0x16Dea0fCBca21E848714B2e96f26ddF6BCe505C9',
  },
  protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2-avalanche',
  cachingServerUrl: 'https://cache-api-avalanche.aave.com/graphql',
  cachingWSServerUrl: 'wss://cache-api-avalanche.aave.com/graphql',
  baseUniswapAdapter: '0x0',
  baseAsset: 'AVAX',
  baseAssetWrappedAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  // incentives hardcoded information
  rewardTokenSymbol: 'WAVAX',
  rewardTokenAddress: API_ETH_MOCK_ADDRESS,
  rewardTokenDecimals: 18,
  incentivePrecision: 18,
  explorerLink: 'https://cchain.explorer.avax.network',
  rpcOnly: !ENABLE_CACHING_BACKEND,
  usdMarket: true,
  bridge: {
    brandColor: '232, 65, 66',
    name: 'Avalanche Bridge',
    url: 'https://bridge.avax.network/',
    logo: avalancheBridgeLogo,
  },
};

export const networkConfigs: { [key: string]: BaseNetworkConfig } = {
  [Network.kovan]: {
    publicJsonRPCUrl: 'https://kovan.poa.network ',
    addresses: {
      walletBalanceProvider: '0x07DC923859b68e9399d787bf52c4Aa9eBe3490aF',
      uiPoolDataProvider: '0x04110Dc40B04b99B94840E53B2a33bE45E45A8Ed',
      uiIncentiveDataProvider: '0x9842E5B7b7C6cEDfB1952a388e050582Ff95645b',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2-kovan',
    baseUniswapAdapter: '0xf86Be05f535EC2d217E4c6116B3fa147ee5C05A1',
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
    // incentives hardcoded information
    rewardTokenSymbol: 'stkAAVE',
    rewardTokenAddress: '0xb597cd8d3217ea6477232f9217fa70837ff667af',
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    explorerLink: 'https://kovan.etherscan.io',
    rpcOnly: true,
    isTestnet: true,
  },
  [Network.goerli]: {
    publicJsonRPCUrl: 'https://eth-goerli.alchemyapi.io/v2/1TKC60gVuUgBj2DqmdjusK-zmDl-Edag',
    addresses: {
      walletBalanceProvider: '0x085c4AcAA09A0561385A2d937e766059683Bd594',
      uiPoolDataProvider: '0x4976E4C7F61124747F7f7496d157B538C8DbeD63',
      uiIncentiveDataProvider: '0x1917a39241cc1bEEa1e503DDC5B94fDBbfBE2731',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/sturdyfi/sturdy-goerli',
    rewardTokenSymbol: 'BRICK',
    rewardTokenAddress: '0x9d0fae54966301F9A6590cD870EAd34bA388Dd45',
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    baseAsset: 'ETH',
    baseAssetWrappedAddress: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
    collateralAssets: {
      stETH: ['ETH', 'stETH'],
    },
    collateralAddresses: {
      stETH: '0x1643E812aE58766192Cf7D2Cf9567dF2C37e9B7F',
    },
    explorerLink: 'https://goerli.etherscan.io',
    rpcOnly: true,
    isTestnet: true,
    chainName: 'Goerli Test Network',
    currency: {
      name: 'Ethereum Coin',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [Network.mainnet]: {
    ...mainnet_config,
  },
  [Network.polygon]: {
    ...polygon_config,
  },
  [Network.mumbai]: {
    publicJsonRPCUrl: 'https://rpc-mumbai.maticvigil.com',
    publicJsonRPCWSUrl: 'wss://rpc-mumbai.maticvigil.com',
    addresses: {
      walletBalanceProvider: '0xEe7c0172c200e12AFEa3C34837052ec52F3f367A',
      uiPoolDataProvider: '0x589390E0AaEB95be573A87Ca828989d8e3e77C04',
      uiIncentiveDataProvider: '0x04110Dc40B04b99B94840E53B2a33bE45E45A8Ed',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/aave-v2-polygon-mumbai',
    baseAsset: 'MATIC',
    baseAssetWrappedAddress: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    // incentives hardcoded information
    rewardTokenSymbol: 'WMATIC',
    rewardTokenAddress: '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    explorerLink: 'https://explorer-mumbai.maticvigil.com',
    rpcOnly: true,
    isTestnet: true,
  },
  [Network.fuji]: {
    publicJsonRPCUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    publicJsonRPCWSUrl: 'wss://api.avax-test.network/ext/bc/C/rpc',
    addresses: {
      walletBalanceProvider: '0x3f5A507B33260a3869878B31FB90F04F451d28e3',
      uiPoolDataProvider: '0x41b6b18DfF735dbaEda5F5FB5393F57E420D5CB8',
      uiIncentiveDataProvider: '0x2c911e6FaD423C00205eda22BBAc3e7F82c38007',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2-fuji',
    baseUniswapAdapter: '0x0',
    baseAsset: 'AVAX',
    baseAssetWrappedAddress: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    // incentives hardcoded information
    rewardTokenSymbol: 'WAVAX',
    rewardTokenAddress: API_ETH_MOCK_ADDRESS,
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    explorerLink: 'https://cchain.explorer.avax-test.network',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: true,
    bridge: {
      brandColor: '232, 65, 66',
      name: 'Avalanche Bridge',
      url: 'https://bridge.avax.network/',
      logo: avalancheBridgeLogo,
    },
  },
  [Network.fork]: {
    ...mainnet_config,
    // addresses: {
    //   walletBalanceProvider: '0x42e0fAB4aa7580D1A1492be16E2fc4fa50eb5a45',
    //   uiPoolDataProvider: '0x1BDc1144C7b3B17F7Be67b5Fe687Bcb7DA1cB550',
    //   uiIncentiveDataProvider: '0x939FC67C23f3d2b165BD7C5cb15Ce8EEe3Fa4429',
    // },
    privateJsonRPCUrl: FORK_RPC_URL,
    privateJsonRPCWSUrl: FORK_WS_RPC_URL,
    rpcOnly: true,
  },
  [Network.polygon_fork]: {
    ...polygon_config,
    privateJsonRPCUrl: POLYGON_FORK_RPC_URL,
    privateJsonRPCWSUrl: POLYGON_FORK_WS_RPC_URL,
    rpcOnly: true,
  },
  [Network.avalanche]: {
    ...avalanche_config,
  },
  [Network.avalanche_fork]: {
    ...avalanche_config,
    privateJsonRPCUrl: AVALANCHE_FORK_RPC_URL,
    privateJsonRPCWSUrl: AVALANCHE_FORK_WS_RPC_URL,
    rpcOnly: true,
  },
  [Network.ftm]: {
    publicJsonRPCUrl: 'https://rpcapi.fantom.network/', //'https://rpc.ftm.tools/',
    addresses: {
      walletBalanceProvider: '0x736c58F1C8B8BEC3Ef73b9308d3137c7b9D41F44',
      uiPoolDataProvider: '0xF672c8422aEcF74df126dFBEf22EA83023bF63C3',
      uiIncentiveDataProvider: '0x9AB2Bdf9ABfCCff59b3005F34b2BC3D4c5E47213',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/sturdyfi/sturdy-fantom',
    rewardTokenSymbol: 'BRICK',
    rewardTokenAddress: '0x6BA22b71D83e44D611bB9a0771A5144d869baf57',
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    baseAsset: 'FTM',
    baseAssetGas: '0.1',
    baseAssetWrappedAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    collateralAssets: {
      WFTM: ['FTM', 'WFTM'],
      WETH: ['WETH'],
      WBTC: ['WBTC'],
      BOO: ['BOO'],
      fBEETS: ['fBEETS'],
      LINK: ['LINK'],
      SPELL: ['SPELL'],
      CRV: ['CRV'],
      TOMB_MIMATIC_LP: ['TOMB_MIMATIC_LP'],
      BASED_MIMATIC_LP: ['BASED_MIMATIC_LP'],
    },
    collateralAssetFromSymbol: {
      yvWFTM: 'WFTM',
      yvWETH: 'WETH',
      yvWBTC: 'WBTC',
      yvBOO: 'BOO',
      yvfBEETS: 'fBEETS',
      yvLINK: 'LINK',
      yvSPELL: 'SPELL',
      yvCRV: 'CRV',
      'mooTombTOMB-MAI': 'TOMB_MIMATIC_LP',
      'mooTombBASED-MAI': 'BASED_MIMATIC_LP',
    },
    collateralAddresses: {
      WFTM: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      WETH: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
      WBTC: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      BOO: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
      TOMB_MIMATIC_LP: '0x45f4682B560d4e3B8FF1F1b3A38FDBe775C7177b',
      BASED_MIMATIC_LP: '0x7B5B3751550be4FF87aC6bda89533F7A0c9825B3',
      LINK: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
      SPELL: '0x468003B688943977e6130F4F68F23aad939a1040',
      CRV: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
      fBEETS: '0xfcef8a994209d6916EB2C86cDD2AFD60Aa6F54b1',
    },
    explorerLink: 'https://ftmscan.com',
    rpcOnly: true,
    usdMarket: true,
    isTestnet: false,
    chainName: 'Fantom',
    currency: {
      name: 'Fantom Coin',
      symbol: 'FTM',
      decimals: 18,
    },
  },
  [Network.ftm_test]: {
    publicJsonRPCUrl: 'https://rpc.testnet.fantom.network/',
    addresses: {
      walletBalanceProvider: '0xA5E91FA7F5A9813923c1a79d50547D78509A7532',
      uiPoolDataProvider: '0x98AEc7583913350B07570231211e3C1a80B08406',
      uiIncentiveDataProvider: '0x1F164A927B5558F46f1471aB43B673b6d776CC13',
    },
    protocolDataUrl: 'https://api.thegraph.com/subgraphs/name/sturdyfi/sturdy-goerli', //ToDo: subgraph dont support fantom testnet
    rewardTokenSymbol: 'BRICK',
    rewardTokenAddress: '0xEfaA310fe2410B0f6B84d0077417A85fba853Aa2',
    rewardTokenDecimals: 18,
    incentivePrecision: 18,
    baseAsset: 'FTM',
    baseAssetGas: '0.1',
    baseAssetWrappedAddress: '0xf1277d1ed8ad466beddf92ef448a132661956621',
    collateralAssets: {
      WFTM: ['FTM', 'WFTM'],
      WETH: ['WETH'],
      WBTC: ['WBTC'],
      BOO: ['BOO'],
      fBEETS: ['fBEETS'],
      LINK: ['LINK'],
      SPELL: ['SPELL'],
      CRV: ['CRV'],
      TOMB_FTM_LP: ['TOMB_FTM_LP'],
      TOMB_MIMATIC_LP: ['TOMB_MIMATIC_LP'],
      BASED_MIMATIC_LP: ['BASED_MIMATIC_LP'],
    },
    collateralAssetFromSymbol: {
      yvWFTM: 'WFTM',
      yvWETH: 'WETH',
      yvWBTC: 'WBTC',
      yvBOO: 'BOO',
      yvfBEETS: 'fBEETS',
      yvLINK: 'LINK',
      yvSPELL: 'SPELL',
      yvCRV: 'CRV',
      'mooTombTOMB-FTM': 'TOMB_FTM_LP',
      'mooTombTOMB-MIMATIC': 'TOMB_MIMATIC_LP',
      'mooTombBASED-MIMATIC': 'BASED_MIMATIC_LP',
    },
    collateralAddresses: {
      WFTM: '0xf1277d1ed8ad466beddf92ef448a132661956621',
      WETH: '0x4135c251eE7804A73dB09D36C306AE0214deA28B',
      WBTC: '0x0e9Cbd91546F290b0F99cF62DAC637B33D22D9B6',
      BOO: '0x9dAFB108f0fFd18C1f844C4782F8c7F934f8566E',
      fBEETS: '0x9dAFB108f0fFd18C1f844C4782F8c7F934f8566E',
      LINK: '0x9dAFB108f0fFd18C1f844C4782F8c7F934f8566E',
      SPELL: '0x180D7c72DAb76D11Dd8E0ffD4892ABc34D4d1f48',
      CRV: '0x9e5C734c28B8Df6F45d4b757F3dDFbc241042259',
      TOMB_FTM_LP: '0x0906E97beB6f422C239627FeFB9198144904327d',
      TOMB_MIMATIC_LP: '0x16c8deB0B2a1dfC8Fc44b4b2694ccAfa76dfE6B6',
      BASED_MIMATIC_LP: '0x323b65bC4F76b36AB57EAF4cFBD9561cfaAe5d29',
    },
    explorerLink: 'https://testnet.ftmscan.com',
    rpcOnly: true,
    usdMarket: false,
    isTestnet: true,
    chainName: 'Fantom Testnet',
    currency: {
      name: 'Fantom Coin',
      symbol: 'FTM',
      decimals: 18,
    },
  },
} as const;
