import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DepositAmount from './screens/DepositAmount';
import DepositConfirmation from './screens/DepositConfirmation';
import LeverageConfirmation from './screens/LeverageConfirmation';

import { CURRENCY_ROUTE_PARAMS } from '../../helpers/router-types';

export default function DepositCurrency() {
  return (
    <Switch>
      <Route exact={true} component={DepositAmount} path={`/provide/${CURRENCY_ROUTE_PARAMS}`} />

      <Route
        exact={true}
        path={`/provide/${CURRENCY_ROUTE_PARAMS}/confirmation`}
        component={DepositConfirmation}
      />

      <Route
        exact={true}
        path={`/provide/${CURRENCY_ROUTE_PARAMS}/leverage`}
        component={LeverageConfirmation}
      />
    </Switch>
  );
}
