import classNames from 'classnames';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { TokenIcon } from '../../../helpers/markets/assets';
import staticStyles from './style';

type BorrowAssetProps = {
  symbol: string;
  fullName: string;
  maxAmount?: number | string;
  className?: string;
  onSelect?: (symbol: string) => void;
  onWhiteBackground?: boolean;
};

export default function BorrowAsset({
  symbol,
  fullName,
  maxAmount,
  className,
  onSelect,
  onWhiteBackground,
}: BorrowAssetProps) {
  const { currentTheme, xl } = useThemeContext();

  return (
    <div className={classNames('BorrowAsset', className)} onClick={() => onSelect?.(symbol)}>
      <TokenIcon
        tokenSymbol={symbol}
        tokenFullName={fullName}
        onWhiteBackground={onWhiteBackground}
        width={xl ? 16 : 18}
        height={xl ? 16 : 18}
      />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .BorrowAsset {
          &:hover {
            background: ${currentTheme.white.hex};
            color: ${currentTheme.darkBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}
