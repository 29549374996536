import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DeleverageOptionArea {
    margin: 25px auto 0;
    width: 335px;
    position: relative;
    display: flex;
    flex-direction: column;

    @include respond-to(lg) {
      width: 260px;
    }
    @include respond-to(md) {
      width: 335px;
    }

    .CheckBoxField {
      label {
        justify-content: flex-start;
      }
    }
    h3 {
      font-size: $small;
    }

    &__inner {
      padding: 0 10px;
    }

    &__error-text {
      text-align: center;
      font-size: $medium;
      @include respond-to(lg) {
        font-size: $small;
      }
      @include respond-to(md) {
        font-size: $medium;
      }
    }

    &__info {
      width: 100%;
      padding: 5px;
      font-size: $small;

      &-inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    &__selector {
      margin: 20px 0;
      &-selection {
        padding: 5px;
      }
    }
    &__select-option {
      padding: 7px 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      &:disabled {
        cursor: default;
      }
    }

    &__flashloan {
      &-selector {
        margin: 0 5px;
        display: flex;
        flex-direction: row;
        .CheckBoxField {
          flex: 1;
          label {
            justify-content: flex-start;
          }
        }
      }
    }

    &__slippage {
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .TextWithModal__text {
        font-size: $small;
        font-weight: bold;
      }

      &-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &-wrapper {
        margin-left: 20px;
        margin-right: 5px;
        max-width: 50px;
        min-height: 25px;
        display: flex;
        align-items: center;

        input {
          text-align: right;
          padding-right: 5px;
        }
      }
    }
  }
`;

export default staticStyles;
