import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { valueToBigNumber, BigNumber, Network, ComputedReserveData } from '@sturdyfi/sturdy-js';

import BasicForm from 'src/components/forms/BasicForm';
import DeleverageOptionArea from 'src/components/DeleverageOptionArea';
import NoDataPanel from 'src/components/NoDataPanel';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from 'src/components/RouteParamsValidationWrapper';
import { useProvideCollateralContext } from 'src/components/wrappers/ScreensWrapper';
import useGetCurveExchangeRate from 'src/libs/hooks/use-curve-exchange-rate';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import defaultMessages from 'src/defaultMessages';
import messages from './messages';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useDynamicPoolDataContext } from 'src/libs/pool-data-provider';
import { mapChainIdToName } from 'src/libs/web3-data-provider';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';

function WithdrawAmount({
  currencySymbol,
  poolReserve,
  userReserve,
  user,
  history,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const [error, setError] = useState('');
  const [maxAmountToWithdraw, setMaxAmountToWithdraw] = useState('0');
  const [deleverage, setDeleverage] = useState(false);
  const [repayAssetID, setRepayAssetID] = useState('');
  const [repayAmount, setRepayAmount] = useState('0');
  const [slippage, setSlippage] = useState('1');
  const { reserves } = useDynamicPoolDataContext();
  const { lendingPool } = useTxBuilderContext();
  const { chainId } = useWeb3React<providers.Web3Provider>();
  const currentWalletNetwork = mapChainIdToName(chainId as number) as Network;

  const borrowData = reserves.filter(
    (reserve: ComputedReserveData) =>
      (user?.reservesData.find((userReserve) => userReserve.reserve.id === reserve.id)
        ?.totalBorrows || '0') !== '0'
  );

  const underlying =
    user?.reservesData.find((userReserve) => userReserve.reserve.id === poolReserve.id)
      ?.underlyingBalance || '0';

  const underlyingInETH = valueToBigNumber(underlying)
    .multipliedBy(poolReserve.price.priceInEth)
    .toString();

  const healthFactorLimit = '1.05';
  const maxRepayableInETH = valueToBigNumber(underlyingInETH)
    .multipliedBy(poolReserve.reserveLiquidationThreshold)
    .dividedBy(healthFactorLimit);

  const availableDebtAssetOptions = borrowData.map((reserve: ComputedReserveData) => {
    const borrowedAmount =
      user?.reservesData.find((userReserve) => userReserve.reserve.id === reserve.id)
        ?.variableBorrows || '0';

    const repayableInETH = BigNumber.min(
      valueToBigNumber(borrowedAmount).multipliedBy(reserve.price.priceInEth),
      maxRepayableInETH
    );

    const maxRepayAmount = repayableInETH.dividedBy(reserve.price.priceInEth).toString();

    return {
      label: reserve.symbol,
      value: reserve.underlyingAsset,
      decimals: reserve.decimals,
      debt: maxRepayAmount,
    };
  });

  useEffect(() => {
    if (!deleverage) {
      setRepayAmount('0');
      setRepayAssetID('');
    }

    let maxUserAmountToWithdraw = BigNumber.min(
      userReserve?.underlyingBalance || '0',
      poolReserve.availableLiquidity
    ).toString(10);

    if (
      user &&
      userReserve?.usageAsCollateralEnabledOnUser &&
      poolReserve.usageAsCollateralEnabled &&
      user?.totalBorrowsETH !== '0'
    ) {
      // if we have any borrowings we should check how much we can withdraw without liquidation
      // with 0.5% gap to avoid reverting of tx
      let totalCollateralToWithdrawInETH = valueToBigNumber('0');
      const excessHF = valueToBigNumber(user.healthFactor).minus('1');
      if (excessHF.gt('0')) {
        totalCollateralToWithdrawInETH = excessHF
          .multipliedBy(user.totalBorrowsETH)
          // because of the rounding issue on the contracts side this value still can be incorrect
          .div(Number(poolReserve.reserveLiquidationThreshold) + 0.01)
          .multipliedBy('0.99');

        // When deleverage
        if (deleverage && repayAssetID) {
          const repayAsset = borrowData.find((reserve) => reserve.underlyingAsset === repayAssetID);
          if (repayAsset) {
            const repayAmountInETH = valueToBigNumber(repayAmount).multipliedBy(
              repayAsset.price.priceInEth
            );

            if (valueToBigNumber(user.totalBorrowsETH).minus(repayAmountInETH).abs().lt('0.0001')) {
              totalCollateralToWithdrawInETH = valueToBigNumber(user.totalCollateralETH)
                .multipliedBy(user.currentLiquidationThreshold)
                .dividedBy(poolReserve.reserveLiquidationThreshold);
            } else {
              totalCollateralToWithdrawInETH = totalCollateralToWithdrawInETH.plus(
                repayAmountInETH.multipliedBy(
                  valueToBigNumber('1').dividedBy(poolReserve.reserveLiquidationThreshold)
                )
              );
            }
            totalCollateralToWithdrawInETH = BigNumber.min(
              valueToBigNumber(maxUserAmountToWithdraw).multipliedBy(poolReserve.price.priceInEth),
              totalCollateralToWithdrawInETH
            ).minus(repayAmountInETH);
          }
        }
      }
      maxUserAmountToWithdraw = BigNumber.min(
        maxUserAmountToWithdraw,
        totalCollateralToWithdrawInETH.dividedBy(poolReserve.price.priceInEth)
      ).toString();
    }

    maxUserAmountToWithdraw = BigNumber.max(maxUserAmountToWithdraw, 0).toString();
    setMaxAmountToWithdraw(maxUserAmountToWithdraw);
  }, [user, userReserve, poolReserve, deleverage, repayAssetID]);

  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  if (!userReserve) {
    return null;
  }

  const handleWithdrawSubmit = (amount: string, max?: boolean) => {
    if (deleverage) {
      if (repayAssetID === '') {
        setError(intl.formatMessage(messages.errorDeleverage));
        return;
      }
      if (valueToBigNumber(repayAmount).eq('0')) {
        setError(intl.formatMessage(messages.errorRepayAmount));
        return;
      }
      if (valueToBigNumber(amount).gt(valueToBigNumber(maxAmountToWithdraw))) {
        setError(intl.formatMessage(messages.errorWithdrawAmount));
        return;
      }
    }

    const asset = borrowData.find((reserve) => reserve.underlyingAsset === repayAssetID);
    const query = queryString.stringify({
      amount: deleverage ? amount : max ? -1 : amount,
      leverageEnabled: deleverage,
      levBorrowAssetSymbol: deleverage ? asset?.symbol || '' : '',
      slippage,
      repayAmount,
    });
    history.push(`${history.location.pathname}/confirmation?${query}`);
  };

  const handleTransactionData = (userId: string) => async () => {
    return await lendingPool.withdraw({
      user: userId,
      reserve: poolReserve.underlyingAsset,
      amount: '-1',
      aTokenAddress: poolReserve.aTokenAddress,
    });
  };

  const handleRepayAssetChange = (underlyingAsset: string) => {
    setError('');
    setRepayAssetID(underlyingAsset);
    setRepayAmount('0');
  };

  const handleRepayAmountChange = (value: string) => {
    setError('');
    setRepayAmount(value);
  };

  const handleSlippageChange = (value: string) => {
    const formattedValue = Number(value) > 100 ? '100' : value;
    setSlippage(formattedValue);
  };

  const { tokenSymbol, setTokenSymbol } = useProvideCollateralContext();
  const { networkConfig } = useProtocolDataContext();
  if (!networkConfig.collateralAssets?.[currencySymbol].includes(tokenSymbol))
    setTokenSymbol(currencySymbol);
  const handleTokenSwitcher = (token: string) => {
    setTokenSymbol(token);
  };

  let ethAmount: string = '0';
  if (currentWalletNetwork === Network.ftm_test || currentWalletNetwork === Network.ftm) {
    ethAmount = maxAmountToWithdraw;
  } else if (tokenSymbol === networkConfig.baseAsset) {
    const { data } = useGetCurveExchangeRate('stETH', 'ETH', maxAmountToWithdraw);
    ethAmount = data ? Number(data).toString() : '0';
  }

  return (
    <>
      <BasicForm
        title={intl.formatMessage(defaultMessages.withdraw)}
        description={intl.formatMessage(messages.formDescription)}
        maxAmount={tokenSymbol === networkConfig.baseAsset ? ethAmount : maxAmountToWithdraw}
        currencySymbol={tokenSymbol}
        onSubmit={handleWithdrawSubmit}
        amountFieldTitle={intl.formatMessage(messages.amountTitle)}
        absoluteMaximum={true}
        maxDecimals={poolReserve.decimals}
        getTransactionData={handleTransactionData}
        depositCollateral={true}
        handleTokenSwitcher={
          networkConfig.collateralAssets?.[currencySymbol].includes(networkConfig.baseAsset)
            ? handleTokenSwitcher
            : undefined
        }
        childrenAtBottom={true}
        // amountReadOnly={deleverage}
      >
        {poolReserve.leverageEnabled && borrowData.length > 0 && (
          <DeleverageOptionArea
            enabled={deleverage}
            onEnableChange={setDeleverage}
            repayAsset={repayAssetID}
            repayAssets={availableDebtAssetOptions}
            onRepayAssetChange={handleRepayAssetChange}
            repayAmount={repayAmount}
            onRepayAmountChange={handleRepayAmountChange}
            slippage={slippage}
            onSlippageChange={handleSlippageChange}
            error={error}
          />
        )}
      </BasicForm>
    </>
  );
}

export default routeParamValidationHOC({
  withUserReserve: true,
})(WithdrawAmount);
