import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { valueToBigNumber, InterestRate, Network } from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import classNames from 'classnames';

import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useDynamicPoolDataContext, useReserveAPYDataContext } from 'src/libs/pool-data-provider';
import { loanActionLinkComposer } from 'src/helpers/loan-action-link-composer';
import { toggleUseAsCollateral } from 'src/helpers/toggle-use-as-collateral';
import { toggleBorrowRateMode } from 'src/helpers/toggle-borrow-rate-mode';
import LabeledSwitcher from 'src/components/basic/LabeledSwitcher';
import NoDataPanel from 'src/components/NoDataPanel';
import ContentWrapper from 'src/components/wrappers/ContentWrapper';
import Row from 'src/components/basic/Row';
import Value from 'src/components/basic/Value';
import MaxLTVHelpModal from 'src/components/HelpModal/MaxLTVHelpModal';
import ValuePercent from 'src/components/basic/ValuePercent';
import HealthFactor from 'src/components/HealthFactor';
import DefaultButton from 'src/components/basic/DefaultButton';
import NoData from 'src/components/basic/NoData';
import DepositCompositionBar from 'src/components/compositionBars/DepositCompositionBar';
// import CollateralCompositionBar from 'src/components/compositionBars/CollateralCompositionBar';
import BorrowCompositionBar from 'src/components/compositionBars/BorrowCompositionBar';
import LTVInfoModal from 'src/components/LTVInfoModal';
import MainDashboardTable from '../../components/MainDashboardTable';
import MobileTopPanelWrapper from '../../components/MobileTopPanelWrapper';
import DepositBorrowTopPanel from 'src/components/DepositBorrowTopPanel';
import ApproximateBalanceHelpModal from 'src/components/HelpModal/ApproximateBalanceHelpModal';
import IncentiveWrapper from 'src/components/wrappers/IncentiveWrapper';
import DashboardNoData from '../../components/DashboardNoData';

import { DepositTableItem } from '../../../deposit/components/DepositDashboardTable/types';
import { BorrowTableItem } from '../../../borrow/components/BorrowDashboardTable/types';
import { DashboardLeftTopLine } from 'src/ui-config';
import { getAssetColor } from 'src/helpers/markets/assets';

import messages from './messages';
import staticStyles from './style';
import StableRewardWrapper from 'src/components/wrappers/StableRewardWrapper';
import VariableRewardWrapper from 'src/components/wrappers/VariableRewardWrapper';

export default function Dashboard() {
  const intl = useIntl();
  const history = useHistory();
  const { network } = useProtocolDataContext();
  const { user } = useDynamicPoolDataContext();
  const { reserves } = useReserveAPYDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  const { currentTheme, sm } = useThemeContext();
  const { networkConfig } = useProtocolDataContext();

  const [isLTVModalVisible, setLTVModalVisible] = useState(false);
  const [isBorrow, setIsBorrow] = useState(false);
  const [isDepositMobileInfoVisible, setDepositMobileInfoVisible] = useState(false);
  const [isBorrowMobileInfoVisible, setBorrowMobileInfoVisible] = useState(false);

  const maxBorrowAmount = valueToBigNumber(user?.totalBorrowsETH || '0').plus(
    user?.availableBorrowsETH || '0'
  );
  const collateralUsagePercent = maxBorrowAmount.eq(0)
    ? '1'
    : valueToBigNumber(user?.totalBorrowsETH || '0')
        .div(maxBorrowAmount)
        .toFixed();

  const loanToValue = valueToBigNumber(user?.totalBorrowsETH || '0')
    .dividedBy(user?.totalCollateralETH || '1')
    .toFixed();

  const depositedPositions: DepositTableItem[] = [];
  const borrowedPositions: BorrowTableItem[] = [];

  if (reserves && reserves.length) {
    user?.reservesData.forEach((userReserve) => {
      const symbol =
        networkConfig.collateralAssetFromSymbol?.[userReserve.reserve.symbol] ||
        userReserve.reserve.symbol;
      const poolReserve = reserves.find((res) => res.id === userReserve.reserve.id);
      if (!poolReserve) {
        throw new Error(
          `Pool for ${symbol}:${userReserve.reserve.symbol} is not available on ${reserves.length} reserves.`
        );
      }

      const reserveIncentiveData =
        reserveIncentives[userReserve.reserve.underlyingAsset.toLowerCase()];
      if (userReserve.underlyingBalance !== '0' || userReserve.totalBorrows !== '0') {
        const baseListData = {
          uiColor: getAssetColor(userReserve.reserve.symbol),
          isActive: poolReserve.isActive,
          isFrozen: poolReserve.isFrozen,
          stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
          reserve: {
            ...userReserve.reserve,
            liquidityRate: poolReserve.variableDepositAPY,
          },
        };
        if (
          userReserve.underlyingBalance !==
          '0' /*&& !networkConfig.collateralAssets?.includes(userReserve.reserve.symbol)*/
        ) {
          depositedPositions.push({
            ...baseListData,
            borrowingEnabled: poolReserve.borrowingEnabled,
            avg30DaysLiquidityRate: poolReserve.avg30DaysLiquidityRate,
            usageAsCollateralEnabledOnThePool: poolReserve.usageAsCollateralEnabled,
            usageAsCollateralEnabledOnUser: userReserve.usageAsCollateralEnabledOnUser,
            underlyingBalance: userReserve.underlyingBalance,
            underlyingBalanceUSD: userReserve.underlyingBalanceUSD,
            aincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.aIncentives.incentiveAPR
              : '0',
            onToggleSwitch: () =>
              toggleUseAsCollateral(
                history,
                poolReserve.id,
                !userReserve.usageAsCollateralEnabledOnUser,
                poolReserve.underlyingAsset
              ),
          });
        }

        if (userReserve.variableBorrows !== '0') {
          borrowedPositions.push({
            ...baseListData,
            borrowingEnabled: poolReserve.borrowingEnabled,
            currentBorrows: userReserve.variableBorrows,
            currentBorrowsUSD: userReserve.variableBorrowsUSD,
            borrowRateMode: InterestRate.Variable,
            borrowRate: poolReserve.variableBorrowAPY,
            vincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.vIncentives.incentiveAPR
              : '0',
            sincentivesAPR: reserveIncentiveData
              ? reserveIncentiveData.sIncentives.incentiveAPR
              : '0',
            avg30DaysVariableRate: poolReserve.avg30DaysVariableBorrowRate,
            repayLink: loanActionLinkComposer(
              'repay',
              poolReserve.id,
              InterestRate.Variable,
              poolReserve.underlyingAsset
            ),
            borrowLink: loanActionLinkComposer(
              'borrow',
              poolReserve.id,
              InterestRate.Variable,
              poolReserve.underlyingAsset
            ),
            onSwitchToggle: () =>
              toggleBorrowRateMode(
                history,
                poolReserve.id,
                InterestRate.Variable,
                poolReserve.underlyingAsset
              ),
          });
        }
      }
    });
  }

  return (
    <div className="Dashboard">
      <div
        className={classNames('Dashboard__mobileMigrate--inner', {
          Dashboard__mobileMigrateWithoutContent:
            network !== Network.mainnet && !depositedPositions.length,
        })}
      >
        <DashboardLeftTopLine intl={intl} network={network} onMobile={true} />
      </div>

      {user && !!depositedPositions.length && (
        <div className="Dashboard__switcher-inner">
          <LabeledSwitcher
            rightOption={intl.formatMessage(messages.switchRightOption)}
            leftOption={intl.formatMessage(messages.switchLeftOption)}
            value={isBorrow}
            onToggle={() => {
              setIsBorrow(!isBorrow);
              setDepositMobileInfoVisible(false);
              setBorrowMobileInfoVisible(false);
            }}
            className="Dashboard__switcher"
          />
        </div>
      )}

      <div className="Dashboard__top--line">
        <div className="ButtonLink">
          <DashboardLeftTopLine intl={intl} network={network} />
        </div>
        <IncentiveWrapper />
        <StableRewardWrapper />
        <VariableRewardWrapper />
      </div>

      <DepositBorrowTopPanel />

      {user && !!depositedPositions.length && !isBorrow && (
        <MobileTopPanelWrapper
          visible={isDepositMobileInfoVisible}
          setVisible={setDepositMobileInfoVisible}
          buttonComponent={
            <Row
              title={
                <ApproximateBalanceHelpModal
                  text={intl.formatMessage(messages.approximateBalance)}
                  color="white"
                  lightWeight={true}
                />
              }
              color="white"
              weight="light"
            >
              {user && user.totalLiquidityUSD !== '0' ? (
                <Value
                  value={user.totalLiquidityUSD}
                  symbol="USD"
                  tokenIcon={true}
                  withSmallDecimals={true}
                  // subValue={user.totalLiquidityETH}
                  maximumSubValueDecimals={18}
                  subSymbol="ETH"
                  color="white"
                />
              ) : (
                <NoData />
              )}
            </Row>
          }
        >
          <DepositCompositionBar user={user} />
        </MobileTopPanelWrapper>
      )}

      {user && !!borrowedPositions.length && isBorrow && (
        <MobileTopPanelWrapper
          visible={isBorrowMobileInfoVisible}
          setVisible={setBorrowMobileInfoVisible}
          buttonComponent={
            <>
              <Row
                title={intl.formatMessage(messages.youBorrowed)}
                color="white"
                weight="light"
                withMargin={!isBorrowMobileInfoVisible}
              >
                {user && user.totalBorrowsUSD !== '0' ? (
                  <Value
                    value={user.totalBorrowsUSD}
                    symbol="USD"
                    tokenIcon={true}
                    minimumValueDecimals={2}
                    maximumValueDecimals={2}
                    subValue={user.totalBorrowsETH}
                    subSymbol="ETH"
                    color="white"
                  />
                ) : (
                  <NoData />
                )}
              </Row>
              {!isBorrowMobileInfoVisible && (
                <HealthFactor
                  value={user?.healthFactor || '-1'}
                  titleColor="white"
                  titleLightWeight={true}
                />
              )}
            </>
          }
        >
          <Row
            title={intl.formatMessage(messages.yourCollateral)}
            color="white"
            weight="light"
            withMargin={true}
          >
            {user && user.totalCollateralUSD !== '0' ? (
              <Value
                value={user.totalCollateralUSD}
                symbol="USD"
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
                subValue={user.totalCollateralETH}
                subSymbol="ETH"
                color="white"
              />
            ) : (
              <NoData />
            )}
          </Row>

          <HealthFactor
            value={user?.healthFactor || '-1'}
            titleColor="white"
            titleLightWeight={true}
          />

          <Row
            title={
              <MaxLTVHelpModal
                text={intl.formatMessage(messages.currentLTV)}
                color="white"
                lightWeight={true}
              />
            }
            color="white"
            weight="light"
            withMargin={true}
            className="Dashboard__mobileRow-center"
          >
            {user && loanToValue !== '0' ? (
              <div className="Dashboard__mobileRow-content">
                <ValuePercent value={loanToValue} color="white" />
                <DefaultButton
                  title={intl.formatMessage(messages.details)}
                  color="white"
                  transparent={true}
                  className="Dashboard__mobileButton"
                  size="small"
                  onClick={() => setLTVModalVisible(true)}
                />
              </div>
            ) : (
              <NoData />
            )}
          </Row>

          <Row
            title={intl.formatMessage(messages.borrowingPowerUsed)}
            color="white"
            weight="light"
            withMargin={true}
          >
            {user && collateralUsagePercent !== '0' ? (
              <ValuePercent value={collateralUsagePercent} color="white" />
            ) : (
              <NoData />
            )}
          </Row>

          <BorrowCompositionBar />
          {/* <CollateralCompositionBar /> */}
        </MobileTopPanelWrapper>
      )}

      {sm && <IncentiveWrapper />}
      {sm && <StableRewardWrapper />}
      {sm && <VariableRewardWrapper />}

      {user ? (
        <>
          {!!depositedPositions.length || !!borrowedPositions.length ? (
            <MainDashboardTable
              borrowedPositions={borrowedPositions}
              depositedPositions={depositedPositions}
              isBorrow={isBorrow}
            />
          ) : (
            <DashboardNoData />
          )}
        </>
      ) : (
        <ContentWrapper withBackButton={true} withFullHeight={true}>
          <NoDataPanel
            title={intl.formatMessage(messages.connectWallet)}
            description={intl.formatMessage(messages.connectWalletDescription)}
            withConnectButton={true}
          />
        </ContentWrapper>
      )}

      {loanToValue !== '0' && (
        <LTVInfoModal visible={isLTVModalVisible} setVisible={setLTVModalVisible} />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .Dashboard {
          &__mobileMigrate--inner {
            background: ${currentTheme.whiteElement.hex};
          }
          &__mobileMigrateWithoutContent {
            background: ${currentTheme.cream.hex};
          }

          &__changeMarket--button {
            color: ${currentTheme.primary.hex};
          }
        }
      `}</style>
    </div>
  );
}
