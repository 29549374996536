import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { normalize } from '@sturdyfi/sturdy-js';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';

import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import Row from '../../../../components/basic/Row';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import Value from '../../../../components/basic/Value';

import messages from './messages';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';

export function StableRewardConfirm() {
  const intl = useIntl();
  const location = useLocation();

  const { user } = useDynamicPoolDataContext();
  const { userReward, stableRewardsTxBuilder } = useIncentivesDataContext();
  const asset = location.pathname.split('/')[0];
  const distributorAddress = location.pathname.split('/')[1];
  const rewardData = userReward?.find(
    (item) => item.rewardTokenAddress.toLowerCase() == asset.toLocaleLowerCase()
  );
  const rewardTokenSymbol = rewardData?.rewardUserDatas?.[0].rewardTokenSymbol;

  const aTokenData = getAtokenInfo({
    address: rewardData?.rewardTokenAddress || '',
    symbol: rewardData?.rewardUserDatas?.[0].rewardTokenSymbol || '',
    decimals: 18,
    withFormattedSymbol: true,
  });

  if (
    (Number(rewardData?.totalUnclaimedRewards) < 0 &&
      !(Number(rewardData?.totalUnclaimedRewards) == -1)) ||
    !user
  ) {
    return null;
  }

  let blockingError = '';
  if (Number(rewardData?.totalUnclaimedRewards) == 0) {
    blockingError = intl.formatMessage(messages.notEnoughBalance);
  }

  const formattedAmount = normalize(Number(rewardData?.totalUnclaimedRewards), 18);

  const assets = [rewardData?.rewardUserDatas?.[0].tokenAddress || ''];
  const handleGetTransactions = async () =>
    stableRewardsTxBuilder.claimRewards({
      user: user.id,
      assets,
      to: user.id,
      distributorAddress,
    });

  return (
    <ScreenWrapper
      pageTitle={intl.formatMessage(messages.pageTitle)}
      isTitleOnDesktop={true}
      withMobileGrayBg={true}
    >
      <ContentWrapper withBackButton={true} withFullHeight={true}>
        <PoolTxConfirmationView
          caption={intl.formatMessage(messages.title)}
          description={intl.formatMessage(messages.description)}
          getTransactionsData={handleGetTransactions}
          boxTitle={intl.formatMessage(messages.claim)}
          boxDescription={intl.formatMessage(messages.boxDescription)}
          mainTxName={intl.formatMessage(messages.claim)}
          blockingError={blockingError}
          goToAfterSuccess="/dashboard"
          aTokenData={aTokenData}
          dangerousMessage={undefined}
        >
          <Row title={intl.formatMessage(messages.claim)}>
            <Value
              symbol={rewardTokenSymbol}
              value={formattedAmount}
              tokenIcon={true}
              tooltipId={rewardTokenSymbol}
            />
          </Row>
        </PoolTxConfirmationView>
      </ContentWrapper>
    </ScreenWrapper>
  );
}
