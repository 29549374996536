import React from 'react';
import { useIntl } from 'react-intl';
import { normalize } from '@aave/math-utils';
import { getAssetInfo, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useDynamicPoolDataContext } from '../../../libs/pool-data-provider';
import IncentiveClaimItem from '../../IncentiveClaimItem';

import messages from './messages';
import staticStyles from './style';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import { REWARD_TYPE } from 'src/helpers/markets/markets-data';
import { UserReserveRewardData } from 'src/libs/pool-data-provider/hooks/use-incentives-data';

export default function VariableRewardWrapper() {
  const intl = useIntl();
  const { currentTheme, sm, isCurrentThemeDark } = useThemeContext();

  const { user } = useDynamicPoolDataContext();
  const { userReward } = useIncentivesDataContext();

  // Only display assets for which user has claimable rewards
  const rewardsFiltered = userReward?.filter(
    (entry) =>
      entry.totalUnclaimedRewards > 0 &&
      entry.rewardUserDatas[0].rewardType === REWARD_TYPE.VARIABLE
  );

  if (!user || !rewardsFiltered || Object.keys(rewardsFiltered).length === 0) return null;

  return (
    <div className="VariableRewardWrapper">
      <div className="VariableRewardWrapper__rewards">
        {rewardsFiltered.map((reward) => {
          const claimableRewards = normalize(Number(reward.totalUnclaimedRewards), 18);

          return (
            <div className="VariableRewardWrapper__item" key={reward.id}>
              <p className="VariableRewardWrapper__item__title">
                {intl.formatMessage(messages.availableReward, {
                  symbol: reward.rewardUserDatas[0].rewardTokenSymbol || '',
                })}
              </p>
              <IncentiveClaimItem
                symbol={reward.rewardUserDatas[0].rewardTokenSymbol || ''}
                claimableRewards={claimableRewards}
                incentiveControllerAddress={reward.id}
                type={REWARD_TYPE.VARIABLE}
              />
            </div>
          );
        })}
      </div>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .VariableRewardWrapper {
          background: ${currentTheme.darkBlue.hex};

          border: 1px solid ${currentTheme.lightBlue.hex};
          &:hover {
            border: 1px solid
              ${sm && !isCurrentThemeDark ? currentTheme.lightBlue.hex : currentTheme.white.hex};
          }

          &__item {
            &__title {
              color: ${currentTheme.cream.hex};
              font-weight: 400;
            }
          }
        }
      `}</style>
    </div>
  );
}
