import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import {
  ComputedUserReserve,
  UserSummaryData,
  ComputedReserveData,
  BigNumber,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';

import { API_ETH_MOCK_AAVE_ADDRESS } from 'src/config';
import {
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
  useReserveAPYDataContext,
} from 'src/libs/pool-data-provider';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import { CurrencyRouteParamsInterface } from 'src/helpers/router-types';
import Preloader from '../basic/Preloader';
import ErrorPage from '../ErrorPage';

import messages from './messages';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

export interface ValidationWrapperComponentProps
  extends Pick<RouteComponentProps, 'history' | 'location'> {
  currencySymbol: string;
  amount?: BigNumber;
  walletBalance: BigNumber;
  walletEthBalance: BigNumber;
  walletBalanceUSD: BigNumber;
  isWalletBalanceEnough: boolean;
  user?: UserSummaryData;
  poolReserve: ComputedReserveData;
  userReserve?: ComputedUserReserve;
  // leverage & deleverage
  leverageCount?: number;
  levBorrowAssetSymbol?: string;
  leverageEnabled?: boolean;
  slippage?: string;
  repayAmount?: BigNumber;
}

interface RouteParamValidationWrapperProps {
  withUserReserve?: boolean;
  withWalletBalance?: boolean;
  withAmount?: boolean;
  allowLimitAmount?: boolean; // -1 for sending everything
  withLeverage?: boolean;
  withDeleverage?: boolean;
}

export default function routeParamValidationHOC({
  withUserReserve,
  withWalletBalance,
  withAmount,
  allowLimitAmount,
  withLeverage,
  withDeleverage,
}: RouteParamValidationWrapperProps) {
  return (ChildComponent: React.ComponentType<ValidationWrapperComponentProps>) =>
    ({ match, location, history }: RouteComponentProps<CurrencyRouteParamsInterface>) => {
      const intl = useIntl();
      const underlyingAsset = match.params.underlyingAsset.toUpperCase();
      const reserveId = match.params.id;

      const { usdPriceEth } = useStaticPoolDataContext();
      const { user } = useDynamicPoolDataContext();
      const { reserves } = useReserveAPYDataContext();
      const { networkConfig } = useProtocolDataContext();

      const poolReserve = reserves.find((res) =>
        reserveId
          ? res.id === reserveId
          : res.underlyingAsset.toLowerCase() === underlyingAsset.toLowerCase()
      );
      const userReserve = user
        ? user.reservesData.find((userReserve) =>
            reserveId
              ? userReserve.reserve.id === reserveId
              : userReserve.reserve.underlyingAsset.toLowerCase() === underlyingAsset.toLowerCase()
          )
        : undefined;

      const currencySymbol = poolReserve?.symbol || '';

      const { walletData } = useWalletBalanceProviderContext({
        skip: !withWalletBalance || !poolReserve || (withUserReserve && !userReserve),
      });
      if (!walletData) {
        return <Preloader withText={true} />;
      }

      if (!poolReserve) {
        // TODO: 404
        return <Redirect to="/" />;
      }
      if (!userReserve && withUserReserve) {
        return <Redirect to="/" />;
        // TODO: 404 || redirect || ?
      }

      const underlyingAssetAddress =
        networkConfig.collateralAddresses?.[poolReserve.symbol] || poolReserve.underlyingAsset;
      const walletBalance = valueToBigNumber(
        walletData[underlyingAssetAddress as string] || '0'
      ).dividedBy(valueToBigNumber(10).pow(poolReserve.decimals));

      const walletEthBalance = valueToBigNumber(
        walletData[API_ETH_MOCK_AAVE_ADDRESS] || '0'
      ).dividedBy(valueToBigNumber(10).pow(poolReserve.decimals));

      let isWalletBalanceEnough = true;

      let amount = undefined;
      if (withAmount) {
        const query = queryString.parse(location.search);
        if (typeof query.amount === 'string') {
          amount = valueToBigNumber(query.amount);
        }
        if (
          !amount ||
          amount.isNaN() ||
          !((allowLimitAmount && amount.eq('-1')) || amount.isPositive())
        ) {
          // TODO: amount invalid
          return <ErrorPage description={intl.formatMessage(messages.error)} buttonType="back" />;
        }
        if (
          withWalletBalance &&
          (walletBalance.eq(0) || (!amount.eq('-1') && amount.gt(walletBalance)))
        ) {
          // TODO: wallet balance is too low
          isWalletBalanceEnough = false;
        }
      }

      let leverageCount = undefined;
      let levBorrowAssetSymbol = undefined;
      let leverageEnabled = undefined;
      let slippage = undefined;
      let repayAmount = undefined;
      if (withLeverage) {
        const query = queryString.parse(location.search);
        if (typeof query.leverageCount === 'string') {
          leverageCount = Number(query.leverageCount);
        }
        if (!leverageCount || Number.isNaN(leverageCount) /*|| !Number.isInteger(leverageCount)*/) {
          // TODO: amount invalid
          return (
            <ErrorPage
              description={intl.formatMessage(messages.errLeverageCount)}
              buttonType="back"
            />
          );
        }

        if (typeof query.levBorrowAssetSymbol === 'string') {
          levBorrowAssetSymbol = query.levBorrowAssetSymbol;
        }
        if (!levBorrowAssetSymbol) {
          // TODO: amount invalid
          return (
            <ErrorPage
              description={intl.formatMessage(messages.errLeverageBorrowAsset)}
              buttonType="back"
            />
          );
        }
      }

      if (withDeleverage) {
        const query = queryString.parse(location.search);
        if (typeof query.leverageEnabled === 'string') {
          leverageEnabled = query.leverageEnabled === 'true';
        }
        if (typeof query.levBorrowAssetSymbol === 'string') {
          levBorrowAssetSymbol = query.levBorrowAssetSymbol;
        }
        if (leverageEnabled && !levBorrowAssetSymbol) {
          return (
            <ErrorPage
              description={intl.formatMessage(messages.errDeleverageBorrowAsset)}
              buttonType="back"
            />
          );
        }
        if (typeof query.slippage === 'string') {
          slippage = '' + Number(query.slippage) * 100;
        }
        if (typeof query.repayAmount === 'string') {
          repayAmount = valueToBigNumber(query.repayAmount);
        }
      }

      const walletBalanceUSD = valueToBigNumber(walletBalance)
        .multipliedBy(poolReserve.price.priceInEth)
        .dividedBy(usdPriceEth);

      const props = {
        poolReserve,
        userReserve,
        amount,
        user,
        walletBalance,
        walletEthBalance,
        walletBalanceUSD,
        isWalletBalanceEnough,
        currencySymbol,
        underlyingAsset,
        history,
        location,
        leverageCount,
        levBorrowAssetSymbol,
        leverageEnabled,
        slippage,
        repayAmount,
      };
      return <ChildComponent {...props} />;
    };
}
