import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import Footer from '../../Footer';
import Menu from '../../menu/Menu';
import { BottomDisclaimer, TopDisclaimer } from '../../../ui-config';

import messages from './messages';
import staticStyles from './style';

// import background from '../../../images/background.svg';
// import backgroundDark from '../../../images/backgroundDark.svg';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

export interface ScreensWrapperProps {
  children: ReactNode;
}

export const TitleContext = createContext({
  title: '',
  setTitle: (title: string) => {},
});

export function useHeaderTitle() {
  const { title, setTitle } = useContext(TitleContext);
  return { title, setTitle };
}

export const TopPanelSmallContext = createContext({
  isTopPanelSmall: false,
  setTopPanelSmall: (isSmallTopLine: boolean) => {},
});

export function useWithDesktopTitle() {
  const { isTopPanelSmall, setTopPanelSmall } = useContext(TopPanelSmallContext);
  return { isTopPanelSmall, setTopPanelSmall };
}

export const ProvideCollateralContext = createContext({
  tokenSymbol: 'ETH',
  setTokenSymbol: (token: string) => {},
});

export function useProvideCollateralContext() {
  const { tokenSymbol, setTokenSymbol } = useContext(ProvideCollateralContext);
  return { tokenSymbol, setTokenSymbol };
}

export default function ScreensWrapper({ children }: ScreensWrapperProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();

  const [title, setTitle] = useState(intl.formatMessage(messages.pageTitle));
  const [isTopPanelSmall, setTopPanelSmall] = useState(
    localStorage.getItem('isTopPanelSmall') === 'true' || false
  );
  const { networkConfig } = useProtocolDataContext();
  const [tokenSymbol, setTokenSymbol] = useState(
    localStorage.getItem('SelectedTokenSymbol') || networkConfig.baseAsset
  );

  const cacheTokenSymbol = (token: string) => {
    localStorage.setItem('SelectedTokenSymbol', token);
    setTokenSymbol(token);
  };

  return (
    <div
      className={classNames('ScreensWrapper', {
        ScreensWrapper__topPanelSmall: isTopPanelSmall,
      })}
    >
      <BottomDisclaimer />

      <TopDisclaimer />
      <Menu title={title} />

      <main className="ScreensWrapper__content" id="ScreensWrapper__content-wrapper">
        {/* <div className="ScreensWrapper__top-contentWrapper" /> */}

        <TitleContext.Provider value={{ title, setTitle }}>
          <TopPanelSmallContext.Provider value={{ isTopPanelSmall, setTopPanelSmall }}>
            <ProvideCollateralContext.Provider
              value={{ tokenSymbol, setTokenSymbol: cacheTokenSymbol }}
            >
              {children}
            </ProvideCollateralContext.Provider>
          </TopPanelSmallContext.Provider>
        </TitleContext.Provider>
      </main>

      <Footer inside={true} />

      {/* <img
        className="ScreensWrapper__background"
        src={isCurrentThemeDark ? backgroundDark : background}
        alt=""
      /> */}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';

        .ScreensWrapper {
          background: ${currentTheme.mainBg.hex};

          &__top-contentWrapper {
            background: ${currentTheme.headerBg.hex};
            &:after {
              background: ${currentTheme.headerBg.hex};
            }
          }
        }
      `}</style>
    </div>
  );
}
