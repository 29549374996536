import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber, ComputedReserveData } from '@sturdyfi/sturdy-js';

import NoDataPanel from 'src/components/NoDataPanel';
import ContentWrapper from 'src/components/wrappers/ContentWrapper';
import BorrowDashboardTable from 'src/modules/borrow/components/BorrowDashboardTable';
import { BorrowTableItem } from 'src/modules/borrow/components/BorrowDashboardTable/types';
import DepositDashboardTable from 'src/modules/deposit/components/DepositDashboardTable';
import { DepositTableItem } from 'src/modules/deposit/components/DepositDashboardTable/types';
import CollateralDashboradTable from 'src/modules/borrow/components/CollateralDashboardTable';
import { DepositTableItem as DepositAssetsTableItem } from 'src/modules/deposit/components/DepositAssetsTable/types';

import { isAssetStable } from 'src/helpers/markets/assets';
import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import Preloader from 'src/components/basic/Preloader';

import { API_ETH_MOCK_AAVE_ADDRESS } from 'src/config';

import messages from './messages';
import staticStyles from './style';

interface MainDashboardTableProps {
  depositedPositions: DepositTableItem[];
  borrowedPositions: BorrowTableItem[];
  isBorrow: boolean;
}

export default function MainDashboardTable({
  depositedPositions,
  borrowedPositions,
  isBorrow,
}: MainDashboardTableProps) {
  const intl = useIntl();

  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves, user } = useDynamicPoolDataContext();

  const [searchValue, setSearchValue] = useState('');
  const [showOnlyStableCoins, setShowOnlyStableCoins] = useState(false);

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);

  // const approvedCollateral = ['stETH'];
  const collateralReserves = reserves.filter(
    ({ symbol, usageAsCollateralEnabled, isActive }) =>
      symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      isActive &&
      usageAsCollateralEnabled &&
      (!showOnlyStableCoins || isAssetStable(symbol))
    // (!showOnlyStableCoins || isAssetStable(symbol)) &&
    // approvedCollateral.includes(symbol)
  );

  const { walletData } = useWalletBalanceProviderContext();

  if (!walletData) {
    return <Preloader withText={true} />;
  }

  const listData2 = (withFilter: boolean) => {
    const data = (reserves: ComputedReserveData[]) =>
      reserves.map<DepositAssetsTableItem>((reserve) => {
        const userReserves =
          user?.reservesData.filter((userRes) => userRes.reserve.symbol === reserve.symbol) || [];
        const userReserve = userReserves?.length > 1 ? userReserves[1] : userReserves?.[0];
        const walletBalance =
          walletData[reserve.underlyingAsset] === '0'
            ? valueToBigNumber('0')
            : valueToBigNumber(walletData[reserve.underlyingAsset] || '0').dividedBy(
                valueToBigNumber('10').pow(reserve.decimals)
              );
        const walletBalanceInUSD = walletBalance
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
          .toString();

        const walletEthBalance = valueToBigNumber(walletData[API_ETH_MOCK_AAVE_ADDRESS]).dividedBy(
          valueToBigNumber('10').pow(reserve.decimals)
        );
        const walletEthBalanceInUSD = walletEthBalance
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
          .toString();

        return {
          ...reserve,
          walletBalance,
          walletEthBalance,
          walletBalanceInUSD,
          walletEthBalanceInUSD,
          underlyingBalance: userReserve ? userReserve.underlyingBalance : '0',
          underlyingBalanceInUSD: userReserve ? userReserve.underlyingBalanceUSD : '0',
          liquidityRate: reserve.borrowingEnabled ? Number(reserve.liquidityRate) : '-1',
          avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
          borrowingEnabled: reserve.borrowingEnabled,
          interestHistory: [],
          aIncentivesAPY: reserve.aIncentivesAPY,
          vIncentivesAPY: reserve.vIncentivesAPY,
          sIncentivesAPY: reserve.sIncentivesAPY,
          isFreezed: reserve.isFrozen
        };
      });

    if (withFilter) {
      if (sortDesc) {
        return (
          data(collateralReserves)
            .filter((reserve) => Number(reserve.underlyingBalanceInUSD) > 0)
            .sort((a, b) => +b.walletBalanceInUSD - +a.walletBalanceInUSD)
            // @ts-ignore
            .sort((a, b) => a[sortName] - b[sortName])
        );
      } else {
        return (
          data(collateralReserves)
            .filter((reserve) => Number(reserve.underlyingBalanceInUSD) > 0)
            .sort((a, b) => +b.walletBalanceInUSD - +a.walletBalanceInUSD)
            // @ts-ignore
            .sort((a, b) => b[sortName] - a[sortName])
        );
      }
    } else {
      return data(reserves);
    }
  };

  const filteredDepositedData = depositedPositions.filter(
    (item) => !item.usageAsCollateralEnabledOnUser
  );

  return (
    <>
      <div
        className={classNames('MainDashboardTable', {
          MainDashboardTable__onlyOne: isBorrow,
          MainDashboardTable__noBorrows: !borrowedPositions.length,
        })}
      >
        <div className="MainDashboardTable__left-inner">
          {!!filteredDepositedData.length && (
            <>
              <DepositDashboardTable listData={filteredDepositedData} />
              <div style={{ marginBottom: 20 }} />
            </>
          )}
          <div>
            {!!listData2(true).length && (
              <>
                <CollateralDashboradTable listData={listData2(true)} userId={user?.id} />
              </>
            )}
          </div>
        </div>

        <div className="MainDashboardTable__right-inner">
          {!!borrowedPositions.length ? (
            <BorrowDashboardTable listData={borrowedPositions} />
          ) : (
            <ContentWrapper withFullHeight={true}>
              <NoDataPanel
                title={intl.formatMessage(messages.nothingBorrowed)}
                description={intl.formatMessage(messages.nothingBorrowedDescription)}
                buttonTitle={intl.formatMessage(messages.borrowNow)}
                linkTo="/borrow"
              />
            </ContentWrapper>
          )}
        </div>

        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </>
  );
}
