import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber } from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import {
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
  useReserveAPYDataContext,
} from '../../../../libs/pool-data-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
// import toggleLocalStorageClick from '../../../../helpers/toggle-local-storage-click';
import TopPanelWrapper from '../../../../components/wrappers/TopPanelWrapper';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
// import SelectMarketPanel from '../../components/SelectMarketPanel';
// import MarketTable from '../../components/MarketTable';
// import MarketTableItem from '../../components/MarketTableItem';
import TotalMarketsSize from '../../components/TotalMarketsSize';
// import TotalBorrowsSize from '../../components/TotalBorrowsSize';
// import TotalCollateralSize from '../../components/TotalCollateralSize';
import BorrowRatesHelpModal from '../../../../components/HelpModal/BorrowRatesHelpModal';
// import LabeledSwitcher from '../../../../components/basic/LabeledSwitcher';
import MarketMobileCard from '../../components/MarketMobileCard';
import { useIncentivesDataContext } from '../../../../libs/pool-data-provider/hooks/use-incentives-data-context';
import { getDefaultNetworkNameByString } from 'src/config';
// import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
import NoData from '../../../../components/basic/NoData';

import messages from './messages';
import staticStyles from './style';
import MarketCollateralMobileCard from '../../components/MarketCollateralMobileCard';

export default function Markets() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves } = useReserveAPYDataContext();
  const { currentMarketData, networkConfig } = useProtocolDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  // eslint-disable-next-line
  const [isPriceInUSD, setIsPriceInUSD] = useState(
    localStorage.getItem('marketsIsPriceInUSD') === 'true'
  );
  const [sortName, setSortName] = useState('totalLiquidity');
  const [sortDesc, setSortDesc] = useState(false);
  let totalLockedInUsd = valueToBigNumber('0');
  let totalReserveBorrowsInUSD = valueToBigNumber('0');
  let totalCollateralInUsd = valueToBigNumber('0');

  // symbols of markets we want to be included
  const defaultNetwork = getDefaultNetworkNameByString();

  let sortedData = reserves
    .filter((res) => res.isActive && res.borrowingEnabled)
    .map((reserve) => {
      totalLockedInUsd = totalLockedInUsd.plus(
        valueToBigNumber(reserve.totalLiquidity)
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
      );

      const totalLiquidity = Number(reserve.totalLiquidity);
      const totalLiquidityInUSD = valueToBigNumber(reserve.totalLiquidity)
        .multipliedBy(reserve.price.priceInEth)
        .dividedBy(marketRefPriceInUsd)
        .toNumber();

      const totalBorrows = Number(reserve.totalDebt);
      const totalBorrowsInUSD = valueToBigNumber(reserve.totalDebt)
        .multipliedBy(reserve.price.priceInEth)
        .dividedBy(marketRefPriceInUsd)
        .toNumber();
      if (reserve.borrowingEnabled) {
        totalReserveBorrowsInUSD = totalReserveBorrowsInUSD.plus(totalBorrowsInUSD);
      }

      const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];
      // console.log('reserve.yieldAPY', reserve.yieldAPY);
      // console.log('reserve.variableDepositAPY', reserve.variableDepositAPY);
      return {
        totalLiquidity,
        totalLiquidityInUSD,
        totalBorrows: reserve.borrowingEnabled ? totalBorrows : -1,
        totalBorrowsInUSD: reserve.borrowingEnabled ? totalBorrowsInUSD : -1,
        id: reserve.id,
        underlyingAsset: reserve.underlyingAsset,
        currencySymbol: reserve.symbol,
        depositAPY: reserve.borrowingEnabled ? Number(reserve.yieldAPY) : -1,
        avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
        stableBorrowRate:
          reserve.stableBorrowRateEnabled && reserve.borrowingEnabled
            ? Number(reserve.stableBorrowAPY)
            : -1,
        variableBorrowRate: reserve.borrowingEnabled ? Number(reserve.variableBorrowAPY) : -1,
        variableDepositRate: reserve.borrowingEnabled
          ? reserve.variableDepositAPY
            ? Number(reserve.variableDepositAPY)
            : 0
          : -1,
        avg30DaysVariableRate: Number(reserve.avg30DaysVariableBorrowRate),
        borrowingEnabled: reserve.borrowingEnabled,
        stableBorrowRateEnabled: reserve.stableBorrowRateEnabled,
        isFreezed: reserve.isFrozen,
        aincentivesAPR: reserveIncentiveData ? reserveIncentiveData.aIncentives.incentiveAPR : '0',
        vincentivesAPR: reserveIncentiveData ? reserveIncentiveData.vIncentives.incentiveAPR : '0',
        sincentivesAPR: reserveIncentiveData ? reserveIncentiveData.sIncentives.incentiveAPR : '0',
      };
    });
  let sortedCollateralData = reserves
    .filter((res) => res.isActive && !res.borrowingEnabled && res.usageAsCollateralEnabled)
    .map((reserve) => {
      const totalLiquidity = Number(reserve.totalLiquidity);
      const totalLiquidityInUSD = valueToBigNumber(reserve.totalLiquidity)
        .multipliedBy(reserve.price.priceInEth)
        .dividedBy(marketRefPriceInUsd)
        .toNumber();

      return {
        totalLiquidity,
        totalLiquidityInUSD,
        id: reserve.id,
        underlyingAsset: reserve.underlyingAsset,
        currencySymbol: reserve.symbol,
        isFreezed: reserve.isFrozen,
      };
    });
  reserves
    .filter(
      (res) =>
        res.isActive &&
        Object.values(networkConfig.collateralAssets || []).find((item) =>
          item.includes(res.symbol)
        )
    )
    .forEach((reserve) => {
      totalCollateralInUsd = totalCollateralInUsd.plus(
        valueToBigNumber(reserve.totalLiquidity)
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
      );
    });

  if (sortDesc) {
    if (sortName === 'currencySymbol') {
      sortedData.sort((a, b) =>
        b.currencySymbol.toUpperCase() < a.currencySymbol.toUpperCase() ? -1 : 0
      );
    } else {
      // @ts-ignore
      sortedData.sort((a, b) => a[sortName] - b[sortName]);
    }
  } else {
    if (sortName === 'currencySymbol') {
      sortedData.sort((a, b) =>
        a.currencySymbol.toUpperCase() < b.currencySymbol.toUpperCase() ? -1 : 0
      );
    } else {
      // @ts-ignore
      sortedData.sort((a, b) => b[sortName] - a[sortName]);
    }
  }

  // let cards: any = [],
  //   sortedDataFirst: any;
  // if (sortedData.length) {
  //   sortedDataFirst = sortedData[0];
  //   cards = [
  //     {
  //       title: messages.deposit,
  //       value: sortedData[0].depositAPY,
  //       thirtyDaysValue: sortedData[0].avg30DaysLiquidityRate,
  //       liquidityMiningValue: sortedData[0].aincentivesAPR,
  //       enabled: true,
  //       type: 'deposit',
  //     },
  //     {
  //       title: messages.borrow,
  //       subTitle: messages.borrow,
  //       value: sortedData[0].variableBorrowRate,
  //       thirtyDaysValue: sortedData[0].avg30DaysVariableRate,
  //       liquidityMiningValue: sortedData[0].vincentivesAPR,
  //       enabled: sortedData[0].borrowingEnabled,
  //       type: 'borrow-variable',
  //     },
  //   ];
  // }

  return (
    <ScreenWrapper
      pageTitle={intl.formatMessage(messages.pageTitle)}
      className="Markets"
      withMobileGrayBg={true}
    >
      <TopPanelWrapper isCollapse={true} withoutCollapseButton={true}>
        <div className="Markets__top-content">
          <TotalMarketsSize value={totalLockedInUsd.toNumber() + totalCollateralInUsd.toNumber()} />
          {/* <TotalBorrowsSize value={totalReserveBorrowsInUSD.toNumber()} />
          <TotalCollateralSize value={totalCollateralInUsd.toNumber()} /> */}
          {/* <SelectMarketPanel /> */}
        </div>
      </TopPanelWrapper>

      <div className="Markets__size">
        <TotalMarketsSize value={totalLockedInUsd.toNumber() + totalCollateralInUsd.toNumber()} />
        {/* <TotalBorrowsSize value={totalReserveBorrowsInUSD.toNumber()} />
        <TotalCollateralSize value={totalCollateralInUsd.toNumber()} /> */}
      </div>

      {/* <div className="Markets__price-switcher">
        <LabeledSwitcher
          value={!isPriceInUSD}
          leftOption="USD"
          rightOption={intl.formatMessage(messages.native)}
          onToggle={() =>
            toggleLocalStorageClick(isPriceInUSD, setIsPriceInUSD, 'marketsIsPriceInUSD')
          }
        />
      </div> */}

      {/* <div className="Markets__apys">
        {!sortedDataFirst?.isFreezed && (
          <>
            {cards.length > 0 &&
              cards.map((card: any, index: number) => (
                <div className="Markets__apys--card" key={index}>
                  {card.enabled ? (
                    <div className="Markets__apys--card-value">
                      {card.value === Infinity || card.value === 0
                        ? 0
                        : (card.value * 100).toFixed(2)}
                      %
                    </div>
                  ) : (
                    // <LiquidityMiningCard
                    //   symbol={sortedDataFirst.currencySymbol}
                    //   value={card.value}
                    //   thirtyDaysValue={card.thirtyDaysValue}
                    //   liquidityMiningValue={card.liquidityMiningValue}
                    //   mobilePosition="left"
                    //   type={card.type}
                    // />
                    <NoData color="dark" />
                  )}
                  <p className="Markets__apys--card-title">
                    {intl.formatMessage(card.title)}{' '}
                  </p>
                </div>
              ))}
          </>
        )}
      </div> */}

      {/* <div className="Markets__market-switcher">
        <p className="Markets__marketSwitcher--title">
          {intl.formatMessage(messages.selectMarket)}
        </p>
        <SelectMarketPanel />
      </div> */}

      {/* <MarketTable
        sortName={sortName}
        setSortName={setSortName}
        sortDesc={sortDesc}
        setSortDesc={setSortDesc}
      >
        {sortedData.map((item, index) => (
          <MarketTableItem {...item} isPriceInUSD={isPriceInUSD} key={index} />
        ))}
      </MarketTable> */}

      <div className="Markets__mobile--cards">
        {currentMarketData.enabledFeatures?.incentives && (
          <div className="Markets__help--modalInner">
            <BorrowRatesHelpModal // TO-DO: Pass rewardTokenSymbol to this component
              className="Markets__help--modal"
              text={intl.formatMessage(messages.rewardsInformation)}
              iconSize={14}
            />
          </div>
        )}

        {sortedData.map((item, index) => (
          <MarketMobileCard {...item} key={index} />
        ))}
      </div>

      {/* <div className="Markets__collateral--mobile--cards">
        {sortedCollateralData.map((item, index) => (
          <MarketCollateralMobileCard {...item} key={index} />
        ))}
      </div> */}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .Markets {
          &__top-content {
            color: ${currentTheme.white.hex};
          }
          &__marketSwitcher--title {
            color: ${currentTheme.textDarkBlue.hex};
          }
          &__apys {
            &--card {
              background: ${currentTheme.darkBlue.hex};
              color: ${currentTheme.white.hex};
              p,
              .LiquidityMiningCard p {
                color: ${currentTheme.white.hex} !important;
              }
            }
          }

          .Markets__mobile--cards {
            .MobileCardWrapper.MarketMobileCard {
              p.TokenIcon__name {
                color: ${currentTheme.white.hex} !important;
              }
            }
          }

          .Markets__collateral--mobile--cards {
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            .MobileCardWrapper {
              margin-right: 0;
              flex: 1;
            }
            .MobileCardWrapper.MarketMobileCard {
              p.TokenIcon__name {
                color: ${currentTheme.white.hex} !important;
              }
            }
          }
        }
      `}</style>
    </ScreenWrapper>
  );
}
