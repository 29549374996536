import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Range } from 'react-range';

import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import {
  calculateHealthFactorFromBalancesBigUnits,
  ComputedReserveData,
  pow10,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';

import {
  useStaticPoolDataContext,
  useDynamicPoolDataContext,
  useReserveAPYDataContext,
} from '../../libs/pool-data-provider';

import CheckBoxField from '../fields/CheckBoxField';
import SelectField from '../fields/SelectField';
import BorrowAsset from './BorrowAsset';
import AmountFieldWithSelect from '../fields/AmountFieldWithSelect';

import messages from './messages';
import staticStyles from './style';
import BasicField from '../fields/BasicField';
import DeleverageSlippageHelpModal from '../HelpModal/DeleverageSlippageHelpModal';

interface DeleverageOptionAreaProps {
  enabled: boolean;
  onEnableChange: (value: boolean) => void;
  repayAssets: {
    label: string;
    value: string;
    decimals: number;
    debt: string;
  }[];
  repayAsset: string;
  onRepayAssetChange: (symbol: string) => void;
  repayAmount: string;
  onRepayAmountChange: (value: string) => void;
  slippage: string;
  onSlippageChange: (value: string) => void;
  error: string;
}

export default function DeleverageOptionArea({
  enabled,
  onEnableChange,
  repayAssets,
  repayAsset,
  onRepayAssetChange,
  repayAmount,
  onRepayAmountChange,
  slippage,
  onSlippageChange,
  error,
}: DeleverageOptionAreaProps) {
  const intl = useIntl();
  const { currentTheme, isCurrentThemeDark, sm } = useThemeContext();

  const onSetFromAsset = (newFromAsset: string, decimals: number) => {
    onRepayAssetChange(newFromAsset);
  };

  const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) =>
    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();

  const selectedAsset = repayAssets.find((asset) => asset.value === repayAsset);
  const maxRepayAmount = selectedAsset?.debt || '0';

  return (
    <div className="DeleverageOptionArea">
      <CheckBoxField
        // className={classNames('BasicForm__leverage', {
        //   withTopMargin: error !== '',
        // })}
        title={intl.formatMessage(messages.useDeleverage)}
        value={enabled}
        name="deleveage"
        onChange={() => onEnableChange(!enabled)}
      />
      {enabled && (
        <div className="DeleverageOptionArea__inner">
          <div className="DeleverageOptionArea__selector">
            <div className="DeleverageOptionArea__selector-description">
              <h3 style={{ marginBottom: 5 }}>{intl.formatMessage(messages.description)}</h3>
            </div>
            <div>
              <AmountFieldWithSelect
                asset={repayAsset}
                setAsset={onSetFromAsset}
                options={repayAssets}
                selectTitle={intl.formatMessage(messages.selectToken)}
                amount={repayAmount}
                // setMaxSelected={setIsMaxDepositSelected}
                onChangeAmount={onRepayAmountChange}
                maxAmount={maxRepayAmount}
                amountTitle={intl.formatMessage(messages.availableToRepay)}
              />
            </div>
          </div>
          <p className="DeleverageOptionArea__error-text">{error}</p>
        </div>
      )}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .DeleverageOptionArea {
          .CheckBoxField {
            color: ${currentTheme.textDarkBlue.hex};
          }

          &__error-text {
            color: ${currentTheme.red.hex};
          }

          h3 {
            color: ${currentTheme.textDarkBlue.hex};
          }

          &__slippage-wrapper {
            border: 1px solid
              ${isCurrentThemeDark
                ? sm
                  ? currentTheme.white.hex
                  : currentTheme.cream.hex
                : currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}
