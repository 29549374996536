import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BorrowAssetSelector {
    margin-top: 15px;
    &.Row .Row__title {
      margin-bottom: 5px;
    }
    &.Row__column {
      text-align: left;
    }
    &__inner {
      padding: 0 5px;
      .Row__content {
        display: flex !important;
        align-items: center;
        justify-contents: flex-start;

        .SelectField {
          flex: 1;
          margin-right: 10px;

          .Asset {
            padding: 7px 12px;
            display: flex;
          }
        }
        .Approve_button {
          // width: 90px;
          // min-height: 30px;
          // font-size: 12px;
          width: 120px;
          min-height: 40px;
          font-size: 14px;
        }
      }
    }
  }
`;

export default staticStyles;
