import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
// import CustomScroll from '../basic/CustomScroll';

import staticStyles from './style';

interface BasicTableProps {
  children: ReactNode;
  headerColumns?: ReactNode;
  className?: string;
  color?: 'dark' | 'light';
}

export default function BasicTable({ children, headerColumns, className, color }: BasicTableProps) {
  const { currentTheme } = useThemeContext();

  return (
    <div className={classNames('BasicTable', className)}>
      <div className="BasicTable__wrapper">
        {!!headerColumns && <div className="BasicTable__header">{headerColumns}</div>}

        <div className="BasicTable__content">
          {/* {!sm ? (
            <CustomScroll>
              <div className="BasicTable__content-inner">{children}</div>
            </CustomScroll>
          ) : ( */}
          <div className="BasicTable__content-inner">{children}</div>
          {/* )} */}
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true}>{`
        .BasicTable__content-inner {
          padding: ${color === 'light' ? '0px 25px' : '0px'};
        }
        .BasicTable__header {
          padding-top: 10px;
          border-top: solid 1px ${currentTheme.textDarkBlue.hex};
        }
      `}</style>
    </div>
  );
}
