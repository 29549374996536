import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .SelectPreferredToken {
    // position: relative;
    z-index: 5;
    margin-top: -7px;

    position: absolute;
    top: 29px;
    padding-left: 2px;
    @include respond-to(xl) {
      margin-top: -10px;
      top: 32px;
    }
    @include respond-to(lg) {
      top: 30px;
    }
    @include respond-to(md) {
      top: 32px;
    }
    @include respond-to(sm) {
      margin-bottom: 25px;
      top: 32px;
    }

    &__title {
      font-size: $medium;
      margin-bottom: 5px;
      @include respond-to(xl) {
        font-size: $small;
      }
    }

    &__select {
      font-weight: 300;
      font-size: $medium;
      padding: 6px;
      // border-radius: $borderRadius;
      // min-width: 200px;
      // border: 1px solid transparent;
      transition: $transition;
      // box-shadow: $boxShadow;

      width: 80px;
      height: 47px;
      @include respond-to(xl) {
        // min-width: 180px;
        font-size: $small;
        height: 47px;
      }
      @include respond-to(lg) {
        height: 38px;
      }
      @include respond-to(md) {
        height: 47px;
      }
      @include respond-to(sm) {
        padding: 8px;
        // min-width: 260px;
        font-size: $medium;
        height: 47px;
      }
      // span {
      //   text-transform: capitalize;
      // }
    }

    &__select-arrow {
      position: absolute;
      right: 10px;
      top: calc(50% + 1px);
      transform: translateY(-50%);
    }

    &__option {
      width: 100%;
      // min-width: 200px;
      font-size: $medium;
      font-weight: 300;
      // text-transform: capitalize;
      position: relative;
      padding: 12px 5px;
      @include respond-to(xl) {
        // min-width: 180px;
        font-size: $small;
      }
      @include respond-to(sm) {
        // min-width: 260px;
        font-size: $medium;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        opacity: 0.1;
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    &__content {
      top: calc(100% + 2px) !important;
      width: 100%;
      right: 1px !important;
    }
  }
`;

export default staticStyles;
