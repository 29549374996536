import React from 'react';
import classNames from 'classnames';
import { DarkModeSwitcher, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
// import { SocialIcons, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
// import { socialIcons } from 'src/ui-config';

import LangSwitcher from '../basic/LangSwitcher';

import staticStyles from './style';

interface FooterProps {
  inside?: boolean;
}

export default function Footer({ inside }: FooterProps) {
  const { currentTheme } = useThemeContext();
  return (
    <footer className={classNames('Footer', { Footer__inside: inside })}>
      <DarkModeSwitcher />
      <LangSwitcher />

      {/* <SocialIcons
        icons={socialIcons}
        iconHeight={20}
        iconWidth={20}
        linkClassName="MoreButton__socialIcon"
      /> */}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>
        {`
          .DarkModeSwitcher {
            .DarkModeSwitcher__slider {
              background-color: ${currentTheme.textDarkBlue.hex};
            }
          }
        `}
      </style>
    </footer>
  );
}
