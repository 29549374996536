import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import staticStyles from './style';

interface TableColumnProps {
  children: ReactNode;
  className?: string;
  maxWidth?: number;
  minWidth?: number;
}

export default function TableColumn({ children, className, maxWidth, minWidth }: TableColumnProps) {
  const { currentTheme } = useThemeContext();
  return (
    <div className={classNames('TableColumn', className)} style={{ maxWidth, minWidth }}>
      {children}

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true} global={true}>{`
        .TableColumn {
          .Value {
            &__dark {
              .Value__value {
                color: ${currentTheme.cream.hex} !important;
              }
            }
          }
        }
      `}</style>
    </div>
  );
}
