import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Range } from 'react-range';

import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import {
  calculateHealthFactorFromBalancesBigUnits,
  ComputedReserveData,
  valueToBigNumber,
  normalize,
  getGasPrice,
} from '@sturdyfi/sturdy-js';

import {
  useStaticPoolDataContext,
  useDynamicPoolDataContext,
  useReserveAPYDataContext,
} from '../../libs/pool-data-provider';

import ValuePercent from '../basic/ValuePercent';
import SelectField from '../fields/SelectField';
import CheckBoxField from '../fields/CheckBoxField';
import BorrowAsset from './BorrowAsset';
import { isAssetStable } from '../../helpers/markets/assets';

import messages from './messages';
import staticStyles from './style';
import { getProvider } from '../../helpers/markets/markets-data';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

interface LeverageOptionAreaProps {
  enabled: boolean;
  onEnableChange: (value: boolean) => void;
  depositAmount: number;
  leverageCount: number;
  onLeverageCountChange: (amount: string) => void;
  maxLeverageCount: string;
  levBorrowAssetSymbol: string;
  onLevBorrowAssetChange: (symbol: string) => void;
  collateralReserve: ComputedReserveData;
  error: string;
}

export default function LeverageOptionArea({
  enabled,
  onEnableChange,
  depositAmount,
  leverageCount,
  maxLeverageCount,
  onLeverageCountChange,
  levBorrowAssetSymbol,
  onLevBorrowAssetChange,
  collateralReserve,
  error,
}: LeverageOptionAreaProps) {
  const intl = useIntl();
  const { currentTheme, isCurrentThemeDark } = useThemeContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { currentMarketData, network: currentNetwork } = useProtocolDataContext();
  const { user } = useDynamicPoolDataContext();
  const { reserves } = useReserveAPYDataContext();
  const [borrowAssetSelector, setBorrowAssetSelector] = useState(false);
  const [leverageUpFactor, setLeverageUpFactor] = useState(0);
  const [borrowAssetSymbols, setBorrowAssetSymbols] = useState<string[]>([]);
  // const [gasCost, setGasCost] = useState('0');
  const [gasPrice, setGasPrice] = useState('0');

  const FLASHLOAN_LEVERAGE_GAS = '2760000';

  const {
    price: { priceInEth: collateralETHPrice },
    baseLTVasCollateral: ltv,
    reserveLiquidationThreshold: liquidationThreshold,
    aprDetails: { totalApr },
  } = collateralReserve;

  const amountToDepositInETH = valueToBigNumber(depositAmount)
    .multipliedBy(collateralETHPrice || '0')
    .multipliedBy(leverageUpFactor);

  const amountToDepositInUSD =
    valueToBigNumber(amountToDepositInETH).dividedBy(marketRefPriceInUsd);

  const amountToBorrowInETH = valueToBigNumber(depositAmount)
        .multipliedBy(collateralETHPrice || '0')
        .multipliedBy(leverageUpFactor - 1)
        .multipliedBy(1 + (currentMarketData?.flashloanSlippage || 0))

  const amountToBorrowInUSD = valueToBigNumber(amountToBorrowInETH).dividedBy(marketRefPriceInUsd);

  const leveragedAPY = valueToBigNumber(totalApr || '0')
    .multipliedBy(100)
    .multipliedBy(leverageUpFactor)
    .toFixed(2);

  useEffect(() => {
    const config = { network: currentNetwork, provider: getProvider(currentNetwork) };
    let mounted = true;
    (async () => {
      const value = (await getGasPrice(config)).toString();
      if (mounted) {
        // only try to update if we are subscribed (or mounted)
        setGasPrice(value);
      }
    })();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setLeverageUpFactor(leverageCount);
  }, [leverageCount]);

  useEffect(() => {
    const OPTIMAL_UTILIZATION_RATE = 0.8;
    const EXCESS_UTILIZATION_RATE = 1 - OPTIMAL_UTILIZATION_RATE;
    const borrowAssets = reserves.filter(
      ({
        symbol,
        borrowingEnabled,
        isActive,
        availableLiquidity,
        totalLiquidity,
        price: { priceInEth: assetETHPrice },
      }) =>
        borrowingEnabled &&
        isActive &&
        isAssetStable(symbol) &&
        valueToBigNumber(availableLiquidity)
          .minus(valueToBigNumber(amountToBorrowInETH).dividedBy(assetETHPrice))
          .gte(valueToBigNumber(totalLiquidity).multipliedBy(EXCESS_UTILIZATION_RATE))
    );
    setBorrowAssetSymbols(borrowAssets.map((asset) => asset.symbol));
  }, [leverageUpFactor, depositAmount]);

  useEffect(() => {
    if (levBorrowAssetSymbol && !borrowAssetSymbols.includes(levBorrowAssetSymbol)) {
      onLevBorrowAssetChange('');
    }
  }, [borrowAssetSymbols]);

  if (!user) {
    return null;
  }

  const handleChange = (value: number[]) => {
    onLeverageCountChange(value[0].toString());
  };

  const handleBorrowAssetChange = (symbol: string) => {
    onLevBorrowAssetChange(symbol);
    setBorrowAssetSelector(false);
  };

  const totalCollateralETHAfter = valueToBigNumber(user.totalCollateralETH).plus(
    amountToDepositInETH
  );
  const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralETH)
    .multipliedBy(user.currentLiquidationThreshold)
    .plus(amountToDepositInETH.multipliedBy(liquidationThreshold))
    .dividedBy(totalCollateralETHAfter);

  const liquidationPrice =
    depositAmount === 0
      ? 0
      : valueToBigNumber(amountToBorrowInUSD)
          .dividedBy(liquidationThresholdAfter || '1')
          .dividedBy(valueToBigNumber(depositAmount).multipliedBy(leverageUpFactor))
          .toFixed(4);

  const newHealthFactor = calculateHealthFactorFromBalancesBigUnits(
    totalCollateralETHAfter,
    valueToBigNumber(user.totalBorrowsETH).plus(amountToBorrowInETH),
    liquidationThresholdAfter
  );

  const calcGasInUsd = (gasCost: string) => {
    const totalGas = normalize(valueToBigNumber(gasCost).times(gasPrice), 18);
    return valueToBigNumber(totalGas).div(marketRefPriceInUsd).toFixed(2);
  };

  const calcGasCostOffset = (value: number) => {
    let newValue = 1;
    let iterations = 9;
    for (let i = 1; i < 10; i++) {
      newValue += Math.pow(Number(ltv), i);
      if (value <= newValue) {
        iterations = i - 1;
        break;
      }
    }
    const gasCostOffset = valueToBigNumber('200000')
      .plus(valueToBigNumber('1051000').multipliedBy(iterations))
      .toString();

    return calcGasInUsd(gasCostOffset);
  };

  const gasCost = calcGasInUsd(FLASHLOAN_LEVERAGE_GAS)

  const flashloanFee = 0;

  return (
    <div className="LeverageOptionArea">
      <CheckBoxField
        // className={classNames('BasicForm__leverage', {
        //   withTopMargin: error !== '',
        // })}
        title={intl.formatMessage(messages.useLeverage)}
        value={enabled}
        name="deleveage"
        onChange={() => onEnableChange(!enabled)}
      />
      {enabled && (
        <div className="LeverageOptionArea__inner">
          <p className="LeverageOptionArea__error-text">{error}</p>
          <div className="LeverageOptionArea__title">
            <h3>
              {intl.formatMessage(messages.leverageUp)} ({leverageUpFactor.toFixed(2)}x)
            </h3>
          </div>
          <div className="LeverageOptionArea__info">
            <div className="LeverageOptionArea__info-inner">
              <p>{intl.formatMessage(messages.collateralDeposit)}</p>
              <span>${amountToDepositInUSD.toFixed(2)}</span>
            </div>
            <div className="LeverageOptionArea__info-inner">
              <p>{intl.formatMessage(messages.liquidationPrice)}</p>
              <span>${liquidationPrice}</span>
            </div>
            <div className="LeverageOptionArea__info-inner">
              <p>{intl.formatMessage(messages.leveragedAPY)}</p>
              <span>{leveragedAPY}%</span>
            </div>
            <div className="LeverageOptionArea__info-inner">
              <p>{intl.formatMessage(messages.gasEstimation)}</p>
              <span>${gasCost}</span>
            </div>
          </div>
          <div className="LeverageOptionArea__range">
            <div className="LeverageOptionArea__range-top-info">
              <span className="LeverageOptionArea__title">
                {intl.formatMessage(messages.safer)}
              </span>
              {Number(newHealthFactor) > 0 && (
                <div className="LeverageOptionArea__newHF">
                  <p>{intl.formatMessage(messages.newHF)}</p>
                  <ValuePercent value={newHealthFactor} color="dark" percentSymbol={false} />
                </div>
              )}
              <span className="LeverageOptionArea__title">
                {intl.formatMessage(messages.riskier)}
              </span>
            </div>
            <div className="LeverageOptionArea__range-inner">
              <Range
                step={0.05}
                min={1}
                max={Number(maxLeverageCount)}
                values={[leverageCount]}
                onChange={(values) => handleChange(values)}
                renderTrack={({ props, children }) => (
                  <div
                    className="LeverageOptionArea__track"
                    {...props}
                    style={{
                      ...props.style,
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    className="LeverageOptionArea__thumb"
                    {...props}
                    style={{
                      ...props.style,
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="LeverageOptionArea__selector">
            <div className="LeverageOptionArea__selector-description">
              <h3 style={{ marginBottom: 5 }}>{intl.formatMessage(messages.description)}</h3>
            </div>
            <div className="LeverageOptionArea__selector-selection">
              <SelectField
                visible={borrowAssetSelector}
                setVisible={setBorrowAssetSelector}
                value={
                  levBorrowAssetSymbol ? (
                    <BorrowAsset
                      symbol={levBorrowAssetSymbol}
                      fullName={levBorrowAssetSymbol}
                      onWhiteBackground={isCurrentThemeDark === false}
                    />
                  ) : (
                    <p>Select token</p>
                  )
                }
              >
                {borrowAssetSymbols.map((asset) => (
                  <BorrowAsset
                    key={asset}
                    symbol={asset}
                    fullName={asset}
                    className="LeverageOptionArea__select-option"
                    onSelect={handleBorrowAssetChange}
                    onWhiteBackground={true}
                  />
                ))}
              </SelectField>
            </div>
          </div>
        </div>
      )}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .LeverageOptionArea {
          &__title {
            color: ${currentTheme.textDarkBlue.hex};
            &:last-of-type {
              color: ${currentTheme.red.hex};
            }
          }

          &__info {
            color: ${currentTheme.textDarkBlue.hex};
          }

          &__error-text {
            color: ${currentTheme.red.hex};
          }

          &__newHF {
            color: ${currentTheme.textDarkBlue.hex};
          }

          &__thumb {
            background: ${currentTheme.white.hex};
          }
          &__selector-description {
            color: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}
