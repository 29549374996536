import React, { PropsWithChildren, useContext } from 'react';
import {
  LendingPoolInterfaceV2,
  TxBuilderV2,
  TxBuilderConfig,
  LendingPoolConfig,
  VaultConfig,
  IncentivesControllerInterface,
  FaucetInterface,
  LeverageSwapConfig,
} from '@sturdyfi/sturdy-js';

import { useProtocolDataContext } from '../protocol-data-provider';
import { getProvider } from '../../helpers/markets/markets-data';
import { marketsData } from '../../ui-config';
import GeneralVaultInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v2/GeneralVault';
import { StableYieldDistributionInterface } from '@sturdyfi/sturdy-js/dist/tx-builder/services/StableYieldDistribution';
import { VariableYieldDistributionInterface } from '@sturdyfi/sturdy-js/dist/tx-builder/services/VariableYieldDistribution';
import GeneralLevSwapInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v2/GeneralLevSwap';

export interface TxBuilderContextInterface {
  lendingPool: LendingPoolInterfaceV2;
  lidoVault: GeneralVaultInterface;
  convexFRAX3CRVVault: GeneralVaultInterface;
  convexDAIUSDCUSDTSUSDVault: GeneralVaultInterface;
  convexIronBankVault: GeneralVaultInterface;
  convexFRAXUSDCVault: GeneralVaultInterface;
  convexMIM3CRVVault: GeneralVaultInterface;
  convexTUSDFRAXBPVault: GeneralVaultInterface;
  // leverage
  convexFRAX3CRVLevSwap: GeneralLevSwapInterface;
  convexDAIUSDCUSDTSUSDLevSwap: GeneralLevSwapInterface;
  convexFRAXUSDCLevSwap: GeneralLevSwapInterface;
  convexIRONBANKLevSwap: GeneralLevSwapInterface;
  convexMIM3CRVLevSwap: GeneralLevSwapInterface;
  convexTUSDFRAXBPLevSwap: GeneralLevSwapInterface;

  yearnVault: GeneralVaultInterface;
  yearnWETHVault: GeneralVaultInterface;
  yearnWBTCVault: GeneralVaultInterface;
  yearnBOOVault: GeneralVaultInterface;
  yearnFBeetsVault: GeneralVaultInterface;
  yearnLINKVault: GeneralVaultInterface;
  yearnSPELLVault: GeneralVaultInterface;
  yearnCRVVault: GeneralVaultInterface;
  tombFtmBeefyVault: GeneralVaultInterface;
  tombMiMaticBeefyVault: GeneralVaultInterface;
  basedMiMaticBeefyVault: GeneralVaultInterface;
  incentiveController: IncentivesControllerInterface;
  stableYieldDistribution: StableYieldDistributionInterface;
  variableYieldDistribution: VariableYieldDistributionInterface;
  faucetService: FaucetInterface;
}

const TxBuilderContext = React.createContext({} as TxBuilderContextInterface);

const marketConfig = Object.entries(marketsData).reduce<
  TxBuilderConfig & {
    lendingPool: LendingPoolConfig;
    lidoVault: { [network: string]: VaultConfig };
    convexFRAX3CRVVault: { [network: string]: VaultConfig };
    convexDAIUSDCUSDTSUSDVault: { [network: string]: VaultConfig };
    convexIronBankVault: { [network: string]: VaultConfig };
    convexFRAXUSDCVault: { [network: string]: VaultConfig };
    convexMIM3CRVVault: { [network: string]: VaultConfig };
    convexTUSDFRAXBPVault: { [network: string]: VaultConfig };
    yearnVault: { [network: string]: VaultConfig };
    yearnWETHVault: { [network: string]: VaultConfig };
    yearnWBTCVault: { [network: string]: VaultConfig };
    yearnBOOVault: { [network: string]: VaultConfig };
    yearnFBeetsVault: { [network: string]: VaultConfig };
    yearnLINKVault: { [network: string]: VaultConfig };
    yearnSPELLVault: { [network: string]: VaultConfig };
    yearnCRVVault: { [network: string]: VaultConfig };
    tombFtmBeefyVault: { [network: string]: VaultConfig };
    tombMiMaticBeefyVault: { [network: string]: VaultConfig };
    basedMiMaticBeefyVault: { [network: string]: VaultConfig };
    convexFRAX3CRVLevSwap: { [network: string]: LeverageSwapConfig };
    convexDAIUSDCUSDTSUSDLevSwap: { [network: string]: LeverageSwapConfig };
    convexFRAXUSDCLevSwap: { [network: string]: LeverageSwapConfig };
    convexIRONBANKLevSwap: { [network: string]: LeverageSwapConfig };
    convexMIM3CRVLevSwap: { [network: string]: LeverageSwapConfig };
    convexTUSDFRAXBPLevSwap: { [network: string]: LeverageSwapConfig };
  }
>(
  (acc, [key, value]) => {
    if (!acc.lendingPool?.[value.network]) {
      acc.lendingPool[value.network] = {};
    }
    if (!acc.lidoVault?.[value.network]) {
      acc.lidoVault[value.network] = { VAULT: '' };
    }
    if (!acc.yearnVault?.[value.network]) {
      acc.yearnVault[value.network] = { VAULT: '' };
    }
    acc.lendingPool[value.network][key] = {
      LENDING_POOL: value.addresses.LENDING_POOL,
      FAUCET: value.addresses.FAUCET,
    };
    if (value.addresses.LIDO_VAULT) {
      acc.lidoVault[value.network] = {
        VAULT: value.addresses.LIDO_VAULT as string,
      };
    }
    if (value.addresses.CONVEX_FRAX_3CRV_VAULT) {
      acc.convexFRAX3CRVVault[value.network] = {
        VAULT: value.addresses.CONVEX_FRAX_3CRV_VAULT as string,
      };
    }
    if (value.addresses.CONVEX_DAI_USDC_USDT_SUSD_VAULT) {
      acc.convexDAIUSDCUSDTSUSDVault[value.network] = {
        VAULT: value.addresses.CONVEX_DAI_USDC_USDT_SUSD_VAULT as string,
      };
    }
    if (value.addresses.CONVEX_IRON_BANK_VAULT) {
      acc.convexIronBankVault[value.network] = {
        VAULT: value.addresses.CONVEX_IRON_BANK_VAULT as string,
      };
    }
    if (value.addresses.CONVEX_FRAX_USDC_VAULT) {
      acc.convexFRAXUSDCVault[value.network] = {
        VAULT: value.addresses.CONVEX_FRAX_USDC_VAULT as string,
      };
    }
    if (value.addresses.CONVEX_MIM_3CRV_VAULT) {
      acc.convexMIM3CRVVault[value.network] = {
        VAULT: value.addresses.CONVEX_MIM_3CRV_VAULT as string,
      };
    }
    if (value.addresses.CONVEX_TUSD_FRAXBP_VAULT) {
      acc.convexTUSDFRAXBPVault[value.network] = {
        VAULT: value.addresses.CONVEX_TUSD_FRAXBP_VAULT as string,
      };
    }
    if (value.addresses.YEARN_VAULT) {
      acc.yearnVault[value.network] = {
        VAULT: value.addresses.YEARN_VAULT as string,
      };
    }
    if (value.addresses.YEARN_WETH_VAULT) {
      acc.yearnWETHVault[value.network] = {
        VAULT: value.addresses.YEARN_WETH_VAULT as string,
      };
    }
    if (value.addresses.YEARN_WBTC_VAULT) {
      acc.yearnWBTCVault[value.network] = {
        VAULT: value.addresses.YEARN_WBTC_VAULT as string,
      };
    }
    if (value.addresses.YEARN_BOO_VAULT) {
      acc.yearnBOOVault[value.network] = {
        VAULT: value.addresses.YEARN_BOO_VAULT as string,
      };
    }
    if (value.addresses.YEARN_FBEETS_VAULT) {
      acc.yearnFBeetsVault[value.network] = {
        VAULT: value.addresses.YEARN_FBEETS_VAULT as string,
      };
    }
    if (value.addresses.YEARN_LINK_VAULT) {
      acc.yearnLINKVault[value.network] = {
        VAULT: value.addresses.YEARN_LINK_VAULT as string,
      };
    }
    if (value.addresses.YEARN_SPELL_VAULT) {
      acc.yearnSPELLVault[value.network] = {
        VAULT: value.addresses.YEARN_SPELL_VAULT as string,
      };
    }
    if (value.addresses.YEARN_CRV_VAULT) {
      acc.yearnCRVVault[value.network] = {
        VAULT: value.addresses.YEARN_CRV_VAULT as string,
      };
    }
    if (value.addresses.TOMB_FTM_BEEFY_VAULT) {
      acc.tombFtmBeefyVault[value.network] = {
        VAULT: value.addresses.TOMB_FTM_BEEFY_VAULT as string,
      };
    }
    if (value.addresses.TOMB_MIMATIC_BEEFY_VAULT) {
      acc.tombMiMaticBeefyVault[value.network] = {
        VAULT: value.addresses.TOMB_MIMATIC_BEEFY_VAULT as string,
      };
    }
    if (value.addresses.BASED_MIMATIC_BEEFY_VAULT) {
      acc.basedMiMaticBeefyVault[value.network] = {
        VAULT: value.addresses.BASED_MIMATIC_BEEFY_VAULT as string,
      };
    }
    if (value.addresses.CONVEX_FRAX_3CRV_LEVSWAPPER) {
      acc.convexFRAX3CRVLevSwap[value.network] = {
        LEVSWAPPER: value.addresses.CONVEX_FRAX_3CRV_LEVSWAPPER as string,
      };
    }
    if (value.addresses.CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER) {
      acc.convexDAIUSDCUSDTSUSDLevSwap[value.network] = {
        LEVSWAPPER: value.addresses.CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER as string,
      };
    }
    if (value.addresses.CONVEX_FRAX_USDC_LEVSWAPPER) {
      acc.convexFRAXUSDCLevSwap[value.network] = {
        LEVSWAPPER: value.addresses.CONVEX_FRAX_USDC_LEVSWAPPER as string,
      };
    }
    if (value.addresses.CONVEX_IRON_BANK_LEVSWAPPER) {
      acc.convexIRONBANKLevSwap[value.network] = {
        LEVSWAPPER: value.addresses.CONVEX_IRON_BANK_LEVSWAPPER as string,
      };
    }
    if (value.addresses.CONVEX_MIM_3CRV_LEVSWAPPER) {
      acc.convexMIM3CRVLevSwap[value.network] = {
        LEVSWAPPER: value.addresses.CONVEX_MIM_3CRV_LEVSWAPPER as string,
      };
    }
    if (value.addresses.CONVEX_TUSD_FRAXBP_LEVSWAPPER) {
      acc.convexTUSDFRAXBPLevSwap[value.network] = {
        LEVSWAPPER: value.addresses.CONVEX_TUSD_FRAXBP_LEVSWAPPER as string,
      };
    }
    return acc;
  },
  {
    lendingPool: {},
    lidoVault: {},
    convexFRAX3CRVVault: {},
    convexDAIUSDCUSDTSUSDVault: {},
    convexIronBankVault: {},
    convexFRAXUSDCVault: {},
    convexMIM3CRVVault: {},
    convexTUSDFRAXBPVault: {},
    yearnVault: {},
    yearnWETHVault: {},
    yearnWBTCVault: {},
    yearnBOOVault: {},
    yearnFBeetsVault: {},
    yearnLINKVault: {},
    yearnSPELLVault: {},
    yearnCRVVault: {},
    tombFtmBeefyVault: {},
    tombMiMaticBeefyVault: {},
    basedMiMaticBeefyVault: {},
    convexFRAX3CRVLevSwap: {},
    convexDAIUSDCUSDTSUSDLevSwap: {},
    convexFRAXUSDCLevSwap: {},
    convexIRONBANKLevSwap: {},
    convexMIM3CRVLevSwap: {},
    convexTUSDFRAXBPLevSwap: {},
  }
);

export function TxBuilderProvider({ children }: PropsWithChildren<{}>) {
  const { currentMarket, network: currentNetwork } = useProtocolDataContext();

  // txBuilder used for lending pool
  const txBuilder = new TxBuilderV2(
    currentNetwork,
    getProvider(currentNetwork),
    undefined,
    marketConfig
  );
  const lendingPool = txBuilder.getLendingPool(currentMarket);
  const lidoVault = txBuilder.getLidoVault();
  const convexFRAX3CRVVault = txBuilder.getConvexFRAX3CRVVault();
  const convexDAIUSDCUSDTSUSDVault = txBuilder.getConvexDAIUSDCUSDTSUSDVault();
  const convexIronBankVault = txBuilder.getConvexIronBankVault();
  const convexFRAXUSDCVault = txBuilder.getConvexFRAXUSDCVault();
  const convexMIM3CRVVault = txBuilder.getConvexMIM3CRVVault();
  const convexTUSDFRAXBPVault = txBuilder.getConvexTUSDFRAXBPVault();
  const yearnVault = txBuilder.getYearnVault();
  const yearnWETHVault = txBuilder.getYearnWETHVault();
  const yearnWBTCVault = txBuilder.getYearnWBTCVault();
  const yearnBOOVault = txBuilder.getYearnBOOVault();
  const yearnFBeetsVault = txBuilder.getYearnFBeetsVault();
  const yearnLINKVault = txBuilder.getYearnLINKVault();
  const yearnSPELLVault = txBuilder.getYearnSPELLVault();
  const yearnCRVVault = txBuilder.getYearnCRVVault();
  const tombFtmBeefyVault = txBuilder.getTombFtmBeefyVault();
  const tombMiMaticBeefyVault = txBuilder.getTombMiMaticBeefyVault();
  const basedMiMaticBeefyVault = txBuilder.getBasedMiMaticBeefyVault();
  const incentiveController = txBuilder.incentiveService;
  const stableYieldDistribution = txBuilder.stableYieldDistribution;
  const variableYieldDistribution = txBuilder.variableYieldDistribution;
  const faucetService = txBuilder.getFaucet(currentMarket);
  const convexFRAX3CRVLevSwap = txBuilder.getConvexFRAX3CRVLevSwap();
  const convexDAIUSDCUSDTSUSDLevSwap = txBuilder.getConvexDAIUSDCUSDTSUSDLevSwap();
  const convexFRAXUSDCLevSwap = txBuilder.getConvexFRAXUSDCLevSwap();
  const convexIRONBANKLevSwap = txBuilder.getConvexIRONBANKLevSwap();
  const convexMIM3CRVLevSwap = txBuilder.getConvexMIM3CRVLevSwap();
  const convexTUSDFRAXBPLevSwap = txBuilder.getConvexTUSDFRAXBPLevSwap();
  return (
    <TxBuilderContext.Provider
      value={{
        lendingPool,
        lidoVault,
        convexFRAX3CRVVault,
        convexDAIUSDCUSDTSUSDVault,
        convexIronBankVault,
        convexFRAXUSDCVault,
        convexMIM3CRVVault,
        convexTUSDFRAXBPVault,
        convexFRAX3CRVLevSwap,
        convexDAIUSDCUSDTSUSDLevSwap,
        convexFRAXUSDCLevSwap,
        convexIRONBANKLevSwap,
        convexMIM3CRVLevSwap,
        convexTUSDFRAXBPLevSwap,
        yearnVault,
        yearnWETHVault,
        yearnWBTCVault,
        yearnBOOVault,
        yearnFBeetsVault,
        yearnLINKVault,
        yearnSPELLVault,
        yearnCRVVault,
        tombFtmBeefyVault,
        tombMiMaticBeefyVault,
        basedMiMaticBeefyVault,
        incentiveController,
        stableYieldDistribution,
        variableYieldDistribution,
        faucetService,
      }}
    >
      {children}
    </TxBuilderContext.Provider>
  );
}

export const useTxBuilderContext = () => useContext(TxBuilderContext);
