import React from 'react';
import { useIntl } from 'react-intl';

import TableItem from '../../../dashboard/components/DashboardTable/TableItem';
import TableCol from '../../../dashboard/components/DashboardTable/TableCol';
import TableValueCol from '../../../dashboard/components/DashboardTable/TableValueCol';
import TableButtonsWrapper from '../../../dashboard/components/DashboardTable/TableButtonsWrapper';
import TableButtonCol from '../../../dashboard/components/DashboardTable/TableButtonCol';
import NoData from 'src/components/basic/NoData';
import { getAssetColor } from 'src/helpers/markets/assets';

import defaultMessages from '../../../../defaultMessages';
import messages from './messages';

import { CollateralTableItem } from './types';
import { isAssetStable } from 'src/helpers/markets/assets';

export default function CollateralItem({
  id,
  symbol,
  userId,
  isFreezed,
  underlyingBalance,
  underlyingBalanceInUSD,
}: CollateralTableItem) {
  const intl = useIntl();
  const hasDeposit = underlyingBalance.toString() > '0';

  const uiColor = getAssetColor(symbol);

  return (
    <TableItem tokenSymbol={symbol} color={uiColor}>
      <TableValueCol
        value={Number(underlyingBalance)}
        subValue={Number(underlyingBalanceInUSD)}
        tooltipId={`collateral-${symbol}-${id}`}
        isStable={isAssetStable(symbol)}
      />
      <TableCol>
        <NoData />
      </TableCol>
      <TableButtonsWrapper>
        <TableButtonCol
          disabled={isFreezed}
          title={intl.formatMessage(messages.provide)}
          linkTo={`/provide/${symbol}-${id}`}
        />
        <TableButtonCol
          disabled={!hasDeposit}
          title={intl.formatMessage(defaultMessages.withdraw)}
          linkTo={`/withdraw-collateral/${symbol}-${id}`}
        />
      </TableButtonsWrapper>
    </TableItem>
  );
}
