import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useLanguageContext } from 'src/modules/../libs/language-provider';
import DashboardTable from 'src/modules/dashboard/components/DashboardTable';
import TableHeader from 'src/modules/dashboard/components/DashboardTable/TableHeader';
import DashboardMobileCardsWrapper from 'src/modules/dashboard/components/DashboardMobileCardsWrapper';
import CollateralItem from './CollateralItem';
import CollateralMobileCard from './CollateralMobileCard';
import { CollateralTableItem } from './types';

import messages from './messages';

interface CollateralDashboardTableProps {
  listData: CollateralTableItem[];
  userId?: string;
  // collatData: BorrowTableItem[];
}

export default function CollateralDashboardTable({
  listData,
  userId,
}: CollateralDashboardTableProps) {
  const intl = useIntl();
  const { currentLangSlug } = useLanguageContext();
  const { lg, sm } = useThemeContext();

  const head = [
    intl.formatMessage(messages.yourCollateral),
    intl.formatMessage(messages.secondTableColumnTitle),
    '',
    '',
    // intl.formatMessage(messages.fourthTableColumnTitle),
  ];
  const colWidth = [lg ? 250 : 160, '100%', '100%'];

  const Header = useCallback(() => {
    return <TableHeader head={head} colWidth={colWidth} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLangSlug]);

  return (
    <>
      {!sm ? (
        <>
          <Header />

          <DashboardTable>
            {listData.map((item, index) => (
              <CollateralItem userId={userId} {...item} key={index} />
            ))}
          </DashboardTable>
        </>
      ) : (
        <DashboardMobileCardsWrapper>
          {listData.map((item, index) => (
            <CollateralMobileCard {...item} key={index} />
          ))}
        </DashboardMobileCardsWrapper>
      )}
    </>
  );
}
