import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber, normalize } from '@sturdyfi/sturdy-js';
import { getAssetInfo, rgba, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useLanguageContext } from '../../../../libs/language-provider';
// import { useReservesRateHistoryHelper } from '../../../../helpers/use-reserve-rates-history';
import Row from '../../../basic/Row';
import ValuePercent from '../../../basic/ValuePercent';
import Value from '../../../basic/Value';
// import Link from '../../../basic/Link';
import GradientLine from '../../../basic/GradientLine';
import MaxLTVHelpModal from '../../../HelpModal/MaxLTVHelpModal';
import LiquidationThresholdHelpModal from '../../../HelpModal/LiquidationThresholdHelpModal';
import LiquidationBonusHelpModal from '../../../HelpModal/LiquidationBonusHelpModal';
import SupplyCapHelpModal from '../../../HelpModal/SupplyCapHelpModal';
// import GraphFilterButtons from '../../../graphs/GraphFilterButtons';
import { ValidationWrapperComponentProps } from '../../../RouteParamsValidationWrapper';
import { InterestRateSeries } from '../../../graphs/types';
import { GraphLegendDot } from '../../../graphs/GraphLegend';
import GraphInner from '../GraphInner';
import { TokenIcon } from '../../../../helpers/markets/assets';
import { networkConfigs } from 'src/ui-config';
import { getCurrentNetworkName } from 'src/config';

import messages from './messages';
import staticStyles from './style';

interface CollateralOverviewProps
  extends Pick<ValidationWrapperComponentProps, 'poolReserve' | 'currencySymbol'> {
  title?: string;
  type: 'deposit' | 'borrow';
  showGraphCondition: boolean;
  dots?: GraphLegendDot[];
  series: InterestRateSeries[];
  isCollapse?: boolean;
}

export default function CollateralOverview({
  title,
  poolReserve,
  currencySymbol,
  type,
  showGraphCondition,
  dots,
  series,
  isCollapse,
}: CollateralOverviewProps) {
  const intl = useIntl();
  const { currentTheme, sm } = useThemeContext();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { currentLangSlug } = useLanguageContext();
  const asset = getAssetInfo(currencySymbol);
  const collateralAssetSymbol = asset.name;

  // const collateralAssetSymbol =
  //   Object.entries(networkConfigs[getCurrentNetworkName()].collateralAssets || {}).find(
  //     (item: [string, ReadonlyArray<string>]) => {
  //       return item[1].includes(currencySymbol);
  //     }
  //   )?.[0] || currencySymbol;

  // const { mode, setMode } = useReservesRateHistoryHelper({
  //   poolReserveId: poolReserve.id,
  // }); TODO: uncomment when filters are added to history graphs

  const overviewData = {
    priceInUsd: valueToBigNumber(poolReserve.price.priceInEth)
      .dividedBy(marketRefPriceInUsd)
      .toNumber(),
    avg30DaysLiquidityRate: Number(poolReserve.avg30DaysLiquidityRate),
    stableRate: Number(poolReserve.stableBorrowRate),
    variableRate: Number(poolReserve.variableBorrowRate),
    avg30DaysVariableRate: Number(poolReserve.avg30DaysVariableBorrowRate),
    usageAsCollateralEnabled: poolReserve.usageAsCollateralEnabled,
    stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
    baseLTVasCollateral: Number(poolReserve.baseLTVasCollateral),
    liquidationThreshold: Number(poolReserve.reserveLiquidationThreshold),
    liquidationBonus: Number(poolReserve.reserveLiquidationBonus),
    borrowingEnabled: poolReserve.borrowingEnabled,
    totalLiquidity: poolReserve.totalLiquidity,
    capacity: Number(poolReserve.capacity),
  };

  const poolCapacity = Number(poolReserve.capacity);
  const capacity = normalize(poolCapacity, poolReserve.decimals);

  const graphBorder = rgba(`${currentTheme.white.rgb}, 0.5`);

  const isBorrow = type === 'borrow';

  // const LeftInformation = useCallback(() => {
  //   return <></>;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   isCollapse,
  //   //overviewData.availableLiquidity,
  //   overviewData.avg30DaysLiquidityRate,
  //   overviewData.borrowingEnabled,
  //   //overviewData.depositApy,
  //   overviewData.priceInUsd,
  //   overviewData.usageAsCollateralEnabled,
  //   //overviewData.utilizationRate,
  //   currentLangSlug,
  // ]);

  const RightInformation = useCallback(() => {
    return (
      <>
        <>
          <Row
            className="CurrencyOverview__row"
            title={
              <MaxLTVHelpModal
                text={intl.formatMessage(messages.maximumLTV)}
                color="white"
                lightWeight={true}
              />
            }
            color="white"
            weight="light"
            isColumn={isCollapse}
          >
            {overviewData.baseLTVasCollateral === 0 ? (
              <span className="CurrencyOverview__no-data">—</span>
            ) : (
              <ValuePercent value={overviewData.baseLTVasCollateral} color="white" />
            )}
          </Row>

          <Row
            className="CurrencyOverview__row"
            title={
              <LiquidationThresholdHelpModal
                text={intl.formatMessage(messages.liquidationThreshold)}
                color="white"
                lightWeight={true}
              />
            }
            color="white"
            weight="light"
            isColumn={isCollapse}
          >
            {overviewData.liquidationThreshold <= 0 ? (
              <span className="CurrencyOverview__no-data">—</span>
            ) : (
              <ValuePercent value={overviewData.liquidationThreshold} color="white" />
            )}
          </Row>

          <Row
            className="CurrencyOverview__row"
            title={
              <LiquidationBonusHelpModal
                text={intl.formatMessage(messages.liquidationPenalty)}
                color="white"
                lightWeight={true}
              />
            }
            color="white"
            weight="light"
            isColumn={isCollapse}
          >
            {overviewData.liquidationBonus <= 0 ? (
              <span className="CurrencyOverview__no-data">—</span>
            ) : (
              <ValuePercent value={overviewData.liquidationBonus} color="white" />
            )}
          </Row>

          <Row
            className="CurrencyOverview__row"
            title={intl.formatMessage(messages.assetPrice)}
            color="white"
            weight="light"
            isColumn={isCollapse}
          >
            <Value
              tokenIcon={true}
              symbol="USD"
              value={overviewData.priceInUsd}
              maximumValueDecimals={2}
              color="white"
            />
          </Row>

          {+capacity > 0 && (
            <Row
              className="CurrencyOverview_row"
              title={
                <SupplyCapHelpModal
                  text={intl.formatMessage(messages.capacity)}
                  color="white"
                  lightWeight={true}
                />
              }
              color="white"
              weight="light"
              isColumn={isCollapse}
            >
              <div className="d-flex">
                <Value
                  tokenIcon={false}
                  compact={true}
                  value={overviewData.totalLiquidity}
                  maximumValueDecimals={2}
                  color="white"
                />
                <span>/</span>
                <Value
                  tokenIcon={false}
                  compact={true}
                  value={capacity}
                  maximumValueDecimals={2}
                  color="white"
                />
              </div>
            </Row>
          )}
        </>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCollapse,
    overviewData.priceInUsd,
    overviewData.baseLTVasCollateral,
    overviewData.liquidationThreshold,
    overviewData.liquidationBonus,
    overviewData.stableBorrowRateEnabled,
    overviewData.stableRate,
    overviewData.avg30DaysVariableRate,
    overviewData.variableRate,
    currentLangSlug,
  ]);

  return (
    <div
      className={classNames('CurrencyOverview', {
        CurrencyOverview__borrow: !isBorrow,
        CurrencyOverview__collapsed: isCollapse,
      })}
    >
      <div className="CurrencyOverview__caption">
        {title && <p className="CurrencyOverview__caption-title">{title}</p>}
        {/* <Link */}
        <div
          // to={`/reserve-overview/${currencySymbol}${poolReserve.id}`}
          className="CurrencyOverview__captionLink"
          color="white"
        >
          <TokenIcon tokenSymbol={currencySymbol} height={sm ? 30 : 20} width={sm ? 30 : 20} />
          <p>{intl.formatMessage(messages.caption, { symbol: collateralAssetSymbol })}</p>
          {/* </Link> */}
        </div>
        {title && <p className="CurrencyOverview__caption-title" />}
      </div>

      <GradientLine height={sm ? 1 : 2} />

      <div className="CurrencyOverview__content">
        <div className="CurrencyOverview__content-left">
          {/* {isCollapse || sm ? (
            <LeftInformation />
          ) : (
            <div className="CurrencyOverview__inner">
              <LeftInformation />
            </div>
          )} */}

          {isCollapse || sm ? (
            <RightInformation />
          ) : (
            <div className="CurrencyOverview__inner">
              <RightInformation />
            </div>
          )}
        </div>

        {/*<div className="CurrencyOverview__mobileFilterButtons">*/}
        {/*  <GraphFilterButtons setMode={setMode} mode={mode} />*/}
        {/*</div> TODO: uncomment when filters are added to history graphs */}

        {!isCollapse && (
          <div
            className={classNames('CurrencyOverview__content-right', {
              CurrencyOverview__contentNoBorder: !(
                showGraphCondition && poolReserve.borrowingEnabled
              ),
            })}
          >
            <GraphInner
              showGraph={showGraphCondition && poolReserve.borrowingEnabled}
              dots={dots}
              seriesData={series}
              type={type}
              poolReserveId={poolReserve.id}
            />
          </div>
        )}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';

        .CurrencyOverview {
          color: ${currentTheme.white.hex};

          .CurrencyOverview__caption {
            .GradientLine {
              @include respond-to(sm) {
                background: ${currentTheme.white.hex};
              }
            }
          }

          .CurrencyOverview__content {
            & .d-flex {
              display: flex;
              flex-direction: row;
              span {
                margin-left: 2px;
                margin-right: 2px;
              }
            }
          }

          .ValuePercent.ValuePercent__darkOrange,
          .ValuePercent.ValuePercent__primary,
          .ValuePercent.ValuePercent__secondary {
            .ValuePercent__value.ValuePercent__value {
              span {
                color: ${currentTheme.white.hex};
              }
            }
          }

          &__usageAsCollateral {
            color: ${currentTheme.green.hex};
          }
          &__usageAsCollateralDisabled {
            color: ${currentTheme.red.hex};
          }

          &__content-right {
            border: 1px solid ${graphBorder};
            color: ${currentTheme.darkBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}
