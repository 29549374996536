import { defineMessages } from 'react-intl';

export default defineMessages({
  markets: 'Market',
  dashboard: 'My Page',
  deposit: 'Deposit',
  borrow: 'Borrow',
  swap: 'Swap',
  stake: 'Stake',
  faucet: 'Faucet',
  history: 'History',
  // governance: 'Governance',
});
