import { defineMessages } from 'react-intl';

export default defineMessages({
  useLeverage: 'Use leverage',
  newHF: 'New health factor',
  safer: 'Safer',
  riskier: 'Riskier',
  leverageUp: 'Leverage amount',
  collateralDeposit: 'Collateral deposit',
  liquidationPrice: 'Liquidation price',
  leveragedAPY: 'APY',
  gasEstimation: 'Gas Estimation',
  flashloanFee: 'Flashloan Fee',
  description: 'Which stable coin would you like to borrow?',
  flashloanDescription: 'Would you like to leverage with flashloan?',
  yes: 'Yes',
  no: 'No',
  slippage: 'Slippage tolerance:',
});
