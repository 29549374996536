import React from 'react';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { valueToBigNumber, BigNumber, InterestRate } from '@sturdyfi/sturdy-js';

import NoDataPanel from 'src/components/NoDataPanel';
import BasicForm from 'src/components/forms/BasicForm';
// import BorrowInterestRateForm from '../../components/BorrowInterestRateForm';
import BorrowCurrencyWrapper from '../../components/BorrowCurrencyWrapper';
import InfoWrapper from 'src/components/wrappers/InfoWrapper';
import AMPLWarning from 'src/components/AMPLWarning';

import { useTxBuilderContext } from 'src/libs/tx-provider';
import messages from './messages';

import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from 'src/components/RouteParamsValidationWrapper';
import { getAssetInfo } from 'src/helpers/markets/assets';

// enum BorrowStep {
//   AmountForm,
//   RateModeSelection,
// }

interface BorrowAmountProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol' | 'history'
  > {}

function BorrowAmount({
  userReserve,
  poolReserve,
  user,
  currencySymbol,
  history,
}: BorrowAmountProps) {
  // const [amountToBorrow, setAmountToBorrow] = useState('0');
  // const [borrowStep, setBorrowStep] = useState<BorrowStep>(BorrowStep.AmountForm);
  const intl = useIntl();
  const { lendingPool } = useTxBuilderContext();
  const OPTIMAL_UTILIZATION_RATE = 0.8;
  const asset = getAssetInfo(currencySymbol);

  const maxUserAmountToBorrow = valueToBigNumber(user?.availableBorrowsETH || 0).div(
    poolReserve.price.priceInEth
  );
  const availableLiquidityFromUtilization = (
    Number(poolReserve.totalLiquidity) * OPTIMAL_UTILIZATION_RATE -
    Number(poolReserve.totalDebt)
  ).toString();
  let maxAmountToBorrow = BigNumber.max(
    BigNumber.min(
      poolReserve.availableLiquidity,
      availableLiquidityFromUtilization,
      maxUserAmountToBorrow
    ),
    0
  );
  if (
    maxAmountToBorrow.gt(0) &&
    user?.totalBorrowsETH !== '0' &&
    maxUserAmountToBorrow.lt(valueToBigNumber(poolReserve.availableLiquidity).multipliedBy('1.01'))
  ) {
    maxAmountToBorrow = maxAmountToBorrow.multipliedBy('0.99');
  }

  const availableToBorrow =
    new BigNumber(maxUserAmountToBorrow)
      .times(Math.pow(10, poolReserve.decimals).toString())
      .gte(1) && Number(maxAmountToBorrow) > 0;

  const formattedMaxAmountToBorrow = availableToBorrow
    ? maxAmountToBorrow
        .times(Math.pow(10, poolReserve.decimals))
        .dp(0, BigNumber.ROUND_DOWN)
        .div(Math.pow(10, poolReserve.decimals))
        .toString(10)
    : '0';

  const handleSetAmountSubmit = (amount: string) => {
    // setAmountToBorrow(amount);
    // setBorrowStep(BorrowStep.RateModeSelection);
    const rateMode = 'Variable';
    const query = queryString.stringify({ rateMode, amount: amount });
    history.push(`${history.location.pathname}/confirmation?${query}`);
  };

  // const handleInterestModeSubmit = (rateMode: string) => {
  //   const query = queryString.stringify({ rateMode, amount: amountToBorrow });
  //   history.push(`${history.location.pathname}/confirmation?${query}`);
  // };

  // const goBack = () => setBorrowStep(BorrowStep.AmountForm);

  const handleTransactionData = (userId: string) => async () => {
    const referralCode = undefined;
    return await lendingPool.borrow({
      interestRateMode: InterestRate.Variable,
      referralCode,
      user: userId,
      amount: formattedMaxAmountToBorrow,
      reserve: poolReserve.underlyingAsset,
      debtTokenAddress: poolReserve.variableDebtTokenAddress,
    });
  };

  return (
    <BorrowCurrencyWrapper
      poolReserve={poolReserve}
      currencySymbol={currencySymbol}
      userReserve={userReserve}
      user={user}
      // goBack={borrowStep === BorrowStep.RateModeSelection ? () => goBack() : undefined}
    >
      {formattedMaxAmountToBorrow !== '0' ? (
        <>
          {/* {borrowStep === BorrowStep.AmountForm && ( */}
          <BasicForm
            title={intl.formatMessage(messages.title)}
            description={intl.formatMessage(messages.description)}
            maxAmount={formattedMaxAmountToBorrow}
            currencySymbol={currencySymbol}
            onSubmit={handleSetAmountSubmit}
            amountFieldTitle={intl.formatMessage(messages.amountTitle)}
            withRiskBar={true}
            maxDecimals={poolReserve.decimals}
            getTransactionData={handleTransactionData}
          />
          {/* )} */}

          {/* {borrowStep === BorrowStep.RateModeSelection && (
            <BorrowInterestRateForm
              poolReserve={poolReserve}
              userReserve={userReserve}
              amountToBorrow={amountToBorrow}
              onSubmit={handleInterestModeSubmit}
            />
          )} */}
        </>
      ) : (
        <NoDataPanel
          title={
            !user
              ? intl.formatMessage(messages.connectWallet)
              : poolReserve.availableLiquidity === '0'
              ? intl.formatMessage(messages.noLiquidityAvailableTitle)
              : !user || user.totalLiquidityETH === '0'
              ? intl.formatMessage(messages.noDataTitle)
              : formattedMaxAmountToBorrow === '0'
              ? intl.formatMessage(messages.noLiquidityAvailableTitle)
              : intl.formatMessage(messages.healthFactorTooLowTitle)
          }
          description={
            !user
              ? intl.formatMessage(messages.connectWalletDescription)
              : poolReserve.availableLiquidity === '0'
              ? intl.formatMessage(messages.noLiquidityAvailableDescription, {
                  symbol: asset.formattedName,
                })
              : !user || user.totalLiquidityETH === '0'
              ? intl.formatMessage(messages.noDataDescription)
              : formattedMaxAmountToBorrow === '0'
              ? intl.formatMessage(messages.utilizationOverDescription, {
                  symbol: asset.formattedName,
                })
              : intl.formatMessage(messages.healthFactorTooLowDescription)
          }
          // buttonTitle={!user ? undefined : intl.formatMessage(messages.noDataButtonTitle)}
          // linkTo={!user ? undefined : `/provide/${poolReserve.underlyingAsset}-${poolReserve.id}`}
          withConnectButton={!user}
        />
      )}

      <InfoWrapper>{currencySymbol === 'AMPL' && <AMPLWarning withInfoPanel={true} />}</InfoWrapper>
    </BorrowCurrencyWrapper>
  );
}

export default routeParamValidationHOC({})(BorrowAmount);
