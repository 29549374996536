import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: '{symbol} Reserve Overview',

  utilizationRate: 'Utilization rate',
  availableLiquidity: 'Available liquidity',
  depositAPY: 'Deposit APY',
  pastThirtyDayAverage: 'Past 30 Day Average',
  canBeUsedAsCollateral: 'Can be used as collateral',
  no: 'No',
  yes: 'Yes',
  assetPrice: 'Asset price',
  maximumLTV: 'Maximum LTV',
  liquidationThreshold: 'Liquidation threshold',
  liquidationPenalty: 'Liquidation penalty',

  stableAPY: 'Stable borrow APY',
  variableAPY: 'Borrow APY',
  capacity: 'Maximum supply',
});
