import { defineMessages } from 'react-intl';

export default defineMessages({
  apyRowTitle: 'APY',
  changeAPYRowTitle: 'Change APY Type',

  yourBorrows: 'Your borrows',
  secondTableColumnTitle: 'Borrowed',
  fourthTableColumnTitle: 'APY Type',
  thirtyDaysAverageTitle: '30 Days Average',

  yourCollat: 'Your collateral',
  secondTableColumnTitleC: 'Provided',
  fourthTableColumnTitleC: 'Utilization',

  borrowMore: 'Borrow more',
  repayYourBorrow: 'Repay your borrow',

  offLabel: 'Stable',
  onLabel: 'Variable',
});
