import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Network } from '@sturdyfi/sturdy-js';

import { getProvider, REWARD_TYPE } from '../../../helpers/markets/markets-data';
import {
  UiIncentiveDataProvider,
  UserReserveIncentiveDataHumanizedResponse,
  Denominations,
} from '@sturdyfi/contract-helpers';
import { useProtocolDataContext } from '../../protocol-data-provider';

// interval in which the rpc data is refreshed
const POOLING_INTERVAL = 30 * 1000;
// decreased interval in case there was a network error for faster recovery
const RECOVER_INTERVAL = 10 * 1000;

// From UiIncentiveDataProvider
export interface ReserveIncentiveData {
  underlyingAsset: string;
  aIncentiveData: ReserveTokenIncentives;
  vIncentiveData: ReserveTokenIncentives;
  sIncentiveData: ReserveTokenIncentives;
}

export interface ReserveRewardData {
  underlyingAsset: string;
  rewardData: ReserveTokenRewards;
}

// From UiIncentiveDataProvider
export interface UserReserveIncentiveData {
  underlyingAsset: string;
  aTokenIncentivesUserData: UserTokenIncentives;
  vTokenIncentivesUserData: UserTokenIncentives;
  sTokenIncentivesUserData: UserTokenIncentives;
}

export interface UserReserveRewardData {
  rewardTokenAddress: string;
  rewardUserDatas: UserTokenRewards[];
  totalUnclaimedRewards: number;
  id: string;
}

interface ReserveTokenIncentives {
  emissionPerSecond: string;
  incentivesLastUpdateTimestamp: number;
  tokenIncentivesIndex: string;
  emissionEndTimestamp: number;
  tokenAddress: string;
  rewardTokenAddress: string;
  incentiveControllerAddress: string;
  rewardTokenDecimals: number;
  precision: number;
  priceFeed: string;
  priceFeedTimestamp: number;
  priceFeedDecimals: number;
}

interface ReserveTokenRewards {
  emissionPerSecond: string;
  incentivesLastUpdateTimestamp: number;
  emissionEndTimestamp: number;
  incentiveRatio: number;
  lastAvailableRewards: string;
  tokenIncentivesIndex: string;
  tokenAddress: string;
  rewardTokenAddress: string;
  rewardTokenSymbol?: string;
  distributorAddress: string;
  rewardTokenDecimals: number;
  rewardType?: REWARD_TYPE;
  id: string;
}

interface UserTokenIncentives {
  tokenIncentivesUserIndex: string;
  userUnclaimedRewards: string;
  tokenAddress: string;
  rewardTokenAddress: string;
  incentiveControllerAddress: string;
  rewardTokenDecimals: number;
}

interface UserTokenRewards {
  tokenIncentivesUserIndex: string;
  userUnclaimedRewards: string;
  tokenAddress: string;
  rewardTokenAddress: string;
  rewardTokenSymbol?: string;
  distributorAddress: string;
  rewardTokenDecimals: number;
  rewardType?: REWARD_TYPE;
}

export interface IncentiveDataResponse {
  loading: boolean;
  error: boolean;
  data: {
    reserveIncentiveData?: ReserveIncentiveData[];
    userIncentiveData?: UserReserveIncentiveData[];
    reserveRewardData?: ReserveRewardData[];
    userRewardData?: UserReserveRewardData[];
  };
  refresh: () => Promise<void>;
}

// Fetch reserve and user incentive data from UiIncentiveDataProvider
export function useIncentivesData(
  lendingPoolAddressProvider: string,
  network: Network,
  incentiveDataProviderAddress: string,
  skip: boolean,
  userAddress?: string
): IncentiveDataResponse {
  const { networkConfig, chainId } = useProtocolDataContext();
  const currentAccount: string | undefined = userAddress ? userAddress.toLowerCase() : undefined;
  const [loadingReserveIncentives, setLoadingReserveIncentives] = useState<boolean>(true);
  const [errorReserveIncentives, setErrorReserveIncentives] = useState<boolean>(false);
  const [loadingUserIncentives, setLoadingUserIncentives] = useState<boolean>(true);
  const [errorUserIncentives, setErrorUserIncentives] = useState<boolean>(false);
  const [reserveIncentiveData, setReserveIncentiveData] = useState<
    ReserveIncentiveData[] | undefined
  >(undefined);
  const [userIncentiveData, setUserIncentiveData] = useState<
    UserReserveIncentiveData[] | undefined
  >(undefined);
  const [reserveRewardData, setReserveRewardData] = useState<ReserveRewardData[] | undefined>(
    undefined
  );
  const [userRewardData, setUserRewardData] = useState<UserReserveRewardData[] | undefined>(
    undefined
  );

  // Fetch reserve incentive data and user incentive data only if currentAccount is set
  const fetchData = async (
    currentAccount: string | undefined,
    lendingPoolAddressProvider: string,
    incentiveDataProviderAddress: string
  ) => {
    fetchReserveIncentiveData(lendingPoolAddressProvider, incentiveDataProviderAddress);
    if (currentAccount && currentAccount !== ethers.constants.AddressZero) {
      fetchUserIncentiveData(
        currentAccount,
        lendingPoolAddressProvider,
        incentiveDataProviderAddress
      );
    } else {
      setLoadingUserIncentives(false);
    }
  };

  // Fetch and format reserve incentive data from UiIncentiveDataProvider contract
  const fetchReserveIncentiveData = async (
    lendingPoolAddressProvider: string,
    incentiveDataProviderAddress: string
  ) => {
    setReserveIncentiveData([]);
    setErrorReserveIncentives(false);
    setLoadingReserveIncentives(false);

    const provider = getProvider(network);
    const incentiveDataProviderContract = new UiIncentiveDataProvider({
      incentiveDataProviderAddress,
      provider,
      chainId,
    });

    try {
      const rawData = await incentiveDataProviderContract.getIncentivesDataWithPrice({
        lendingPoolAddressProvider,
        quote: networkConfig.usdMarket ? Denominations.usd : Denominations.eth,
        chainlinkFeedsRegistry: networkConfig.addresses.chainlinkFeedRegistry,
      });

      const rawReserveIncentiveData: ReserveIncentiveData[] = [];
      const rawReserveRewardData: ReserveRewardData[] = [];
      rawData.forEach((item) => {
        rawReserveIncentiveData.push({
          underlyingAsset: item.underlyingAsset,
          aIncentiveData: item.aIncentiveData,
          vIncentiveData: item.vIncentiveData,
          sIncentiveData: item.sIncentiveData,
        });

        const rewardInfo = networkConfig?.collateralRewardAddresses?.find(
          (rewardInfo) =>
            rewardInfo.token.toLowerCase() === item.rewardData.rewardTokenAddress.toLowerCase()
        );
        rawReserveRewardData.push({
          underlyingAsset: item.underlyingAsset,
          rewardData: {
            ...item.rewardData,
            rewardTokenSymbol: rewardInfo?.symbol,
            rewardType: rewardInfo?.type,
            id: `${item.rewardData.tokenAddress}-${item.rewardData.distributorAddress}`.toLowerCase(),
          },
        });
      });
      setReserveIncentiveData(rawReserveIncentiveData);
      setReserveRewardData(rawReserveRewardData);
      setErrorReserveIncentives(false);
    } catch (e) {
      console.log('e', e);
      setErrorReserveIncentives(e.message);
    }
    setLoadingReserveIncentives(false);
  };

  // Fetch and format user incentive data from UiIncentiveDataProvider
  const fetchUserIncentiveData = async (
    currentAccount: string,
    lendingPoolAddressProvider: string,
    incentiveDataProviderAddress: string
  ) => {
    setUserIncentiveData([]);
    setUserRewardData([]);
    setErrorUserIncentives(false);
    setLoadingUserIncentives(false);

    const provider = getProvider(network);
    const incentiveDataProviderContract = new UiIncentiveDataProvider({
      incentiveDataProviderAddress,
      provider,
      chainId,
    });

    try {
      const rawData: UserReserveIncentiveDataHumanizedResponse[] =
        await incentiveDataProviderContract.getUserReservesIncentivesDataHumanized(
          currentAccount,
          lendingPoolAddressProvider
        );

      const rawUserIncentiveData: UserReserveIncentiveData[] = [];
      const rawUserRewardData: UserReserveRewardData[] = [];
      rawData.forEach((item) => {
        rawUserIncentiveData.push({
          underlyingAsset: item.underlyingAsset,
          aTokenIncentivesUserData: item.aTokenIncentivesUserData,
          vTokenIncentivesUserData: item.vTokenIncentivesUserData,
          sTokenIncentivesUserData: item.sTokenIncentivesUserData,
        });
      });

      networkConfig?.collateralRewardAddresses?.forEach((rewardInfo) => {
        const rewardData = rawData
          .filter(
            (item) =>
              item.rewardUserData.rewardTokenAddress.toLowerCase() ===
              rewardInfo.token.toLowerCase()
          )
          .map((item) => ({
            ...item.rewardUserData,
            rewardTokenSymbol: rewardInfo?.symbol,
            rewardType: rewardInfo?.type,
          }));
        rawUserRewardData.push({
          rewardTokenAddress: rewardInfo.token,
          rewardUserDatas: rewardData,
          totalUnclaimedRewards: rewardData
            .map((item) => Number(item.userUnclaimedRewards))
            .reduce((totalUnclaimedRewards, item) => totalUnclaimedRewards + item),
          id: `${rewardInfo.token.toLowerCase()}-${rewardData[0].distributorAddress}`.toLowerCase(),
        });
      });

      setUserIncentiveData(rawUserIncentiveData);
      setUserRewardData(rawUserRewardData);
      setErrorUserIncentives(false);
    } catch (e) {
      console.log('e', e);
      setErrorUserIncentives(e.message);
    }
    setLoadingUserIncentives(false);
  };

  useEffect(() => {
    setLoadingReserveIncentives(true);
    setLoadingUserIncentives(true);

    if (!skip) {
      fetchData(currentAccount, lendingPoolAddressProvider, incentiveDataProviderAddress);
      const intervalID = setInterval(
        () => fetchData(currentAccount, lendingPoolAddressProvider, incentiveDataProviderAddress),
        errorReserveIncentives || errorUserIncentives ? RECOVER_INTERVAL : POOLING_INTERVAL
      );
      return () => clearInterval(intervalID);
    } else {
      setLoadingReserveIncentives(false);
      setLoadingUserIncentives(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, lendingPoolAddressProvider, skip]);

  const loading = loadingReserveIncentives || loadingUserIncentives;
  const error = errorReserveIncentives || errorUserIncentives;
  return {
    loading,
    error,
    data: { reserveIncentiveData, userIncentiveData, reserveRewardData, userRewardData },
    refresh: () =>
      fetchData(currentAccount, lendingPoolAddressProvider, incentiveDataProviderAddress),
  };
}
