import classNames from 'classnames';

import staticStyles from './style';

import checkmarkIcon from './images/checkmarkIcon.svg';

interface CollateralMarkerProps {
  className?: string;
  icon?: string;
}

export default function CollateralMarker({ className, icon }: CollateralMarkerProps) {
  return (
    <button className={classNames('CollateralMarker', className)} type="button" disabled={true}>
      <img src={icon || checkmarkIcon} alt="" />

      <style jsx={true}>{staticStyles}</style>
    </button>
  );
}
