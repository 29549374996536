import { useState, useEffect } from 'react';
import { usePooling } from './use-pooling';
import curve from '@curvefi/api';
// import { networkConfigs } from '../../ui-config';
// import { Network } from '@sturdyfi/sturdy-js';

const TIME_POOLING = 100000;

const useGetCurveExchangeRate = (
  tokenSymbol1: string,
  tokenSymbol2: string,
  amount: string,
  skip?: boolean,
  interval?: number
) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState<string | null>();
  // const network = localStorage.getItem('preferredNetwork');
  // const config = networkConfigs[network || Network.mainnet];

  const apiRequest = async () => {
    try {
      await curve.init(
        'Alchemy',
        { network: 'homestead', apiKey: '1TKC60gVuUgBj2DqmdjusK-zmDl-Edag' },
        { gasPrice: 0, maxFeePerGas: 0, maxPriorityFeePerGas: 0, chainId: 1 }
      );
      const expected = await curve.exchangeExpected(
        tokenSymbol1,
        tokenSymbol2,
        Number(amount).toFixed(18)
      );
      setData(expected);
      setError(false);
    } catch (err) {
      console.error(
        ` Error on getting the exchange rate between ${tokenSymbol1}, and ${tokenSymbol2} `,
        err
      );
      setError(true);
    }
  };

  useEffect(() => {
    if (!skip) apiRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  usePooling(apiRequest, interval ? interval : TIME_POOLING, !!skip, [skip]);

  return { data, error };
};

export default useGetCurveExchangeRate;
