import amplitude from 'amplitude-js';
import TagManager from 'react-gtm-module';

export const AmplitudeEventType = {
  deposit: 'Deposit',
  provide: 'Provide',
  withdraw: 'Withdraw',
  borrow: 'Borrow',
  repay: 'Repay',
  leverage: 'Leverage',
  uncertain_deposit: 'Uncertain Deposit',
  uncertain_provide: 'Uncertain Provide',
  uncertain_withdraw: 'Uncertain Withdraw',
  uncertain_borrow: 'Uncertain Borrow',
  uncertain_repay: 'Uncertain Repay',
  uncertain_leverage: 'Uncertain Leverage',
};

export type AmplitudeEventProperty = {
  network: string;
  reserve: string;
  amount: number;
  value: number;
};

export const initAmplitude = () => {
  const amplitude_id =
    process.env.REACT_APP_AMPLITUDE_API_KEY || '129e96f5d74c59a44be2585ef30a46b8';
  amplitude.getInstance().init(amplitude_id, undefined, {
    includeUtm: true,
    includeGclid: true,
    includeFbclid: true,
    includeReferrer: true,
  });
};

export const sendAmplitudeEvent = (
  user: string,
  eventName: string,
  property: AmplitudeEventProperty
) => {
  amplitude.getInstance().setUserId(user);

  const gtm_id = process.env.REACT_APP_GTM_ID || 'GTM-PKCRZFM';
  const tagManagerArgs = {
    gtmId: gtm_id,
    dataLayer: {
      event: 'logEvent',
      eventType: eventName,
      eventProperties: property,
    },
  };
  TagManager.initialize(tagManagerArgs);
};
