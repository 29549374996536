import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Footer {
    position: relative;
    z-index: 2;
    padding: 16px 35px 16px;
    display: flex;
    // align-items: center;
    // flex-direction: row;
    justify-content: flex-end;
    @include respond-to(xl) {
      // padding: 10px 10px 40px;
    }
    @include respond-to(sm) {
      display: none;
    }

    &__inside {
      @include respond-to(md) {
        display: none;
      }
    }

    .DarkModeSwitcher {
      margin-right: 10px;

      .DarkModeSwitcher__slider {
        height: 24px;

        img {
          width: 22px;
        }
      }
    }

    .MoreButton__socialIcon {
      margin: 0px 8px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export default staticStyles;
