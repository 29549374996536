import React, { useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import {
  DropdownWrapper,
  rgba,
  textCenterEllipsis,
  useThemeContext,
} from '@sturdyfi/sturdy-ui-kit';
import { providers } from 'ethers';
import { ChainId, Network } from '@sturdyfi/sturdy-js';

import { mapChainIdToName, useUserWalletDataContext } from '../../../libs/web3-data-provider';
import { useMenuContext } from '../../../libs/menu';
// import Link from '../../basic/Link';
import ConnectButton from '../../ConnectButton';

import staticStyles from './style';
import messages from './messages';
import { Link } from 'react-router-dom';
import UAuth from '@uauth/js';

export default function AddressInfo() {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { chainId } = useWeb3React();
  const {
    currentAccount,
    disconnectWallet,
    displaySwitchAccountModal,
    currentProviderName,
    availableAccounts,
  } = useUserWalletDataContext();
  const { closeMobileMenu } = useMenuContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const [userEns, setUserEns] = useState('');

  useEffect(() => {
    get_ens();
  }, [currentAccount, chainId]);

  const get_ens = async () => {
    if (!currentAccount || !provider) {
      return setUserEns('');
    }
    
    if (currentProviderName == 'uath-connect') {
      const clientID =
        process.env.REACT_APP_UNSTOPPABLE_DOMAIN_CLIENTID || 'e240de52-3903-4bb9-a839-3dcd6a3c0ff3';
      const redirectUri = 
        process.env.REACT_APP_UNSTOPPABLE_DOMAIN_REDIRECT_URI || 'https://app.sturdy.finance/';
      const uauth = new UAuth({
        clientID,
        redirectUri
      });
      const user = await uauth.user();
      setUserEns(user.sub);
    } else if (chainId === ChainId.mainnet){
      var ens = (await provider.lookupAddress(currentAccount)) || '';
      setUserEns(ens);
    }
  }

  const [visible, setVisible] = useState(false);
  const networkName = chainId && mapChainIdToName(chainId);
  let networkColor = '';
  if (networkName === 'ropsten') {
    networkColor = '#ff4a8d';
  } else if (networkName === 'kovan') {
    networkColor = '#7157ff';
  } else {
    networkColor = '#65c970';
  }
  let networkMessage: MessageDescriptor = messages.networkName;
  if (
    networkName === Network.polygon ||
    networkName === Network.avalanche ||
    networkName === Network.ftm ||
    networkName === Network.ftm_test
  ) {
    networkMessage = messages.networkShortName;
  } else {
    networkMessage = messages.networkName;
  }

  let formattedNetworkName = '';

  if (networkName === Network.polygon || networkName === Network.avalanche) {
    formattedNetworkName = networkName;
  } else if (networkName === Network.mainnet || networkName === Network.fork) {
    formattedNetworkName = 'Ethereum';
  } else if (networkName === Network.ftm) {
    formattedNetworkName = 'Fantom';
  } else if (networkName === Network.ftm_test) {
    formattedNetworkName = 'Fantom Testnet';
  } else {
    formattedNetworkName = `${networkName} Test Network`;
  }

  const borderColor = rgba(`${currentTheme.darkBlue.rgb}, 0.1`);
  const hoverColor = rgba(`${currentTheme.darkBlue.rgb}, 0.05`);

  return (
    <div className="AddressInfo">
      {currentAccount ? (
        <DropdownWrapper
          visible={visible}
          setVisible={setVisible}
          horizontalPosition="right"
          verticalPosition="bottom"
          className="AddressInfo__dropdownWrapper"
          buttonComponent={
            <button
              className={classNames('AddressInfo__button', { AddressInfo__buttonActive: visible })}
              onClick={() => setVisible(!visible)}
              type="button"
            >
              <p>{formattedNetworkName}</p>
              <span>{userEns || textCenterEllipsis(currentAccount, 4, 4)}</span>
            </button>
          }
        >
          <div className="AddressInfo__content">
            <div className="AddressInfo__content-caption">
              <p className="AddressInfo__content-network">
                <i />
                <span>
                  {intl.formatMessage(networkMessage, {
                    name: networkName === 'ftm_test' ? 'Fantom' : networkName,
                  })}
                </span>
              </p>
              {userEns && <p className="AddressInfo__content-address">{userEns}</p>}
              <p className="AddressInfo__content-address">{currentAccount}</p>
            </div>

            {(networkName === Network.ftm ||
              networkName === Network.mainnet) && (
              <Link
                to="/history"
                className="AddressInfo__contentButton ButtonLink"
                onClick={() => {
                  setVisible(false);
                  closeMobileMenu();
                }}
              >
                <span>{intl.formatMessage(messages.history)}</span>
              </Link>
            )}

            {(currentProviderName?.includes('ledger') || availableAccounts.length > 1) && (
              <button
                className="AddressInfo__contentButton"
                type="button"
                onClick={() => displaySwitchAccountModal(true)}
              >
                <span>{intl.formatMessage(messages.changeAddress)}</span>
              </button>
            )}

            <button
              className="AddressInfo__contentButton"
              type="button"
              onClick={() => {
                disconnectWallet();
                closeMobileMenu();
              }}
            >
              <span>{intl.formatMessage(messages.disconnect)}</span>
            </button>
          </div>
        </DropdownWrapper>
      ) : (
        <ConnectButton size="small" />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .AddressInfo {
          &__button {
            background: ${currentTheme.darkBlueLighter.hex};
            color: ${currentTheme.white.hex};
            &:hover {
              border-color: ${currentTheme.white.hex};
            }
          }

          &__buttonActive {
            border-color: ${currentTheme.white.hex};
          }

          &__content {
            color: ${currentTheme.darkBlue.hex};
          }

          &__content-caption {
            border-bottom: 1px solid ${currentTheme.darkBlue.hex};
          }
          &__content-network {
            i {
              background: ${networkColor};
            }
          }

          &__contentButton {
            color: ${currentTheme.darkBlue.hex} !important;
            border-bottom: 1px solid ${borderColor};
            &:hover {
              background: ${hoverColor};
            }
          }
        }
      `}</style>
    </div>
  );
}
