import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .LeverageOptionArea {
    margin: 25px auto 0;
    width: 335px;
    position: relative;
    display: flex;
    flex-direction: column;

    @include respond-to(lg) {
      width: 260px;
    }
    @include respond-to(md) {
      width: 335px;
    }

    .CheckBoxField {
      label {
        justify-content: flex-start;
      }
    }

    h3 {
      font-size: $small;
    }

    &__inner {
      padding: 0 10px;
    }

    &__title {
      margin-top: 20px;
    }

    &__error-text {
      text-align: center;
      font-size: $medium;
      @include respond-to(lg) {
        font-size: $small;
      }
      @include respond-to(md) {
        font-size: $medium;
      }
    }

    &__info {
      width: 100%;
      padding: 5px;
      font-size: $small;

      &-inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    &__newHF {
      display: flex;
      align-items: center;
      p,
      .ValuePercent .ValuePercent__value {
        font-size: $small;
        @include respond-to(lg) {
          font-size: $extraSmall;
        }
        @include respond-to(md) {
          font-size: $small;
        }
      }
      .ValuePercent {
        margin-left: 5px;
      }
    }

    &__range {
      &-top-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2px;
        min-height: 16px;
        font-size: $extraSmall;
      }

      &-inner {
        width: 100%;
        border-radius: 10px;
        background-image: linear-gradient(to right, #65c970, #ffac4d 53%, #de5959);

        .LeverageOptionArea__track {
          height: 12px;
          width: calc(100% - 12px);
          margin: 0 auto;
          border-radius: 10px;
        }
        .LeverageOptionArea__thumb {
          width: 10px;
          height: 10px;
          border-radius: 10px;
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
          outline: none !important;
        }
      }
    }

    &__selector {
      margin: 20px 0;
      &-selection {
        padding: 5px;
      }
    }
    &__select-option {
      padding: 7px 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      &:disabled {
        cursor: default;
      }
    }

    &__flashloan {
      &-selector {
        margin: 0 5px;
        display: flex;
        flex-direction: row;
        .CheckBoxField {
          flex: 1;
          label {
            justify-content: flex-start;
          }
        }
      }
    }
  }
`;

export default staticStyles;
