import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import toggleLocalStorageClick from '../../../helpers/toggle-local-storage-click';
import ScreenWrapper from '../ScreenWrapper';
import TopPanelWrapper from '../TopPanelWrapper';
import ContentWrapper from '../ContentWrapper';
import TopInfoPanel from './TopInfoPanel';
import CollateralOverview from './CollateralOverview';
// import { useProvideCollateralContext } from '../ScreensWrapper';

import staticStyles from './style';

import { ValidationWrapperComponentProps } from '../../RouteParamsValidationWrapper';
import { GraphLegendDot } from '../../graphs/GraphLegend';
import { InterestRateSeries } from '../../graphs/types';
import { networkConfigs } from 'src/ui-config';
import { getCurrentNetworkName } from 'src/config';

import messages from './messages';

interface CurrencyScreenWrapperProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol'
  > {
  title: string;
  isCollapseLocalStorageName: string;
  // walletEthBalance?: string;
  walletBalance?: string;
  type: 'deposit' | 'borrow';
  showGraphCondition: boolean;
  dots?: GraphLegendDot[];
  series: InterestRateSeries[];
  goBack?: () => void;
  children: ReactNode;
}

export default function CollateralScreenWrapper({
  title,
  currencySymbol,
  poolReserve,
  user,
  userReserve,
  // walletEthBalance,
  walletBalance,
  isCollapseLocalStorageName,
  type,
  showGraphCondition,
  dots,
  series,
  goBack,
  children,
}: CurrencyScreenWrapperProps) {
  const intl = useIntl();
  const { currentTheme, sm } = useThemeContext();

  const [isCollapse, setIsCollapse] = useState(
    // localStorage.getItem(isCollapseLocalStorageName) === 'true'
    true
  );

  const collateralAssetSymbol =
    /*Object.entries(networkConfigs[getCurrentNetworkName()].collateralAssets || {}).find(
      (item: [string, ReadonlyArray<string>]) => {
        return item[1].includes(currencySymbol);
      }
    )?.[0] || */ currencySymbol;

  // const { tokenSymbol } = useProvideCollateralContext();

  return (
    <ScreenWrapper
      pageTitle={intl.formatMessage(type === 'deposit' ? messages.deposit : messages.borrow)}
      className="CurrencyScreenWrapper"
    >
      {!sm && (
        <div className="CurrencyScreenWrapper__top-info">
          <TopInfoPanel
            poolReserve={poolReserve}
            currencySymbol={currencySymbol}
            walletBalance={walletBalance}
            userReserve={userReserve}
            user={user}
            type={type}
          />
        </div>
      )}

      <TopPanelWrapper
        isCollapse={isCollapse}
        setIsCollapse={() =>
          toggleLocalStorageClick(isCollapse, setIsCollapse, isCollapseLocalStorageName)
        }
        withoutCollapseButton={true}
      >
        <CollateralOverview
          title={title}
          poolReserve={poolReserve}
          currencySymbol={collateralAssetSymbol}
          type={type}
          showGraphCondition={showGraphCondition}
          dots={dots}
          series={series}
          isCollapse={isCollapse}
        />
      </TopPanelWrapper>

      <div className="CurrencyScreenWrapper__mobileInner">
        <TopInfoPanel
          poolReserve={poolReserve}
          currencySymbol={collateralAssetSymbol}
          walletBalance={walletBalance}
          userReserve={userReserve}
          user={user}
          type={type}
        />
      </div>

      <ContentWrapper
        className="CurrencyScreenWrapper__content"
        withBackButton={true}
        goBack={goBack}
      >
        {children}
      </ContentWrapper>

      <div className="CurrencyScreenWrapper__mobileInner">
        <CollateralOverview
          poolReserve={poolReserve}
          currencySymbol={collateralAssetSymbol}
          type={type}
          showGraphCondition={showGraphCondition}
          dots={dots}
          series={series}
        />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .CurrencyScreenWrapper {
          &__mobileInner {
            background: ${currentTheme.darkBlue.hex};

            .TopInfoPanel {
              div,
              p {
                color: ${currentTheme.cream.hex};
              }
            }
          }
        }
      `}</style>
    </ScreenWrapper>
  );
}
