import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import css from 'styled-jsx/css';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useStaticPoolDataContext } from './libs/pool-data-provider';
import { useMenuContext } from './libs/menu';
import { CURRENCY_ROUTE_PARAMS } from './helpers/router-types';
import ScreensWrapper from './components/wrappers/ScreensWrapper';
import { useProtocolDataContext } from './libs/protocol-data-provider';
import { isFeatureEnabled } from './helpers/markets/markets-data';

import {
  Markets,
  ReserveOverview,
  History,
  Deposit,
  Withdraw,
  WithdrawCollateral,
  Borrow,
  Repay,
  Dashboard,
  Provide,
  Faucet,
} from './modules';

import { RewardConfirm } from './modules/reward/screens/RewardConfirm';
import { StableRewardConfirm } from './modules/reward/screens/StableRewardConfirm';
import { VariableRewardConfirm } from './modules/reward/screens/VariableRewardConfirm';

const staticStyles = css.global`
  .App {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
    height: 1px;
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      position: relative;
    }
  }
`;

function ModulesWithMenu() {
  const { isUserHasDeposits, userId } = useStaticPoolDataContext();
  const { currentMarketData } = useProtocolDataContext();

  return (
    <ScreensWrapper>
      <Switch>
        <Route path="/markets" component={Markets} />
        <Route path="/dashboard" component={Dashboard} />

        <Route path="/deposit" component={Deposit} />
        <Route path={`/provide/${CURRENCY_ROUTE_PARAMS}`} component={Provide} />
        <Route path={`/withdraw/${CURRENCY_ROUTE_PARAMS}`} component={Withdraw} />
        <Route
          path={`/withdraw-collateral/${CURRENCY_ROUTE_PARAMS}`}
          component={WithdrawCollateral}
        />

        <Route path="/borrow" component={Borrow} />
        <Route path={`/repay/${CURRENCY_ROUTE_PARAMS}`} component={Repay} />

        <Route
          exact={true}
          path={`/reserve-overview/${CURRENCY_ROUTE_PARAMS}`}
          component={ReserveOverview}
        />

        <Route
          path="/rewards/confirm/:incentivesControllerAddress"
          component={RewardConfirm}
          key="Reward confirm"
        />

        <Route
          path="/stable-rewards/confirm/:distributorAddress"
          component={StableRewardConfirm}
          key="Stable Reward confirm"
        />

        <Route
          path="/variable-rewards/confirm/:distributorAddress"
          component={VariableRewardConfirm}
          key="Variable Reward confirm"
        />

        {isFeatureEnabled.faucet(currentMarketData) && [
          <Route path="/faucet" component={Faucet} key="Faucet" />,
        ]}

        {userId && [<Route exact={true} path="/history" component={History} key="History" />]}

        <Redirect to={isUserHasDeposits ? '/dashboard' : '/markets'} />
      </Switch>
    </ScreensWrapper>
  );
}

const App: React.FC = () => {
  const { md } = useThemeContext();
  const { openMobileMenu } = useMenuContext();

  const handlers = useSwipeable({
    onSwipedLeft: () => (md ? openMobileMenu() : null),
  });

  return (
    <div className="App">
      <div {...handlers} className="App__content">
        <Switch>
          <Route component={ModulesWithMenu} />
        </Switch>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
};

export default App;
