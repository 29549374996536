import React, { useState, useEffect } from 'react';
// import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { BigNumber, Network, valueToBigNumber } from '@sturdyfi/sturdy-js';
// import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useTxBuilderContext } from '../../../../libs/tx-provider';
// import { usePayments } from '../../../../helpers/payments';
// import { getLPTokenPoolLink } from '../../../../helpers/lp-tokens';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import NoDataPanel from '../../../../components/NoDataPanel';
// import DefaultButton from '../../../../components/basic/DefaultButton';
import BasicForm from '../../../../components/forms/BasicForm';
import LeverageOptionArea from '../../../../components/LeverageOptionArea';
// import PaymentsPanel from '../../components/PaymentsPanel';
import Link from '../../../../components/basic/Link';
import InfoPanel from '../../../../components/InfoPanel';
import InfoWrapper from '../../../../components/wrappers/InfoWrapper';
import AMPLWarning from '../../../../components/AMPLWarning';
import ProvideCollateralWrapper from '../../components/ProvideCollateralWrapper';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import { API_ETH_MOCK_ADDRESS } from '@sturdyfi/sturdy-js';
import { useProvideCollateralContext } from '../../../../components/wrappers/ScreensWrapper';

import messages from './messages';

// import linkIcon from '../../../../images/whiteLinkIcon.svg';
import { isFeatureEnabled } from '../../../../helpers/markets/markets-data';
import { getAssetInfo, isAssetStable } from '../../../../helpers/markets/assets';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import { mapChainIdToName } from 'src/libs/web3-data-provider';
import { useDynamicPoolDataContext, useReserveAPYDataContext } from 'src/libs/pool-data-provider';
import GeneralVaultInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v2/GeneralVault';

interface DepositAmountProps
  extends Pick<
    ValidationWrapperComponentProps,
    | 'currencySymbol'
    | 'poolReserve'
    | 'history'
    | 'walletBalance'
    | 'walletEthBalance'
    | 'user'
    | 'userReserve'
  > {}

function DepositAmount({
  currencySymbol,
  poolReserve,
  user,
  userReserve,
  history,
  walletBalance,
  walletEthBalance,
}: DepositAmountProps) {
  const intl = useIntl();
  const { reserves } = useReserveAPYDataContext();
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [leverage, setLeverage] = useState(false);
  const [leverageCount, setLeverageCount] = useState(1);
  const [maxLeverageCount, setMaxLeverageCount] = useState('10');
  const [levBorrowAssetSymbol, setLevBorrowAssetSymbol] = useState('');

  const collateral = reserves.find((reserve) => reserve.symbol === currencySymbol);

  if (!user || !collateral) {
    return null;
  }

  useEffect(() => {
    setLeverageCount(1);
    const { baseLTVasCollateral } = collateral;
    const ratio =
      Number(baseLTVasCollateral) /
      (1 + (currentMarketData?.flashloanSlippage || 0))
    setMaxLeverageCount((1 + ratio / (1 - ratio)).toFixed(2));
  }, []);

  const { isTestnet, networkConfig, currentMarketData } = useProtocolDataContext();
  const {
    lidoVault,
    convexFRAX3CRVVault,
    convexDAIUSDCUSDTSUSDVault,
    convexIronBankVault,
    convexFRAXUSDCVault,
    convexMIM3CRVVault,
    convexTUSDFRAXBPVault,
    yearnVault,
    yearnWETHVault,
    yearnWBTCVault,
    yearnBOOVault,
    yearnFBeetsVault,
    yearnLINKVault,
    yearnSPELLVault,
    yearnCRVVault,
    tombFtmBeefyVault,
    tombMiMaticBeefyVault,
    basedMiMaticBeefyVault,
  } = useTxBuilderContext();
  // const { payments, isPaymentNashNotOnMainMarket } = usePayments();
  const { chainId } = useWeb3React<providers.Web3Provider>();
  const currentWalletNetwork = mapChainIdToName(chainId as number) as Network;
  const symbolToVault: { [key: string]: { [key: string]: GeneralVaultInterface } } = {
    [Network.ftm]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.ftm_test]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.mainnet]: {
      stETH: lidoVault,
      FRAX_3CRV_LP: convexFRAX3CRVVault,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDVault,
      IRON_BANK_LP: convexIronBankVault,
      FRAX_USDC_LP: convexFRAXUSDCVault,
      MIM_3CRV_LP: convexMIM3CRVVault,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPVault,
    },
  };

  // const { sm } = useThemeContext();

  const asset = getAssetInfo(currencySymbol);

  // const routeHistory = useHistory<RouteComponentProps>();
  const { tokenSymbol, setTokenSymbol } = useProvideCollateralContext();
  if (!networkConfig.collateralAssets?.[currencySymbol]?.includes(tokenSymbol))
    setTokenSymbol(currencySymbol);

  const handleTokenSwitcher = (token: string) => {
    setTokenSymbol(token);
    // routeHistory.push(`/provide/${token}-${poolReserve.id}`);
  };

  let maxAmountToDeposit = valueToBigNumber(
    tokenSymbol === networkConfig.baseAsset ? walletEthBalance : walletBalance
  );

  if (maxAmountToDeposit.gt(0) && tokenSymbol.toUpperCase() === networkConfig.baseAsset) {
    // keep it for tx gas cost
    maxAmountToDeposit = maxAmountToDeposit.minus(
      networkConfig.baseAssetGas ? networkConfig.baseAssetGas : '0.001'
    );
  }
  if (maxAmountToDeposit.lte(0)) {
    maxAmountToDeposit = valueToBigNumber('0');
  }

  const handleLeverageCountChange = (newCount: string) => {
    const newCountValue = Number(newCount);
    setError('');
    setLeverageCount(newCountValue);
  };

  const handleLevBorrowAssetChange = (newSymbol: string) => {
    setError('');
    setLevBorrowAssetSymbol(newSymbol);
  };

  const handleSubmit = (amount: string) => {
    if (leverage) {
      if (levBorrowAssetSymbol === '') {
        setError(intl.formatMessage(messages.errorEmptyBorrowAsset));
        return;
      }
      if (leverageCount === 1) {
        setError(intl.formatMessage(messages.errorInvalidLeverage));
        return;
      }
      
      const borrowAsset = reserves.find(
        ({ symbol, borrowingEnabled, isActive }) =>
          symbol.toUpperCase() === levBorrowAssetSymbol?.toUpperCase() &&
          borrowingEnabled &&
          isActive &&
          isAssetStable(symbol)
      );
      if (!borrowAsset) {
        setError(intl.formatMessage(messages.errorEmptyBorrowAsset));
        return;
      }

      const amountInETH = new BigNumber(amount).multipliedBy(poolReserve.price.priceInEth);
      const borrowAmount = amountInETH
        .dividedBy(borrowAsset.price.priceInEth)
        .multipliedBy(leverageCount - 1)
        .plus(0.5)                                        //rounding up
        .plus(0.5)                                        //rounding up

      if (borrowAmount.gt(borrowAsset.availableLiquidity)) {
        setError(intl.formatMessage(messages.errorAvailableLiquidity));
        return;
      }

      const query = queryString.stringify({ amount, leverageCount, levBorrowAssetSymbol });
      history.push(`${history.location.pathname}/leverage?${query}`);
    }
    else {
      const query = queryString.stringify({ amount });
      history.push(`${history.location.pathname}/confirmation?${query}`);
    }
  };

  const handleGetTransactions = (userId: string) => async () => {
    let vault = lidoVault;
    for (const [key, value] of Object.entries(symbolToVault?.[currentWalletNetwork])) {
      if (networkConfig.collateralAssets?.[key]?.includes(tokenSymbol)) {
        vault = value;
        break;
      }
    }

    return await vault.depositCollateral({
      _user: userId,
      _asset:
        tokenSymbol === networkConfig.baseAsset
          ? API_ETH_MOCK_ADDRESS
          : networkConfig.collateralAddresses?.[tokenSymbol] || poolReserve.underlyingAsset,
      _amount: maxAmountToDeposit.toString(10),
    });
  };

  // const lpPoolLink = getLPTokenPoolLink(poolReserve);

  return (
    <ProvideCollateralWrapper
      currencySymbol={tokenSymbol}
      poolReserve={poolReserve}
      walletBalance={tokenSymbol === networkConfig.baseAsset ? walletEthBalance : walletBalance}
      userReserve={userReserve}
      user={user}
    >
      {user && (
        <>
          <BasicForm
            title={intl.formatMessage(messages.title)}
            description={
              tokenSymbol === 'AAVE' && isFeatureEnabled.staking(currentMarketData)
                ? intl.formatMessage(messages.aaveDescription, {
                    stake: <strong>{intl.formatMessage(messages.stake)}</strong>,
                    link: (
                      <Link
                        className="italic"
                        to="/staking"
                        bold={true}
                        title={intl.formatMessage(messages.stakingView)}
                      />
                    ),
                  })
                : tokenSymbol.toUpperCase() === 'STETH'
                ? intl.formatMessage(messages.stethDescription, {
                    link: (
                      <Link
                        to="https://docs.sturdy.finance/overview/staking-strategies"
                        absolute={true}
                        bold={true}
                        title="here"
                        color="dark"
                      />
                    ),
                  })
                : intl.formatMessage(messages.description)
            }
            amountFieldTitle={intl.formatMessage(messages.amountTitle)}
            maxAmount={maxAmountToDeposit.toString(10)}
            currencySymbol={tokenSymbol}
            onSubmit={handleSubmit}
            maxDecimals={poolReserve.decimals}
            getTransactionData={handleGetTransactions}
            depositCollateral={true}
            handleTokenSwitcher={
              networkConfig.collateralAssets?.[currencySymbol].includes(networkConfig.baseAsset)
                ? handleTokenSwitcher
                : undefined
            }
            childrenAtBottom={true}
            onAmountChange={setAmount}
          >
            {poolReserve.leverageEnabled && (
              <LeverageOptionArea
                enabled={leverage}
                onEnableChange={setLeverage}
                depositAmount={Number(amount)}
                leverageCount={leverageCount}
                maxLeverageCount={maxLeverageCount}
                onLeverageCountChange={handleLeverageCountChange}
                levBorrowAssetSymbol={levBorrowAssetSymbol}
                onLevBorrowAssetChange={handleLevBorrowAssetChange}
                collateralReserve={collateral}
                error={error}
              />
            )}
          </BasicForm>
        </>
      )}
      {!user && (
        <NoDataPanel
          title={
            !user
              ? intl.formatMessage(messages.connectWallet)
              : intl.formatMessage(messages.noDataTitle)
          }
          description={
            !user
              ? intl.formatMessage(messages.connectWalletDescription)
              : intl.formatMessage(messages.noDataDescription, {
                  currencySymbol: asset.formattedName,
                })
          }
          linkTo={!user ? undefined : isTestnet ? `/faucet` : undefined}
          buttonTitle={
            !user
              ? undefined
              : isTestnet
              ? intl.formatMessage(messages.noDataButtonTitle)
              : undefined
          }
          withConnectButton={!user}
        />
      )}

      {/* {maxAmountToDeposit.eq('0') && user && lpPoolLink && (
        <NoDataPanel
          title={intl.formatMessage(messages.noDataTitle)}
          description={intl.formatMessage(messages.noDataLPTokenDescription, {
            currencySymbol: asset.formattedName,
          })}
        >
          <Link to={lpPoolLink} absolute={true} inNewWindow={true} className="ButtonLink">
            <DefaultButton
              className="DepositAmount__poolLink--button"
              title={intl.formatMessage(messages.viewPool)}
              iconComponent={<img src={linkIcon} alt="" />}
              size="medium"
              mobileBig={true}
            />
          </Link>
        </NoDataPanel>
      )}
     */}
      {/* {user &&
        sm &&
        payments.some(
          (payment) =>
            payment.availableAssets?.includes(currencySymbol.toUpperCase()) &&
            !isPaymentNashNotOnMainMarket(payment.name)
        ) && (
          <PaymentsPanel
            currencySymbol={currencySymbol}
            withoutOrTitle={maxAmountToDeposit.eq('0')}
          />
        )} */}

      <InfoWrapper>
        {currencySymbol === 'AMPL' && <AMPLWarning withInfoPanel={true} />}

        {currencySymbol === 'AAVE' && isFeatureEnabled.staking(currentMarketData) && (
          <InfoPanel>
            {intl.formatMessage(messages.aaveWarning, {
              link: (
                <Link
                  className="italic"
                  to="/staking"
                  bold={true}
                  title={intl.formatMessage(messages.stakingView)}
                />
              ),
            })}
          </InfoPanel>
        )}

        {currencySymbol === 'SNX' && !maxAmountToDeposit.eq('0') && (
          <InfoPanel>
            {intl.formatMessage(messages.warningText, {
              symbol: <strong>{currencySymbol}</strong>,
            })}
          </InfoPanel>
        )}

        {/* {user &&
          !sm &&
          payments.some(
            (payment) =>
              payment.availableAssets?.includes(currencySymbol.toUpperCase()) &&
              !isPaymentNashNotOnMainMarket(payment.name)
          ) && <PaymentsPanel currencySymbol={currencySymbol} />} */}
      </InfoWrapper>
    </ProvideCollateralWrapper>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
})(DepositAmount);
