import React from 'react';

import TableItem from '../../../../components/BasicAssetsTable/TableItem';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';
import CollateralMarker from '../../../../components/CollateralMarker';
import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
import NoData from '../../../../components/basic/NoData';
import { isAssetStable } from '../../../../helpers/markets/assets';
import TableButtonsWrapper from '../../../dashboard/components/DashboardTable/TableButtonsWrapper';
import TableButtonCol from '../../../dashboard/components/DashboardTable/TableButtonCol';

import { DepositTableItem } from './types';

export default function DepositItem({
  id,
  symbol,
  underlyingBalance,
  underlyingBalanceInUSD,
  walletBalance,
  walletBalanceInUSD,
  liquidityRate,
  avg30DaysLiquidityRate,
  userId,
  borrowingEnabled,
  usageAsCollateralEnabled,
  isFreezed,
  aIncentivesAPY,
  aprDetails,
}: DepositTableItem) {
  // const url = `/deposit/${underlyingAsset}-${id}`;
  const hasDeposit = underlyingBalance.toString() > '0';
  const hasBalance = Number(walletBalance) > 0;
  const hasDeposits = Number(underlyingBalance) > 0;
  const depositBtnTitle = 'Deposit';
  const depositLink = usageAsCollateralEnabled ? 'provide' : 'deposit';
  const withdrawLink = usageAsCollateralEnabled ? 'withdraw-collateral' : 'withdraw';
  if (isFreezed) return <></>;

  return (
    <TableItem symbol={symbol} /*isFreezed={isFreezed}*/ darkOnDarkMode={true}>
      <TableColumn>
        {!userId || !hasBalance ? (
          <NoData color="dark" />
        ) : (
          <Value
            value={Number(walletBalance)}
            subValue={walletBalanceInUSD}
            maximumSubValueDecimals={2}
            subSymbol="USD"
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumn>

      <TableColumn>
        {!userId || !hasDeposit ? (
          <NoData color="dark" />
        ) : (
          <Value
            value={Number(underlyingBalance)}
            subValue={underlyingBalanceInUSD}
            maximumSubValueDecimals={2}
            subSymbol="USD"
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumn>

      {/* {!isFreezed && ( */}
        <TableColumn>
          <LiquidityMiningCard
            value={liquidityRate}
            thirtyDaysValue={avg30DaysLiquidityRate}
            liquidityMiningValue={aIncentivesAPY}
            symbol={symbol}
            type="deposit"
            aprDetails={aprDetails}
          />
        </TableColumn>
      {/* )} */}

      <TableColumn>{usageAsCollateralEnabled && <CollateralMarker />}</TableColumn>

      <TableColumn>
        <TableButtonsWrapper>
          <TableButtonCol
            disabled={false}
            title={depositBtnTitle}
            linkTo={`/${depositLink}/${symbol}-${id}`}
          />
          <TableButtonCol
            disabled={!hasDeposits}
            title={'Withdraw'}
            linkTo={`/${withdrawLink}/${symbol}-${id}`}
          />
        </TableButtonsWrapper>
      </TableColumn>
    </TableItem>
  );
}
