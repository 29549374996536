import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  calculateHealthFactorFromBalancesBigUnits,
  valueToBigNumber,
  BigNumber,
  API_ETH_MOCK_ADDRESS,
  Network,
} from '@sturdyfi/sturdy-js';
import GeneralLevSwapInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v2/GeneralLevSwap';

import Row from 'src/components/basic/Row';
import NoDataPanel from 'src/components/NoDataPanel';
import PoolTxConfirmationView from 'src/components/PoolTxConfirmationView';
import Value from 'src/components/basic/Value';
import HealthFactor from 'src/components/HealthFactor';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from 'src/components/RouteParamsValidationWrapper';
import { useProvideCollateralContext } from 'src/components/wrappers/ScreensWrapper';
import { getAtokenInfo } from 'src/helpers/get-atoken-info';
import { isAssetStable } from 'src/helpers/markets/assets';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import useGetCurveExchangeRate from 'src/libs/hooks/use-curve-exchange-rate';

import defaultMessages from 'src/defaultMessages';
import messages from './messages';
import { mapChainIdToName } from 'src/libs/web3-data-provider';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { AmplitudeEventType, sendAmplitudeEvent } from 'src/helpers/amplitude';
import { getDefaultNetworkNameByString } from 'src/config';
import { useStaticPoolDataContext, useReserveAPYDataContext } from 'src/libs/pool-data-provider';
import GeneralVaultInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v2/GeneralVault';

function WithdrawConfirmation({
  currencySymbol,
  userReserve,
  poolReserve,
  amount,
  user,
  leverageEnabled,
  levBorrowAssetSymbol,
  slippage,
  repayAmount,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const { networkConfig } = useProtocolDataContext();
  const { reserves } = useReserveAPYDataContext();
  const {
    lidoVault,
    convexFRAX3CRVVault,
    convexDAIUSDCUSDTSUSDVault,
    convexIronBankVault,
    convexFRAXUSDCVault,
    convexMIM3CRVVault,
    convexTUSDFRAXBPVault,
    yearnVault,
    yearnWETHVault,
    yearnWBTCVault,
    yearnBOOVault,
    yearnFBeetsVault,
    yearnLINKVault,
    yearnSPELLVault,
    yearnCRVVault,
    tombFtmBeefyVault,
    tombMiMaticBeefyVault,
    basedMiMaticBeefyVault,
    convexFRAX3CRVLevSwap,
    convexDAIUSDCUSDTSUSDLevSwap,
    convexFRAXUSDCLevSwap,
    convexIRONBANKLevSwap,
    convexMIM3CRVLevSwap,
    convexTUSDFRAXBPLevSwap,
  } = useTxBuilderContext();
  const { chainId } = useWeb3React<providers.Web3Provider>();
  const currentWalletNetwork = mapChainIdToName(chainId as number) as Network;
  const aTokenData = getAtokenInfo({
    address: poolReserve.underlyingAsset,
    symbol: currencySymbol,
    decimals: poolReserve.decimals,
    withFormattedSymbol: true,
  });
  const symbolToVault: { [key: string]: { [key: string]: GeneralVaultInterface } } = {
    [Network.ftm]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.ftm_test]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.mainnet]: {
      stETH: lidoVault,
      FRAX_3CRV_LP: convexFRAX3CRVVault,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDVault,
      IRON_BANK_LP: convexIronBankVault,
      FRAX_USDC_LP: convexFRAXUSDCVault,
      MIM_3CRV_LP: convexMIM3CRVVault,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPVault,
    },
    [Network.fork]: {
      stETH: lidoVault,
      FRAX_3CRV_LP: convexFRAX3CRVVault,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDVault,
      IRON_BANK_LP: convexIronBankVault,
      FRAX_USDC_LP: convexFRAXUSDCVault,
      MIM_3CRV_LP: convexMIM3CRVVault,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPVault,
    },
  };
  const symbolToLevSwap: { [key: string]: { [key: string]: GeneralLevSwapInterface } } = {
    [Network.mainnet]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
    },
    [Network.fork]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      MIM_3CRV_LP: convexMIM3CRVLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
    },
  };

  const [isTxExecuted, setIsTxExecuted] = useState(false);
  const { marketRefPriceInUsd } = useStaticPoolDataContext();

  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  if (!userReserve || !amount) {
    return null;
  }

  const underlyingBalance = valueToBigNumber(userReserve.underlyingBalance);
  const availableLiquidity = valueToBigNumber(poolReserve.availableLiquidity);
  let maxAmountToWithdraw = BigNumber.min(underlyingBalance, availableLiquidity);
  let maxCollateralToWithdrawInETH = valueToBigNumber('0');

  if (
    userReserve.usageAsCollateralEnabledOnUser &&
    poolReserve.usageAsCollateralEnabled &&
    user.totalBorrowsETH !== '0'
  ) {
    // if we have any borrowings we should check how much we can withdraw without liquidation
    // with 0.5% gap to avoid reverting of tx
    const excessHF = valueToBigNumber(user.healthFactor).minus('1');
    if (excessHF.gt('0')) {
      maxCollateralToWithdrawInETH = excessHF
        .multipliedBy(user.totalBorrowsETH)
        // because of the rounding issue on the contracts side this value still can be incorrect
        .div(Number(poolReserve.reserveLiquidationThreshold) + 0.01)
        .multipliedBy('0.99');
    }
    maxAmountToWithdraw = BigNumber.min(
      maxAmountToWithdraw,
      maxCollateralToWithdrawInETH.dividedBy(poolReserve.price.priceInEth)
    );
  }

  let amountToWithdraw = amount;
  let displayAmountToWithdraw = amount;

  if (amountToWithdraw.eq('-1')) {
    if (user.totalBorrowsETH !== '0') {
      if (!maxAmountToWithdraw.eq(underlyingBalance)) {
        amountToWithdraw = maxAmountToWithdraw;
      }
    }
    displayAmountToWithdraw = maxAmountToWithdraw;
  }

  let blockingError = '';
  let totalCollateralInETHAfterWithdraw = valueToBigNumber(user.totalCollateralETH);
  let liquidationThresholdAfterWithdraw = user.currentLiquidationThreshold;
  let healthFactorAfterWithdraw = valueToBigNumber(user.healthFactor);

  const repayAsset = leverageEnabled
    ? reserves.find(
        ({ symbol, borrowingEnabled, isActive }) =>
          symbol.toUpperCase() === levBorrowAssetSymbol?.toUpperCase() &&
          borrowingEnabled &&
          isActive &&
          isAssetStable(symbol)
      )
    : null;
  let totalBorrowsInETHAfterWithdraw = valueToBigNumber(user.totalBorrowsETH);
  let flashloanFee = valueToBigNumber('0');
  let realWithdrawInETH = valueToBigNumber('0');
  const BALANCER_FLASHLOAN_FEE = '0';
  const SLIPPAGE = '1.01';
  if (leverageEnabled) {
    const repayAmountInETH = valueToBigNumber(repayAmount || '0').multipliedBy(
      repayAsset?.price.priceInEth || '0'
    );

    realWithdrawInETH = repayAmountInETH
      .multipliedBy(SLIPPAGE)
      .plus(amount.multipliedBy(poolReserve.price.priceInEth));

    flashloanFee = repayAmountInETH
      .dividedBy(marketRefPriceInUsd)
      .multipliedBy(valueToBigNumber(BALANCER_FLASHLOAN_FEE));

    totalBorrowsInETHAfterWithdraw = totalBorrowsInETHAfterWithdraw.minus(repayAmountInETH);
    // because of the rounding issue
    if (totalBorrowsInETHAfterWithdraw.abs().lt('0.00001')) {
      totalBorrowsInETHAfterWithdraw = valueToBigNumber('0');
    }
  }

  if (userReserve.usageAsCollateralEnabledOnUser && poolReserve.usageAsCollateralEnabled) {
    const amountToWithdrawInEth = leverageEnabled
      ? realWithdrawInETH
      : displayAmountToWithdraw.multipliedBy(poolReserve.price.priceInEth);
    totalCollateralInETHAfterWithdraw =
      totalCollateralInETHAfterWithdraw.minus(amountToWithdrawInEth);

    liquidationThresholdAfterWithdraw = valueToBigNumber(user.totalCollateralETH)
      .multipliedBy(user.currentLiquidationThreshold)
      .minus(
        valueToBigNumber(amountToWithdrawInEth).multipliedBy(
          poolReserve.reserveLiquidationThreshold
        )
      )
      .div(totalCollateralInETHAfterWithdraw)
      .toFixed(4, BigNumber.ROUND_DOWN);

    healthFactorAfterWithdraw = calculateHealthFactorFromBalancesBigUnits(
      totalCollateralInETHAfterWithdraw,
      totalBorrowsInETHAfterWithdraw,
      liquidationThresholdAfterWithdraw
    );

    if (healthFactorAfterWithdraw.lt('1') && totalBorrowsInETHAfterWithdraw.toString() !== '0') {
      blockingError = intl.formatMessage(messages.errorCanNotWithdrawThisAmount);
    }
  }

  if (
    !blockingError &&
    (underlyingBalance.eq('0') || underlyingBalance.lt(displayAmountToWithdraw))
  ) {
    blockingError = intl.formatMessage(messages.errorYouDoNotHaveEnoughFundsToWithdrawThisAmount);
  }
  if (
    !blockingError &&
    (availableLiquidity.eq('0') || displayAmountToWithdraw.gt(poolReserve.availableLiquidity))
  ) {
    blockingError = intl.formatMessage(messages.errorPoolDoNotHaveEnoughFundsToWithdrawThisAmount);
  }

  const { tokenSymbol } = useProvideCollateralContext();

  const handleGetTransactions = async () => {
    if (leverageEnabled) {
      let levSwap = convexFRAX3CRVLevSwap;
      for (const [key, value] of Object.entries(symbolToLevSwap?.[currentWalletNetwork])) {
        if (networkConfig.collateralAssets?.[key]?.includes(tokenSymbol)) {
          levSwap = value;
          break;
        }
      }
      return await levSwap.withdrawWithFlashloan({
        _user: user.id,
        _asset:
          tokenSymbol === networkConfig.baseAsset
            ? API_ETH_MOCK_ADDRESS
            : networkConfig.collateralAddresses?.[tokenSymbol] || poolReserve.underlyingAsset,
        _repayAmount: repayAmount?.toString() || '0',
        _withdrawAmount: amount.toString(),
        _sasset: poolReserve.aTokenAddress,
        _sassetAmount: underlyingBalance.toString(),
        _slippage1: '200',
        _slippage2: slippage ? slippage : '100',
        _stableAsset: repayAsset?.underlyingAsset || '0',
      });
    } else {
      let vault = lidoVault;
      for (const [key, value] of Object.entries(symbolToVault?.[currentWalletNetwork])) {
        if (networkConfig.collateralAssets?.[key]?.includes(tokenSymbol)) {
          vault = value;
          break;
        }
      }

      return await vault.withdrawCollateral({
        _user: user.id,
        _asset:
          tokenSymbol === networkConfig.baseAsset
            ? API_ETH_MOCK_ADDRESS
            : networkConfig.collateralAddresses?.[tokenSymbol] || poolReserve.underlyingAsset,
        _amount: displayAmountToWithdraw.toString(),
        _to: user.id,
      });
    }
  };

  const handleMainTxExecuted = () => setIsTxExecuted(true);

  const isHealthFactorDangerous =
    totalBorrowsInETHAfterWithdraw.toString() !== '0' &&
    healthFactorAfterWithdraw.toNumber() <= 1.05;

  const { data: curveExchangeAmount } = useGetCurveExchangeRate(
    'stETH',
    'ETH',
    displayAmountToWithdraw.toString(),
    currentWalletNetwork === Network.ftm_test ||
      (tokenSymbol === networkConfig.baseAsset &&
        Number(displayAmountToWithdraw) < Number(maxAmountToWithdraw))
  );

  let ethAmount: string | number = '0';
  if (currentWalletNetwork === Network.ftm_test || currentWalletNetwork === Network.ftm) {
    ethAmount = displayAmountToWithdraw.toString() || '0';
  } else if (tokenSymbol === networkConfig.baseAsset) {
    if (Number(displayAmountToWithdraw) < Number(maxAmountToWithdraw)) {
      ethAmount = displayAmountToWithdraw.toString() || '0';
    } else {
      ethAmount = curveExchangeAmount ? Number(curveExchangeAmount).toString() : '0';
    }
  }

  const sendAmplitudeEventByType = (eventType: string) => () => {
    const amountInUsd = displayAmountToWithdraw
      .multipliedBy(poolReserve.price.priceInEth)
      .dividedBy(marketRefPriceInUsd);
    sendAmplitudeEvent(user.id, eventType, {
      network: getDefaultNetworkNameByString(),
      reserve: poolReserve.symbol,
      amount: +displayAmountToWithdraw,
      value: +amountInUsd,
    });
  };

  return (
    <PoolTxConfirmationView
      mainTxName={intl.formatMessage(defaultMessages.withdraw)}
      caption={intl.formatMessage(messages.caption)}
      boxTitle={intl.formatMessage(defaultMessages.withdraw)}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      approveDescription={intl.formatMessage(messages.approveDescription)}
      getTransactionsData={handleGetTransactions}
      onMainTxExecuted={handleMainTxExecuted}
      blockingError={blockingError}
      dangerousMessage={
        isHealthFactorDangerous
          ? intl.formatMessage(messages.healthFactorDangerousText, {
              liquidation: <span>{intl.formatMessage(messages.liquidation)}</span>,
            })
          : ''
      }
      aTokenData={aTokenData}
      onMainTxConfirmed={sendAmplitudeEventByType(AmplitudeEventType.withdraw)}
      onSubmitTransaction={sendAmplitudeEventByType(AmplitudeEventType.uncertain_withdraw)}
    >
      <Row title={intl.formatMessage(messages.rowTitle)} withMargin={+user.healthFactor > 0}>
        <Value
          symbol={tokenSymbol}
          value={
            tokenSymbol === networkConfig.baseAsset ? ethAmount : displayAmountToWithdraw.toString()
          }
          tokenIcon={true}
          maximumValueDecimals={isAssetStable(currencySymbol) ? 4 : 18}
          updateCondition={isTxExecuted}
          tooltipId={tokenSymbol}
        />
      </Row>

      {+user.healthFactor > 0 && (
        <>
          <HealthFactor
            title={intl.formatMessage(messages.currentHealthFactor)}
            value={user.healthFactor}
            updateCondition={isTxExecuted}
            titleColor="dark"
          />
          <HealthFactor
            title={intl.formatMessage(messages.nextHealthFactor)}
            value={healthFactorAfterWithdraw.toString()}
            withTextShadow={isHealthFactorDangerous}
            updateCondition={isTxExecuted}
            withoutModal={true}
            titleColor="dark"
          />
        </>
      )}

      {leverageEnabled && (
        <Row title={intl.formatMessage(messages.flashloanFee)}>
          <Value
            value={flashloanFee.toString()}
            symbol="USD"
            tokenIcon={true}
            withoutSymbol={true}
            maximumValueDecimals={2}
          />
        </Row>
      )}
    </PoolTxConfirmationView>
  );
}

export default routeParamValidationHOC({
  withAmount: true,
  withUserReserve: true,
  allowLimitAmount: true,
  withDeleverage: true,
})(WithdrawConfirmation);
