import { useEffect, useState } from 'react';
import { ethers, providers } from 'ethers';
import BigNumber from 'bignumber.js';
import { Network, ReserveData, UserReserveData, valueToBigNumber } from '@sturdyfi/sturdy-js';

import { IUiPoolDataProviderFactory } from '../contracts/IUiPoolDataProviderFactory';
import { IYearnVaultFactory } from '../contracts/IYearnVaultFactory';
import { getProvider } from '../../../helpers/markets/markets-data';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { IBeefyVaultFactory } from '../contracts/IBeefyVaultFactory';

// interval in which the rpc data is refreshed
const POOLING_INTERVAL = 30 * 1000;
// decreased interval in case there was a network error for faster recovery
const RECOVER_INTERVAL = 10 * 1000;

function formatObjectWithBNFields(obj: object): any {
  return Object.keys(obj).reduce((acc, key) => {
    if (isNaN(Number(key))) {
      // @ts-ignore
      let value = obj[key];
      if (value._isBigNumber) {
        value = value.toString();
      }
      acc[key] = value;
    }
    return acc;
  }, {} as any);
}

type PoolData = {
  reserves: ReserveData[];
  userReserves: UserReserveData[];
  usdPriceEth: string;
  userId?: string;
  rewardsData: {
    userUnclaimedRewards: string;
    emissionEndTimestamp: number;
  };
};

interface PoolReservesWithRPC {
  loading: boolean;
  data?: PoolData;
  error?: string;
  refresh: () => Promise<void>;
}

type RawPoolData = {
  rawReservesData: {
    underlyingAsset: string;
    name: string;
    symbol: string;
    decimals: ethers.BigNumber;
    baseLTVasCollateral: ethers.BigNumber;
    reserveLiquidationThreshold: ethers.BigNumber;
    reserveLiquidationBonus: ethers.BigNumber;
    reserveFactor: ethers.BigNumber;
    usageAsCollateralEnabled: boolean;
    borrowingEnabled: boolean;
    stableBorrowRateEnabled: boolean;
    isActive: boolean;
    isFrozen: boolean;
    liquidityIndex: ethers.BigNumber;
    variableBorrowIndex: ethers.BigNumber;
    liquidityRate: ethers.BigNumber;
    variableBorrowRate: ethers.BigNumber;
    stableBorrowRate: ethers.BigNumber;
    lastUpdateTimestamp: number;
    aTokenAddress: string;
    stableDebtTokenAddress: string;
    variableDebtTokenAddress: string;
    interestRateStrategyAddress: string;
    availableLiquidity: ethers.BigNumber;
    totalPrincipalStableDebt: ethers.BigNumber;
    averageStableRate: ethers.BigNumber;
    stableDebtLastUpdateTimestamp: ethers.BigNumber;
    totalScaledVariableDebt: ethers.BigNumber;
    priceInEth: ethers.BigNumber;
    variableRateSlope1: ethers.BigNumber;
    variableRateSlope2: ethers.BigNumber;
    stableRateSlope1: ethers.BigNumber;
    stableRateSlope2: ethers.BigNumber;
    capacity: ethers.BigNumber;
    aEmissionPerSecond: ethers.BigNumber;
    vEmissionPerSecond: ethers.BigNumber;
    sEmissionPerSecond: ethers.BigNumber;
    aIncentivesLastUpdateTimestamp: ethers.BigNumber;
    vIncentivesLastUpdateTimestamp: ethers.BigNumber;
    sIncentivesLastUpdateTimestamp: ethers.BigNumber;
    aTokenIncentivesIndex: ethers.BigNumber;
    vTokenIncentivesIndex: ethers.BigNumber;
    sTokenIncentivesIndex: ethers.BigNumber;
    leverageEnabled: boolean;
  }[];
  userReserves: {
    underlyingAsset: string;
    scaledATokenBalance: ethers.BigNumber;
    usageAsCollateralEnabledOnUser: boolean;
    stableBorrowRate: ethers.BigNumber;
    scaledVariableDebt: ethers.BigNumber;
    principalStableDebt: ethers.BigNumber;
    stableBorrowLastUpdateTimestamp: ethers.BigNumber;
    aTokenincentivesUserIndex: ethers.BigNumber;
    vTokenincentivesUserIndex: ethers.BigNumber;
    sTokenincentivesUserIndex: ethers.BigNumber;
  }[];
  usdPriceEth: ethers.BigNumber;
  rawRewardsData: {
    userUnclaimedRewards: ethers.BigNumber;
    emissionEndTimestamp: ethers.BigNumber;
  };
};

export function useProtocolDataWithRpc(
  poolAddress: string,
  rawCurrentAccount: string,
  network: Network,
  batchPoolDataProviderAddress: string,
  skip: boolean,
  injectedProvider?: providers.Web3Provider
): PoolReservesWithRPC {
  const currentAccount = rawCurrentAccount
    ? rawCurrentAccount.toLowerCase()
    : ethers.constants.AddressZero;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [poolData, setPoolData] = useState<PoolData | undefined>(undefined);
  const { networkConfig, uiProviderDataFromFB } = useProtocolDataContext();

  const fetchData = async (
    poolAddress: string,
    userAddress: string,
    network: Network,
    poolDataProvider: string
  ) => {
    await setPoolDataFromRpc(poolDataProvider, userAddress);
  };

  const setPoolDataFromFB = async (userAddress: string) => {    
    const fb_data = uiProviderDataFromFB;
    if (!fb_data) return;

    fb_data.rawReservesData.forEach((item: any) => {
      for (const key in item) {
        if (item[key].type === 'BigNumber') {
          item[key] = valueToBigNumber(item[key].hex);
        }
      }
    });
    fb_data.rawRewardsData.userUnclaimedRewards = valueToBigNumber(
      fb_data.rawRewardsData.userUnclaimedRewards.hex
    );
    fb_data.rawRewardsData.emissionEndTimestamp = valueToBigNumber(
      fb_data.rawRewardsData.emissionEndTimestamp.hex
    );
    fb_data.usdPriceEth = valueToBigNumber(fb_data.usdPriceEth.hex);

    const rawData = fb_data as RawPoolData;
    await setPoolDataFromRaw(rawData, userAddress);
  };

  const setPoolDataFromRpc = async (poolDataProvider: string, userAddress: string) => {
    const provider = getProvider(network);
    const helperContract = IUiPoolDataProviderFactory.connect(poolDataProvider, provider);
    try {
      const result = await helperContract.getReservesData(poolAddress, userAddress);
      const { 0: rawReservesData, 1: userReserves, 2: usdPriceEth, 3: rawRewardsData } = result;
      await setPoolDataFromRaw(
        { rawReservesData, userReserves, usdPriceEth, rawRewardsData },
        userAddress
      );
      setError(undefined);
    } catch (e) {
      console.log('e', e); // TODO: should be thrown most probably
      setError(e.message);
    }
  };

  const setPoolDataFromRaw = async (rawPoolData: RawPoolData, userAddress: string) => {
    const { rawReservesData, userReserves, usdPriceEth, rawRewardsData } = rawPoolData;
    const rewardsData = {
      userUnclaimedRewards: rawRewardsData.userUnclaimedRewards.toString(),
      emissionEndTimestamp: rawRewardsData.emissionEndTimestamp.toNumber(),
    };

    const formattedReservesData = await Promise.all(
      rawReservesData
        // .filter((item) => !item.isFrozen)
        .map(async (rawReserve) => {
          const formattedReserve = formatObjectWithBNFields(rawReserve);
          formattedReserve.symbol = rawReserve.symbol;
          formattedReserve.id = (rawReserve.underlyingAsset + poolAddress).toLowerCase();
          formattedReserve.underlyingAsset = rawReserve.underlyingAsset.toLowerCase();
          formattedReserve.price = { priceInEth: rawReserve.priceInEth.toString() };
          if (
            network === Network.ftm_test &&
            networkConfig.collateralAssetFromSymbol?.[rawReserve.symbol]
          ) {
            if (
              rawReserve.symbol === 'mooTombTOMB-FTM' ||
              rawReserve.symbol === 'mooTombTOMB-MIMATIC' ||
              rawReserve.symbol === 'mooTombBASED-MIMATIC'
            ) {
              const vaultContract = IBeefyVaultFactory.connect(
                rawReserve.underlyingAsset,
                getProvider(network)
              );
              formattedReserve.vaultAPY = (await vaultContract.getPricePerFullShare()).toString();
            } else {
              const vaultContract = IYearnVaultFactory.connect(
                rawReserve.underlyingAsset,
                getProvider(network)
              );
              formattedReserve.vaultAPY = (await vaultContract.pricePerShare()).toString();
            }
          }
          return formattedReserve;
        })
    );

    const formattedUserReserves = userReserves
      .filter((item) =>
        formattedReservesData.find(
          (res) => res.underlyingAsset === item.underlyingAsset.toLowerCase()
        )
      )
      .map((rawUserReserve) => {
        const reserve = formattedReservesData.find(
          (res) => res.underlyingAsset === rawUserReserve.underlyingAsset.toLowerCase()
        );
        const formattedUserReserve = formatObjectWithBNFields(rawUserReserve);
        formattedUserReserve.id = (userAddress + reserve.id).toLowerCase();

        formattedUserReserve.reserve = {
          id: reserve.id,
          underlyingAsset: reserve.underlyingAsset,
          name: reserve.name,
          symbol: reserve.symbol,
          decimals: reserve.decimals,
          reserveLiquidationBonus: reserve.reserveLiquidationBonus,
          lastUpdateTimestamp: reserve.lastUpdateTimestamp,
        };
        return formattedUserReserve;
      });

    const formattedUsdPriceEth = new BigNumber(10)
      .exponentiatedBy(18 + 8)
      .div(usdPriceEth.toString())
      .toFixed(0, BigNumber.ROUND_DOWN);
    const newPoolData = {
      reserves: formattedReservesData,
      userReserves: userAddress !== ethers.constants.AddressZero ? formattedUserReserves : [],
      usdPriceEth: formattedUsdPriceEth,
      userId: userAddress !== ethers.constants.AddressZero ? userAddress : undefined,
      rewardsData, // userUnclaimedRewards: userUnclaimedRewards.toString(),
    };
    setPoolData(newPoolData);
    setLoading(false);
  };

  useEffect(() => {
    // if data not needed now - clean the storage to don't use outdated next time
    if (skip) {
      setPoolData(undefined);
      setLoading(true);
      return;
    }

    setLoading(true);
    fetchData(poolAddress, currentAccount, network, batchPoolDataProviderAddress);

    const intervalID = setInterval(
      () => fetchData(poolAddress, currentAccount, network, batchPoolDataProviderAddress),
      error ? RECOVER_INTERVAL : POOLING_INTERVAL
    );
    return () => clearInterval(intervalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, injectedProvider, poolAddress, skip, error]);

  useEffect(() => {
    setPoolDataFromFB(currentAccount);
  }, [currentAccount, uiProviderDataFromFB])

  return {
    loading,
    data: poolData,
    error,
    refresh: () => fetchData(poolAddress, currentAccount, network, batchPoolDataProviderAddress),
  };
}
