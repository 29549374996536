import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .VariableRewardWrapper {
    display: flex;
    align-items: center;
    padding-left: 10px;

    @include respond-to(sm) {
      flex-direction: column;
      margin-bottom: 40px;
    }

    &__rewards {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      min-width: 150px;
      justify-content: end;
      @include respond-to(sm) {
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      &__title {
        margin-right: 16px;
        font-size: $regular;
        @include respond-to(xl) {
          font-size: $regular;
        }
        @include respond-to(sm) {
          margin: 8px 0;
        }
      }
    }
  }
`;

export default staticStyles;
