import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ApproveButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: $small;

    &__spinner {
      margin-right: 10px;
    }

    &__inner {
      &Disabled {
        pointer-events: none;
      }
      button {
        box-shadow: $boxShadow;
        width: 120px;
        min-height: 40px;
        font-size: $medium;
        @include respond-to(xl) {
          width: 90px;
          min-height: 30px;
          font-size: $small;
        }
      }
    }
  }
`;

export default staticStyles;
