import React, { ReactNode, ReactNodeArray, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { EthereumTransactionTypeExtended, Network } from '@sturdyfi/sturdy-js';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import { useThemeContext, SpinLoader } from '@sturdyfi/sturdy-ui-kit';

import { getDefaultNetworkName, getSupportedNetworks } from '../../config';
import { mapChainIdToName, useUserWalletDataContext } from '../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import {
  EthTransactionData,
  sendEthTransaction,
  TxStatusType,
} from '../../helpers/send-ethereum-tx';
import { ATokenInfo } from '../../helpers/get-atoken-info';
import Preloader from '../basic/Preloader';
import DefaultButton from '../basic/DefaultButton';
import Caption from '../basic/Caption';
import InfoWrapper from '../wrappers/InfoWrapper';

import messages from './messages';
import staticStyles from './style';

export interface ApproveButtonProps {
  txNetwork: Network;
  getTransactionsData: () => Promise<EthereumTransactionTypeExtended[]>;
  className?: string;
  updateTransactionsData?: boolean;
  allowedNetworks?: Network[];
  onApprovalTxConfirmed?: (confirmed: boolean) => void;
}

export default function ApproveButton({
  txNetwork,
  getTransactionsData,
  className,

  updateTransactionsData,
  allowedNetworks: _allowedNetworks,
  onApprovalTxConfirmed,
}: ApproveButtonProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { library: provider, chainId } = useWeb3React<providers.Web3Provider>();
  const { network: currentMarketNetwork } = useProtocolDataContext();
  const { disconnectWallet, currentProviderName } = useUserWalletDataContext();

  const [loadingTxData, setLoadingTxData] = useState(true);
  const [backendNotAvailable, setBackendNotAvailable] = useState(false);
  const [txConfirmed, setTxConfirmed] = useState(false);

  // todo: do types more sophisticated
  const [uncheckedApproveTxData, setApproveTxData] = useState({} as EthTransactionData);

  /**
   * For some actions like e.g. stake/gov/migration we only allow certain networks (fork, kovan, mainnet).
   * We allow to browse these actions even while the user is on a different chain/network, therefore we can have multiple cases of mismatch,
   * 1. walletNetwork is not allowed for this action
   * 2. all networks or walletNetwork is allowed, but there the browsed market does not walletNetwork the walletNetwork
   */
  const currentWalletNetwork = mapChainIdToName(chainId as number) as Network;
  const allowedNetworks = _allowedNetworks?.filter((network) =>
    getSupportedNetworks().includes(network)
  );
  // current marketNetwork is supported if the action is either not restricted to a network or the network is in the allow list
  const currentMarketNetworkIsSupported =
    !allowedNetworks || allowedNetworks?.find((network) => network === currentMarketNetwork);

  let networkMismatch = false;
  let neededNetworkName = getDefaultNetworkName();

  if (currentMarketNetworkIsSupported && currentMarketNetwork !== currentWalletNetwork) {
    networkMismatch = true;
    neededNetworkName = currentMarketNetwork;
  }

  if (!currentMarketNetworkIsSupported && txNetwork !== currentWalletNetwork) {
    networkMismatch = true;
    neededNetworkName = txNetwork;
  }

  const [customGasPrice, setCustomGasPrice] = useState<string | null>(null);
  // todo: do types more sophisticated
  const approveTxData = uncheckedApproveTxData.unsignedData
    ? (uncheckedApproveTxData as EthTransactionData & {
        unsignedData: EthTransactionData;
      })
    : undefined;

  const handleGetTxData = async () => {
    try {
      const txs = await getTransactionsData();
      const approvalTx = txs.find((tx) => tx.txType === 'ERC20_APPROVAL');

      if (approvalTx) {
        setApproveTxData({
          txType: approvalTx.txType,
          unsignedData: approvalTx.tx,
          gas: approvalTx.gas,
          name: intl.formatMessage(messages.approve),
        });
      } else {
        onApprovalTxConfirmed?.(true);
      }

      setLoadingTxData(false);
    } catch (e) {
      console.log('Error on txs loading', e);
      setBackendNotAvailable(true);
      setLoadingTxData(false);
    }
  };

  const handleApprovalTxConfirmed = () => {
    setTxConfirmed(true);
    onApprovalTxConfirmed?.(true);
  };

  useEffect(() => {
    if (approveTxData?.error?.includes('disconnected') && currentProviderName?.includes('ledger')) {
      setTimeout(() => disconnectWallet(new Error('Ledger device is disconnected')), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveTxData?.error]);

  useEffect(() => {
    if (!networkMismatch) {
      // console.log('tx loading started');
      handleGetTxData();
    } else {
      setLoadingTxData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTransactionsData, networkMismatch]);

  const handleButtonClick = async () => {
    if (approveTxData && approveTxData.txStatus !== TxStatusType.confirmed) {
      await sendEthTransaction(
        approveTxData.unsignedData,
        provider,
        setApproveTxData,
        customGasPrice,
        {
          onConfirmation: handleApprovalTxConfirmed,
        }
      );
    }
  };

  const approved = txConfirmed || approveTxData === undefined;
  const title = approved
    ? intl.formatMessage(messages.approved)
    : intl.formatMessage(messages.approve);
  const disabled = loadingTxData || approved === true;

  return (
    <div className="ApproveButton">
      {(loadingTxData || approveTxData?.loading) && (
        <SpinLoader color={currentTheme.lightBlue.hex} className="ApproveButton__spinner" />
      )}
      <div
        className={classNames('ApproveButton__inner', {
          ApproveButton__innerDisabled: disabled,
        })}
      >
        <DefaultButton
          title={title}
          className={classNames('ApproveButton', className)}
          onDarkBackground={true}
          disabled={disabled}
          color="dark"
          type="submit"
          onClick={handleButtonClick}
        />
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
