import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default function initSentry() {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          tracingOrigins: ['https://staging.sturdy.finance/', /^\//],
        }),
      ],
      tracesSampleRate: 0.2,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
      ],
      release: process.env.RELEASE,
      environment: 'staging',
      enabled: process.env.NODE_ENV === 'production',
    });
  }
}
