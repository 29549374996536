import React from 'react';
import classNames from 'classnames';

import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import { AprDetailData, ConvexAprDetailData } from '@sturdyfi/sturdy-js';
import ValuePercent from '../../basic/ValuePercent';

import messages from './messages';
import staticStyles from './style';

interface AprDetailsProps {
  data: AprDetailData;
  className?: string;
  size?: 'normal' | 'small';
  forTooltip?: boolean;
}

export default function AprDetails({
  data,
  className,
  size = 'normal',
  forTooltip,
}: AprDetailsProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();

  const convexAprData = data.convex ? data.convex : null;

  const displayConvexApr = (info: ConvexAprDetailData) => {
    return (
      <>
        {+info.baseApr > 0 && (
          <>
            <span className="AprDetails__text">{intl.formatMessage(messages.convexBaseApr)}</span>
            <ValuePercent
              value={info.baseApr}
              minimumDecimals={2}
              maximumDecimals={2}
              color={forTooltip ? 'white' : 'lightBlue'}
            />
          </>
        )}
        {+info.crvApr > 0 && (
          <>
            <span className="AprDetails__text">{intl.formatMessage(messages.convexCrvApr)}</span>
            <ValuePercent
              value={info.crvApr}
              minimumDecimals={2}
              maximumDecimals={2}
              color={forTooltip ? 'white' : 'lightBlue'}
            />
          </>
        )}
      </>
    );
  };

  return (
    <div className={classNames('AprDetails', `AprDetails__${size}`, className)}>
      {convexAprData && displayConvexApr(convexAprData)}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true}>{`
        .AprDetails {
          .AprDetails__text {
            color: ${forTooltip ? currentTheme.white.hex : currentTheme.lightBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}
