import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber, EthereumTransactionTypeExtended } from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useUserWalletDataContext } from '../../../libs/web3-data-provider';
// import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
// import TxEstimation from '../../TxEstimation';
import Caption from '../../basic/Caption';
import AmountField from '../../fields/AmountField';
import RiskBar from '../../basic/RiskBar';
import DefaultButton from '../../basic/DefaultButton';
import ConnectButton from '../../ConnectButton';
import { isAssetStable } from 'src/helpers/markets/assets';

import messages from './messages';
import staticStyles from './style';
import { useDynamicPoolDataContext } from 'src/libs/pool-data-provider';

interface BasicFormProps {
  title?: string;
  description?: string | ReactNode;
  maxAmount: string;
  amountFieldTitle?: string;
  currencySymbol: string;
  onSubmit: (amount: string, max?: boolean) => void;
  withRiskBar?: boolean;
  submitButtonTitle?: string;
  absoluteMaximum?: boolean;
  className?: string;
  maxDecimals?: number;
  warning?: ReactNode;
  children?: ReactNode;
  childrenAtBottom?: boolean;
  getTransactionData?: (
    user: string
  ) => () => Promise<EthereumTransactionTypeExtended[]> | EthereumTransactionTypeExtended[];
  depositCollateral?: boolean;
  handleTokenSwitcher?: (token: string) => void;
  amountReadOnly?: boolean;
  onAmountChange?: (value: string) => void;
}

export default function BasicForm({
  title,
  description,
  maxAmount,
  amountFieldTitle,
  currencySymbol,
  onSubmit,
  withRiskBar,
  submitButtonTitle,
  absoluteMaximum,
  className,
  maxDecimals,
  warning,
  children,
  childrenAtBottom,
  getTransactionData,
  depositCollateral,
  handleTokenSwitcher,
  amountReadOnly,
  onAmountChange,
}: BasicFormProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  // const { network } = useProtocolDataContext();
  const { currentAccount } = useUserWalletDataContext();
  const { user } = useDynamicPoolDataContext();

  const [isMaxSelected, setIsMaxSelected] = useState(false);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  if (!user) {
    return null;
  }

  useEffect(() => {
    if (amountReadOnly) {
      setAmount(maxAmount);
    }
  }, [amountReadOnly, maxAmount]);

  useEffect(() => {
    if (onAmountChange && amountReadOnly !== true) {
      onAmountChange(amount);
    }
  }, [amount]);

  const handleAmountChange = (newAmount: string) => {
    const newAmountValue = valueToBigNumber(newAmount);
    setError('');
    if (newAmountValue.gt(maxAmount)) {
      setAmount(maxAmount);
      return setIsMaxSelected(true);
    } else if (newAmountValue.isNegative()) {
      setAmount('0');
    } else {
      setAmount(newAmount);
    }
    setIsMaxSelected(false);
  };

  const handleMaxButtonClick = () => {
    setAmount(maxAmount);
    setIsMaxSelected(true);
    setError('');
  };

  const handlePercentButtonClick = (value: number) => {
    if (value === 100) {
      setAmount(maxAmount);
      setIsMaxSelected(true);
    } else {
      setAmount(((Number(maxAmount) * value) / 100).toString());
    }
    setError('');
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (valueToBigNumber(amount).isNaN() || +amount === 0) {
      setError(intl.formatMessage(messages.error));
      return;
    }
    if (!valueToBigNumber(amount).isNaN() && +amount !== 0) {
      return onSubmit(amount, absoluteMaximum && isMaxSelected);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classNames('BasicForm', className)}>
      {!!title && <Caption title={title} description={description} />}

      <div className="BasicForm__inner">
        {childrenAtBottom !== true && children}

        <AmountField
          title={amountFieldTitle}
          maxAmount={maxAmount}
          symbol={currencySymbol}
          maxDecimals={maxDecimals}
          value={amount}
          onChange={handleAmountChange}
          onMaxButtonClick={handleMaxButtonClick}
          onPercentButtonClick={handlePercentButtonClick}
          error={error}
          depositCollateral={depositCollateral}
          handleTokenSwitcher={handleTokenSwitcher}
          topDecimals={isAssetStable(currencySymbol) ? 2 : 6}
          disabled={amountReadOnly === true}
        />

        {/* {[Network.mainnet, Network.fork, Network.ftm_test].includes(network) && getTransactionData && amount && (
          <TxEstimation getTransactionsData={getTransactionData} amount={amount} />
        )} */}

        {error && <div style={{ height: 20 }} />}

        {withRiskBar && (
          <RiskBar
            value={Number(amount)}
            onChange={handleAmountChange}
            maxAmount={maxAmount}
            currencySymbol={currencySymbol}
          />
        )}

        {childrenAtBottom === true && children}
        {!!warning && <div className="BasicForm__warning">{warning}</div>}
      </div>

      <div className="BasicForm__buttons">
        {!currentAccount ? (
          <ConnectButton />
        ) : (
          <DefaultButton
            title={submitButtonTitle || intl.formatMessage(messages.continue)}
            mobileBig={true}
            color="dark"
            type="submit"
          />
        )}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .BasicForm {
          &__leverage {
            color: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </form>
  );
}
