import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber, BigNumber, ComputedReserveData } from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import { PERMISSION } from '@sturdyfi/contract-helpers';

import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import ScreenWrapper from 'src/components/wrappers/ScreenWrapper';
import NoDataPanel from 'src/components/NoDataPanel';
import AssetsFilterPanel from 'src/components/AssetsFilterPanel';
import BorrowAssetTable from '../../components/BorrowAssetTable';
// import CollateralAssetTable from '../../components/CollateralAssetTable';
// import CollateralMobileCard from '../../components/CollateralAssetTable/CollateralMobileCard';
import BorrowMobileCard from '../../components/BorrowAssetTable/BorrowMobileCard';
import DepositBorrowMainWrapper from 'src/components/wrappers/DepositBorrowMainWrapper';
import Card from 'src/components/wrappers/DepositBorrowMainWrapper/components/Card';

// import defaultMessages from 'src/defaultMessages';
import messages from './messages';

import { BorrowTableItem } from '../../components/BorrowAssetTable/types';
import { DepositTableItem } from 'src/modules/deposit/components/DepositAssetsTable/types';
import { isAssetStable } from 'src/helpers/markets/assets';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import PermissionWarning from 'src/ui-config/branding/PermissionWarning';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import Preloader from 'src/components/basic/Preloader';

import { API_ETH_MOCK_AAVE_ADDRESS } from 'src/config';
// import { useWeb3React } from '@web3-react/core';
// import { providers } from 'ethers';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

export default function BorrowMain() {
  const intl = useIntl();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves, user } = useDynamicPoolDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  const { sm } = useThemeContext();

  const [searchValue, setSearchValue] = useState('');
  const [showOnlyStableCoins, setShowOnlyStableCoins] = useState(false);

  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);

  const availableBorrowsETH = valueToBigNumber(user?.availableBorrowsETH || 0);

  const filteredReserves = reserves.filter(
    ({ symbol, borrowingEnabled, isActive }) =>
      symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      borrowingEnabled &&
      isActive &&
      (!showOnlyStableCoins || isAssetStable(symbol))
    // (!showOnlyStableCoins || isAssetStable(symbol)) &&
    // approvedMarkets.includes(symbol)
  );

  // const approvedCollateral = ['yvWFTM'];
  const collateralReserves = reserves.filter(
    ({ symbol, usageAsCollateralEnabled, isActive }) =>
      symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      isActive &&
      usageAsCollateralEnabled &&
      (!showOnlyStableCoins || isAssetStable(symbol))
    // (!showOnlyStableCoins || isAssetStable(symbol)) &&
    // approvedCollateral.includes(symbol)
  );

  const { walletData } = useWalletBalanceProviderContext();

  if (!walletData) {
    return <Preloader withText={true} />;
  }

  const listData = (withFilter: boolean) => {
    const data = (reserves: ComputedReserveData[]) =>
      reserves.map<BorrowTableItem>((reserve) => {
        const availableBorrows = availableBorrowsETH.gt(0)
          ? BigNumber.min(
              // one percent margin to don't fail tx
              availableBorrowsETH
                .div(reserve.price.priceInEth)
                .multipliedBy(user && user.totalBorrowsETH !== '0' ? '0.99' : '1'),
              reserve.availableLiquidity
            ).toNumber()
          : 0;
        const availableBorrowsInUSD = valueToBigNumber(availableBorrows)
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
          .toString();
        const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];
        return {
          ...reserve,
          currentBorrows:
            user?.reservesData.find((userReserve) => userReserve.reserve.id === reserve.id)
              ?.totalBorrows || '0',
          currentBorrowsInUSD:
            user?.reservesData.find((userReserve) => userReserve.reserve.id === reserve.id)
              ?.totalBorrowsUSD || '0',
          availableBorrows,
          availableBorrowsInUSD,
          stableBorrowRate:
            reserve.stableBorrowRateEnabled && reserve.borrowingEnabled
              ? Number(reserve.stableBorrowAPY)
              : -1,
          variableBorrowRate: reserve.borrowingEnabled ? Number(reserve.variableBorrowAPY) : -1,
          avg30DaysVariableRate: Number(reserve.avg30DaysVariableBorrowRate),
          interestHistory: [],
          aincentivesAPR: reserveIncentiveData
            ? reserveIncentiveData.aIncentives.incentiveAPR
            : '0',
          vincentivesAPR: reserveIncentiveData
            ? reserveIncentiveData.vIncentives.incentiveAPR
            : '0',
          sincentivesAPR: reserveIncentiveData
            ? reserveIncentiveData.sIncentives.incentiveAPR
            : '0',
          borrowingEnabled: reserve.borrowingEnabled,
        };
      });

    if (withFilter) {
      if (sortDesc) {
        // @ts-ignore
        return data(filteredReserves).sort((a, b) => a[sortName] - b[sortName]);
      } else {
        // @ts-ignore
        return data(filteredReserves).sort((a, b) => b[sortName] - a[sortName]);
      }
    } else {
      return data(reserves);
    }
  };

  const listData2 = (withFilter: boolean) => {
    // const { chainId } = useWeb3React<providers.Web3Provider>();
    const { networkConfig } = useProtocolDataContext();
    const data = (reserves: ComputedReserveData[]) =>
      reserves.map<DepositTableItem>((reserve) => {
        const userReserves =
          user?.reservesData.filter((userRes) => userRes.reserve.symbol === reserve.symbol) || [];
        const userReserve = userReserves?.length > 1 ? userReserves[1] : userReserves?.[0];

        const underlyingAsset =
          networkConfig.collateralAddresses?.[reserve.symbol] || reserve.underlyingAsset;
        const walletBalance =
          walletData[underlyingAsset] === '0'
            ? valueToBigNumber('0')
            : valueToBigNumber(walletData[underlyingAsset] || '0').dividedBy(
                valueToBigNumber('10').pow(reserve.decimals)
              );
        const walletBalanceInUSD = walletBalance
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
          .toString();

        const walletEthBalance = valueToBigNumber(
          walletData[API_ETH_MOCK_AAVE_ADDRESS] || '0'
        ).dividedBy(valueToBigNumber('10').pow(reserve.decimals));
        const walletEthBalanceInUSD = walletEthBalance
          .multipliedBy(reserve.price.priceInEth)
          .dividedBy(marketRefPriceInUsd)
          .toString();

        return {
          ...reserve,
          walletBalance,
          walletEthBalance,
          walletBalanceInUSD,
          walletEthBalanceInUSD,
          underlyingBalance: userReserve ? userReserve.underlyingBalance : '0',
          underlyingBalanceInUSD: userReserve ? userReserve.underlyingBalanceUSD : '0',
          liquidityRate: reserve.borrowingEnabled ? Number(reserve.liquidityRate) : '-1',
          avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
          borrowingEnabled: reserve.borrowingEnabled,
          interestHistory: [],
          aIncentivesAPY: reserve.aIncentivesAPY,
          vIncentivesAPY: reserve.vIncentivesAPY,
          sIncentivesAPY: reserve.sIncentivesAPY,
        };
      });

    if (withFilter) {
      if (sortDesc) {
        return (
          data(collateralReserves)
            .sort((a, b) => +b.walletBalanceInUSD - +a.walletBalanceInUSD)
            // @ts-ignore
            .sort((a, b) => a[sortName] - b[sortName])
        );
      } else {
        return (
          data(collateralReserves)
            .sort((a, b) => +b.walletBalanceInUSD - +a.walletBalanceInUSD)
            // @ts-ignore
            .sort((a, b) => b[sortName] - a[sortName])
        );
      }
    } else {
      return data(reserves);
    }
  };

  const isShowRightPanel = listData(false).some((item) => item.currentBorrows.toString() > '0');

  return (
    <PermissionWarning requiredPermission={PERMISSION.BORROWER}>
      <ScreenWrapper
        // pageTitle={intl.formatMessage(defaultMessages.borrow)}
        isTitleOnDesktop={true}
        withMobileGrayBg={true}
      >
        {sm && (
          <AssetsFilterPanel
            optionTitleLeft={intl.formatMessage(messages.optionTitleLeft)}
            optionTitleRight={intl.formatMessage(messages.optionTitleRight)}
            switchOnToggle={setShowOnlyStableCoins}
            switchValue={showOnlyStableCoins}
            searchValue={searchValue}
            searchOnChange={setSearchValue}
          />
        )}

        {/* <DepositBorrowMainWrapper
          contentTitle={''}
          itemsTitle={intl.formatMessage(messages.myBorrows)}
          items={listData(false).map((item, index) => (
            <React.Fragment key={index}>
              {item.currentBorrows.toString() > '0' && (
                <Card
                  link={`/borrow/${item.underlyingAsset}-${item.id}`}
                  symbol={item.symbol}
                  id={item.id}
                  value={item.currentBorrows.toString()}
                  underlyingAsset={item.underlyingAsset}
                />
              )}
            </React.Fragment>
          ))}
          isShowRightPanel={false}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showOnlyStableCoins={showOnlyStableCoins}
          setShowOnlyStableCoins={setShowOnlyStableCoins}
          withSwitchMarket={true}
          totalValue={listData(false).reduce((a, b) => a + (+b['currentBorrowsInUSD'] || 0), 0)}
        >
          {!!listData2(true).length ? (
            <>
              {!sm ? (
                <>
                  <CollateralAssetTable
                    listData={listData2(true)}
                    userId={user?.id}
                    sortName={sortName}
                    setSortName={setSortName}
                    sortDesc={sortDesc}
                    setSortDesc={setSortDesc}
                  />
                </>
              ) : (
                <>
                  {listData2(true).map((item, index) => (
                    <CollateralMobileCard userId={user?.id} {...item} key={index} />
                  ))}
                </>
              )}
            </>
          ) : (
            <NoDataPanel title={intl.formatMessage(messages.noDataText)} />
          )}
        </DepositBorrowMainWrapper> */}

        <DepositBorrowMainWrapper
          contentTitle={''}
          itemsTitle={intl.formatMessage(messages.myBorrows)}
          items={listData(false).map((item, index) => (
            <React.Fragment key={index}>
              {item.currentBorrows.toString() > '0' && (
                <Card
                  link={`/borrow/${item.underlyingAsset}-${item.id}`}
                  symbol={item.symbol}
                  isStable={item.borrowingEnabled}
                  id={item.id}
                  value={item.currentBorrows.toString()}
                  underlyingAsset={item.underlyingAsset}
                />
              )}
            </React.Fragment>
          ))}
          isShowRightPanel={isShowRightPanel}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          showOnlyStableCoins={showOnlyStableCoins}
          setShowOnlyStableCoins={setShowOnlyStableCoins}
          withSwitchMarket={true}
          totalValue={listData(false).reduce((a, b) => a + (+b['currentBorrowsInUSD'] || 0), 0)}
          background="white"
        >
          {!!listData(true).length ? (
            <>
              {!sm ? (
                <>
                  <BorrowAssetTable
                    listData={listData(true)}
                    userId={user?.id}
                    sortName={sortName}
                    setSortName={setSortName}
                    sortDesc={sortDesc}
                    setSortDesc={setSortDesc}
                  />

                  {/* <CollateralAssetTable
                    listData={listData2(true)}
                    userId={user?.id}
                    sortName={sortName}
                    setSortName={setSortName}
                    sortDesc={sortDesc}
                    setSortDesc={setSortDesc}
                  /> */}
                </>
              ) : (
                <>
                  {listData(true).map((item, index) => (
                    <BorrowMobileCard userId={user?.id} {...item} key={index} />
                  ))}
                </>
              )}
            </>
          ) : (
            <NoDataPanel title={intl.formatMessage(messages.noDataText)} />
          )}
        </DepositBorrowMainWrapper>
      </ScreenWrapper>
    </PermissionWarning>
  );
}
