import React from 'react';
// import { useIntl } from 'react-intl';
import { useWeb3React } from '@web3-react/core';
import { valueToBigNumber } from '@sturdyfi/sturdy-js';
import {
  useStaticPoolDataContext,
  useDynamicPoolDataContext,
} from '../../../../libs/pool-data-provider';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import FaucetMainWrapper from 'src/components/wrappers/FaucetMainWrapper';
import Preloader from '../../../../components/basic/Preloader';
import FaucetAssetTable from '../../components/FaucetAssetTable';

// import messages from './messages';

import { FaucetTableItem } from '../../components/FaucetAssetTable/types';
import { useWalletBalanceProviderContext } from '../../../../libs/wallet-balance-provider/WalletBalanceProvider';
// import { isAssetStable } from '../../../../helpers/markets/assets';
import { isFaucetAvailable, getFaucetTokenAddress } from '../../helper';

export default function FaucetMain() {
  // const intl = useIntl();
  const { userId } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { networkConfig } = useProtocolDataContext();
  const { chainId } = useWeb3React();

  const { walletData } = useWalletBalanceProviderContext();
  if (!walletData) {
    return <Preloader />;
  }

  const listData = reserves
    .filter(({ symbol, isActive }) => isActive && isFaucetAvailable(symbol, chainId))
    .map<FaucetTableItem>((reserve) => {
      const underlyingAsset = getFaucetTokenAddress(networkConfig, reserve, chainId);
      const walletBalance =
        walletData[underlyingAsset] === '0' || walletData[underlyingAsset] === 'undefined'
          ? valueToBigNumber('0')
          : valueToBigNumber(walletData[underlyingAsset] || '0').dividedBy(
              valueToBigNumber('10').pow(reserve.decimals)
            );
      return {
        ...reserve,
        walletBalance,
      };
    });

  return (
    <ScreenWrapper
      // pageTitle={intl.formatMessage(messages.pageTitle)}
      isTitleOnDesktop={true}
      withMobileGrayBg={true}
    >
      <FaucetMainWrapper contentTitle={''}>
        {<FaucetAssetTable listData={listData} userId={userId} />}
      </FaucetMainWrapper>
    </ScreenWrapper>
  );
}
