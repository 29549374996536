import React, { useState } from 'react';
import { AnimationArrow, DropdownWrapper, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import staticStyles from './style';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

interface TokenSwitcherProps {
  preferredToken: string;
  onSelectPreferredToken?: (token: string) => void;
}

export default function TokenSwitcher({
  preferredToken,
  onSelectPreferredToken,
}: TokenSwitcherProps) {
  const { currentTheme } = useThemeContext();
  const { networkConfig } = useProtocolDataContext();

  const [visible, setVisible] = useState(false);

  return (
    <div className="SelectPreferredToken">
      <DropdownWrapper
        contentClassName="SelectPreferredToken__content"
        visible={visible}
        setVisible={setVisible}
        buttonComponent={
          <button
            className="SelectPreferredToken__select"
            type="button"
            onClick={() => setVisible(true)}
          >
            <span>{preferredToken}</span>
            <AnimationArrow
              className="SelectPreferredToken__select-arrow"
              active={visible}
              width={12}
              height={6}
              arrowTopPosition={1}
              arrowWidth={7}
              arrowHeight={1}
              color={currentTheme.textDarkBlue.hex}
            />
          </button>
        }
        verticalPosition="bottom"
        horizontalPosition="right"
      >
        {Object.values(networkConfig.collateralAssets || [])
          .find((item) => item.includes(preferredToken))
          ?.map((token) => (
            <button
              type="button"
              className="SelectPreferredToken__option"
              onClick={() => {
                if (onSelectPreferredToken) onSelectPreferredToken(token);
                setVisible(false);
              }}
              key={token}
              disabled={token === preferredToken}
            >
              <span>{token}</span>
            </button>
          ))}
      </DropdownWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true}>{`
        // @import 'src/_mixins/screen-size';
        .SelectPreferredToken {
          &__select {
            text-align: left;
            background: ${currentTheme.whiteElement.hex};
            color: ${currentTheme.textDarkBlue.hex};
            border-right: solid 1px ${currentTheme.darkBlue.hex};

            // @include respond-to(sm) {
            //   background: ${currentTheme.white.hex};
            // }
          }

          &__option {
            color: ${currentTheme.darkBlue.hex};
            &:after {
              background: ${currentTheme.darkBlue.hex};
            }
            &:hover,
            &:disabled {
              background: ${currentTheme.mainBg.hex};
              color: ${currentTheme.textDarkBlue.hex};
            }
          }
        }
      `}</style>
    </div>
  );
}
