import { defineMessages } from 'react-intl';

export default defineMessages({
  amountTitle: 'Available to withdraw',
  degenscoreTitle: 'Only accessible to approved DegenScore users',
  formDescription: 'How much do you want to withdraw?',
  degenscoreDescription: 'This vault will become publicly accessible soon.',

  pageTitle: `Withdraw {currencySymbol}`,

  connectWallet: 'Please connect a wallet',
  connectWalletDescription: 'We couldn’t detect a wallet. Connect a wallet to withdraw.',
  useDeleverage: 'Use deleverage',
  errorDeleverage: 'Please select token to repay while deleverage',
  errorRepayAmount: 'Please input the correct repay amount',
  errorWithdrawAmount: 'Please input the correct withdraw amount',
});
