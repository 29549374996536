import React, { ReactNode } from 'react';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import staticStyles from './style';

interface TableHeaderWrapperProps {
  children: ReactNode;
  color?: 'dark' | 'light';
}

export default function TableHeaderWrapper({ color, children }: TableHeaderWrapperProps) {
  const { currentTheme } = useThemeContext();

  return (
    <div className="TableHeaderWrapper">
      {children}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true}>{`
        .TableHeaderWrapper {
          // border-bottom: solid 1px ${currentTheme.cream.hex};
          // padding-bottom: 12px;
          // border-top: solid 1px ${currentTheme.darkBlue.hex};
        }
      `}</style>
    </div>
  );
}
