import { Network } from '@sturdyfi/sturdy-js';
import { MarketDataType } from '../../helpers/markets/markets-data';

import * as logos from './images';

export enum CustomMarket {
  proto_kovan = 'proto_kovan',
  proto_goerli = 'proto_goerli',
  proto_mainnet = 'proto_mainnet',
  proto_avalanche = 'proto_avalanche',
  avalanche_fork = 'avalanche_fork',
  proto_matic = 'proto_matic',
  proto_mumbai = 'proto_mumbai',
  proto_fork = 'proto_fork',
  // amm_kovan = 'amm_kovan',
  // amm_mainnet = 'amm_mainnet',
  // amm_fork = 'amm_fork',
  polygon_fork = 'polygon_fork',
  proto_fuji = 'proto_fuji',
  proto_ftm = 'proto_ftm',
  proto_ftm_test = 'proto_ftm_test',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.proto_kovan]: {
    network: Network.kovan,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'a',
    enabledFeatures: {
      faucet: false,
      governance: false,
      staking: false,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x88757f2f99175387ab4c6a4b3067c77a695b0349'.toLowerCase(),
      LENDING_POOL: '0xE0fBa4Fc209b4948668006B2bE61711b7f465bAe',
      // WETH_GATEWAY: '0xA61ca04DF33B72b235a8A28CfB535bb7A5271B70',
      // FAUCET: '0x600103d518cC5E8f3319D532eB4e5C268D32e604',
    },
  },
  [CustomMarket.proto_goerli]: {
    network: Network.goerli,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'a',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
      faucet: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xf7895a2be7082980E60C427E3E5F32067c9ED333'.toLowerCase(),
      LENDING_POOL: '0x53f4569958A9487598e2aa9db6c1E9BCaF81ea4c',
      // WETH_GATEWAY: '0xcc9a0B7c43DC2a5F023Bb9b738E45B0Ef6B06E04',
      // REPAY_WITH_COLLATERAL_ADAPTER: '0x498c5431eb517101582988fbb36431ddaac8f4b1',
      // SWAP_COLLATERAL_ADAPTER: '0x135896DE8421be2ec868E0b811006171D9df802A',
      LIDO_VAULT: '0x43b0aB1DacBFB33bd558444344F0Ed6515153b52',
      FAUCET: '0x6702403fffB739ADAd805e582458713726f94d97',
    },
  },
  [CustomMarket.proto_mainnet]: {
    network: Network.mainnet,
    logo: logos.eth,
    activeLogo: logos.eth,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    flashloanSlippage: 0.02,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xb7499a92fc36e9053a4324aFfae59d333635D9c3'.toLowerCase(),
      LENDING_POOL: '0xA422CA380bd70EeF876292839222159E41AAEe17',
      LIDO_VAULT: '0x01c05337354aae5345d27d2A4A70B56a17aF2b4a',
      CONVEX_FRAX_3CRV_VAULT: '0xA77D72A6C3fA97F091E9aa9F0C87e9efCB552628',
      CONVEX_DAI_USDC_USDT_SUSD_VAULT: '0x9700E0B9d22fE10fF00170462c98aBfFa2505DE2',
      CONVEX_IRON_BANK_VAULT: '0xCD1B342cd04A29D7ae86aE54FfCCB8b4580a7192',
      CONVEX_FRAX_USDC_VAULT: '0xf8C91E5BfFDD79Ef7D1d6F9ae3391E021fFCBD73',
      CONVEX_MIM_3CRV_VAULT: '0xe2Cac13bbff65d30BA31C1709B73c60573cFbDA0',
      CONVEX_TUSD_FRAXBP_VAULT: '0x994A8C1AD6C6A65E8D8c6b37A9c8F964A6C985A8',
      CONVEX_FRAX_3CRV_LEVSWAPPER: '0x812F220f7e79B3Df1EF7bF9Df0d5AbeAb58f95E3',
      CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER: '0xB0E64f8E2F735857CE9441356623452E72Fb4d5f',
      CONVEX_FRAX_USDC_LEVSWAPPER: '0xcEA4FB7D31856F1Ec25Cad53a519039753B01fd1',
      CONVEX_IRON_BANK_LEVSWAPPER: '0x01Eac83b91158e09EaE57a86eE4538134ff47e26',
      CONVEX_MIM_3CRV_LEVSWAPPER: '0x4b00AbCA8276Aef29adA38f8b4fC386203F71bc4',
      CONVEX_TUSD_FRAXBP_LEVSWAPPER: '0xaaCb05194c7864519116493C5d42353D2b658ecF',
    },
  },
  [CustomMarket.proto_fork]: {
    network: Network.fork,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'a',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    flashloanSlippage: 0.02,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x3a5e7db2E0EA9e69fB53Cd8582e64D4001746E8c'.toLowerCase(),
      LENDING_POOL: '0x2A4d822BFB34d377c978F28a6C332Caa2fF87530',
      LIDO_VAULT: '0x01c05337354aae5345d27d2A4A70B56a17aF2b4a',
      CONVEX_FRAX_3CRV_VAULT: '0xb2E308Ef09697ed2b2d740c769CD3b0246DD1e6c',
      CONVEX_DAI_USDC_USDT_SUSD_VAULT: '0xF44275a19A24D016364ddD9541FC9B17735De2f2',
      CONVEX_IRON_BANK_VAULT: '0x8c30CE2bCE4523681DA9644cA63e1E3f5f41c3d6',
      CONVEX_FRAX_USDC_VAULT: '0xAd7D921B94aDA77A38AbE4c70049574b639BBEBA',
      CONVEX_MIM_3CRV_VAULT: '0xfDEba3d5Fd0819864FD652f5d6b0beC7a3DE5BF8',
      CONVEX_TUSD_FRAXBP_VAULT: '0x994A8C1AD6C6A65E8D8c6b37A9c8F964A6C985A8',
      CONVEX_FRAX_3CRV_LEVSWAPPER: '0x7bCeDa3f6554c5fC7AD043840a8546DbE8b90402',
      CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER: '0xFa73E18353C9a44b677090708E46e7d1bCed2515',
      CONVEX_FRAX_USDC_LEVSWAPPER: '0x882A796B6f1f1679BBa1dd127EcE41Fd4f7aEacc',
      CONVEX_IRON_BANK_LEVSWAPPER: '0xD436e756Cf41318ADeC62E8dCbEF2608753Ae068',
      CONVEX_MIM_3CRV_LEVSWAPPER: '0xed60B470AD09D99aeF8363F52d75910Bd0079bBa',
      CONVEX_TUSD_FRAXBP_LEVSWAPPER: '0x72352d5Db60027a902a0AE6CF6815A11eB639Edb',
    },
  },
  // [CustomMarket.amm_kovan]: {
  //   network: Network.kovan,
  //   logo: logos.ammLogo,
  //   activeLogo: logos.ammActiveLogo,
  //   aTokenPrefix: 'aAmm',
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0x67FB118A780fD740C8936511947cC4bE7bb7730c'.toLowerCase(),
  //     LENDING_POOL: '0x762E2a3BBe729240ea44D31D5a81EAB44d34ef01',
  //     // WETH_GATEWAY: '0xA61ca04DF33B72b235a8A28CfB535bb7A5271B70',
  //     // FAUCET: '0x600103d518cC5E8f3319D532eB4e5C268D32e604',
  //   },
  // },
  // [CustomMarket.amm_mainnet]: {
  //   network: Network.mainnet,
  //   logo: logos.ammLogo,
  //   activeLogo: logos.ammActiveLogo,
  //   aTokenPrefix: 'aAmm',
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0xacc030ef66f9dfeae9cbb0cd1b25654b82cfa8d5'.toLowerCase(),
  //     LENDING_POOL: '0x7937d4799803fbbe595ed57278bc4ca21f3bffcb',
  //     // WETH_GATEWAY: '0xcc9a0B7c43DC2a5F023Bb9b738E45B0Ef6B06E04',
  //   },
  // },
  // [CustomMarket.amm_fork]: {
  //   network: Network.fork,
  //   logo: logos.ammLogo,
  //   activeLogo: logos.ammActiveLogo,
  //   aTokenPrefix: 'aAmm',
  //   addresses: {
  //     LENDING_POOL_ADDRESS_PROVIDER: '0xacc030ef66f9dfeae9cbb0cd1b25654b82cfa8d5'.toLowerCase(),
  //     LENDING_POOL: '0x7937d4799803fbbe595ed57278bc4ca21f3bffcb',
  //     // WETH_GATEWAY: '0xcc9a0B7c43DC2a5F023Bb9b738E45B0Ef6B06E04',
  //   },
  // },
  [CustomMarket.proto_matic]: {
    network: Network.polygon,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.polygon,
    aTokenPrefix: 'AM',
    enabledFeatures: {
      liquiditySwap: false,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xd05e3E715d945B59290df0ae8eF85c1BdB684744'.toLowerCase(),
      LENDING_POOL: '0x8dFf5E27EA6b7AC08EbFdf9eB090F32ee9a30fcf',
      // WETH_GATEWAY: '0xbEadf48d62aCC944a06EEaE0A9054A90E5A7dc97',
      // SWAP_COLLATERAL_ADAPTER: '0x35784a624D4FfBC3594f4d16fA3801FeF063241c',
    },
  },
  [CustomMarket.polygon_fork]: {
    network: Network.polygon_fork,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.polygon,
    aTokenPrefix: 'AM',
    enabledFeatures: {
      liquiditySwap: false,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xd05e3E715d945B59290df0ae8eF85c1BdB684744'.toLowerCase(),
      LENDING_POOL: '0x8dFf5E27EA6b7AC08EbFdf9eB090F32ee9a30fcf',
      // WETH_GATEWAY: '0xbEadf48d62aCC944a06EEaE0A9054A90E5A7dc97',
      // SWAP_COLLATERAL_ADAPTER: '0x35784a624D4FfBC3594f4d16fA3801FeF063241c',
    },
  },
  [CustomMarket.proto_mumbai]: {
    network: Network.mumbai,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.polygon,
    aTokenPrefix: 'AM',
    enabledFeatures: {
      incentives: true,
      faucet: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x178113104fEcbcD7fF8669a0150721e231F0FD4B'.toLowerCase(),
      LENDING_POOL: '0x9198F13B08E299d85E096929fA9781A1E3d5d827',
      // WETH_GATEWAY: '0xee9eE614Ad26963bEc1Bec0D2c92879ae1F209fA',
      // FAUCET: '0x0b3C23243106A69449e79C14c58BB49E358f9B10',
    },
  },
  [CustomMarket.proto_fuji]: {
    network: Network.fuji,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.avalanche,
    aTokenPrefix: 'AAVA',
    enabledFeatures: {
      faucet: false,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x7fdC1FdF79BE3309bf82f4abdAD9f111A6590C0f'.toLowerCase(),
      LENDING_POOL: '0x76cc67FF2CC77821A70ED14321111Ce381C2594D',
      // WETH_GATEWAY: '0x1648C14DbB6ccdd5846969cE23DeEC4C66a03335',
      // FAUCET: '0x90E5BAc5A98fff59617080848959f44eACB4Cd7B',
    },
  },
  [CustomMarket.proto_avalanche]: {
    network: Network.avalanche,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.avalanche,
    aTokenPrefix: 'AV',
    enabledFeatures: {
      liquiditySwap: false,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xb6A86025F0FE1862B372cb0ca18CE3EDe02A318f'.toLowerCase(),
      LENDING_POOL: '0x4F01AeD16D97E3aB5ab2B501154DC9bb0F1A5A2C',
      // WETH_GATEWAY: '0x8a47F74d1eE0e2edEB4F3A7e64EF3bD8e11D27C8',
      // SWAP_COLLATERAL_ADAPTER: '0x2EcF2a2e74B19Aab2a62312167aFF4B78E93B6C5',
    },
  },
  [CustomMarket.avalanche_fork]: {
    network: Network.avalanche_fork,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.avalanche,
    aTokenPrefix: 'AV',
    enabledFeatures: {
      liquiditySwap: false,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xb6A86025F0FE1862B372cb0ca18CE3EDe02A318f'.toLowerCase(),
      LENDING_POOL: '0x4F01AeD16D97E3aB5ab2B501154DC9bb0F1A5A2C',
      // WETH_GATEWAY: '0x8a47F74d1eE0e2edEB4F3A7e64EF3bD8e11D27C8',
      // SWAP_COLLATERAL_ADAPTER: '0x2EcF2a2e74B19Aab2a62312167aFF4B78E93B6C5',
    },
  },
  [CustomMarket.proto_ftm]: {
    network: Network.ftm,
    logo: logos.ftm,
    activeLogo: logos.ftm,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x3B8569df88A70ECAE31a6bCA1fc3d51BD426189d'.toLowerCase(),
      LENDING_POOL: '0x7FF2520Cd7b76e8C49B5DB51505b842d665f3e9A',
      YEARN_VAULT: '0xc7506f6e48Aa399e571BDc0e15FC608F05375127',
      YEARN_WETH_VAULT: '0x51Dd37AD6BC71629b348F77ab5C978b7CeD931f3',
      YEARN_WBTC_VAULT: '0x94c5Cf77E26615bae9978a06dD8DE0e0a8F7A4EC',
      YEARN_BOO_VAULT: '0xc54cB7d967D139e4FcD414b4Eed2b0Fc19a1461E',
      TOMB_MIMATIC_BEEFY_VAULT: '0x3C0238B16dBA2D11Af549954abA9bfd75A074236',
      BASED_MIMATIC_BEEFY_VAULT: '0xbF9Cc228AEC3D5A80cA44E295E8f029E2D84d0AE',
      YEARN_FBEETS_VAULT: '0xEDb658f0Cf6FF9e25922500B9EC0c97a681B2559',
      YEARN_LINK_VAULT: '0x860544A91A432819C3DCF4B6BB7B6eFF8D2701c2',
      YEARN_SPELL_VAULT: '0x015DE603a68F2EC3A783bc609E1d20906D8Ec675',
      YEARN_CRV_VAULT: '0x5A427C7dE1E27133D292D23a00EBF365c672c87E',
    },
  },
  [CustomMarket.proto_ftm_test]: {
    network: Network.ftm_test,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
      faucet: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x808Ab7D913c2C6db826C7C83B9D2C1316E43E1cA'.toLowerCase(),
      LENDING_POOL: '0x7b07169cCB3CcEe717498e1Cefe6049De02f588D',
      YEARN_VAULT: '0xaAb1BCFF466ABa124327C3135D1F96C0fFefaE93',
      YEARN_WETH_VAULT: '0x6b9ba59c56f096d16D755DF3395b6b66d35d13e1',
      YEARN_WBTC_VAULT: '0x2dd4be604EC805E045bf7d014eBdAdD558cd248e',
      YEARN_BOO_VAULT: '0xDc202B24147910484e2D1b6f385FEf886cA87B6F',
      YEARN_FBEETS_VAULT: '',
      YEARN_LINK_VAULT: '',
      YEARN_SPELL_VAULT: '',
      YEARN_CRV_VAULT: '',
      TOMB_FTM_BEEFY_VAULT: '0x0196D782c84bB31B50C5349e9498D7975D127e4c',
      TOMB_MIMATIC_BEEFY_VAULT: '0x28Ceee5212aA73C4Ac4d635542fE97B4128A5dEf',
      BASED_MIMATIC_BEEFY_VAULT: '0x8706C35bDD49c6029b8113cf02C5544AA5E2375d',
      FAUCET: '0xaDeF6a09F84037ab69Ea88A9b5aa5CA689E58B82',
    },
  },
} as const;
