import React from 'react';
import BigNumber from 'bignumber.js';

import TableItem from '../../../../components/BasicAssetsTable/TableItem';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';
import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
import NoData from '../../../../components/basic/NoData';
import { isAssetStable } from '../../../../helpers/markets/assets';
import TableButtonsWrapper from '../../../dashboard/components/DashboardTable/TableButtonsWrapper';
import TableButtonCol from '../../../dashboard/components/DashboardTable/TableButtonCol';

import { BorrowTableItem } from './types';

export default function BorrowItem({
  id,
  symbol,
  decimals,
  underlyingAsset,
  availableBorrows,
  availableBorrowsInUSD,
  currentBorrows,
  stableBorrowRate,
  variableBorrowRate,
  avg30DaysVariableRate,
  stableBorrowRateEnabled,
  userId,
  isFreezed,
  vincentivesAPR,
  sincentivesAPR,
  borrowingEnabled,
}: BorrowTableItem) {
  const availableToBorrow = new BigNumber(availableBorrows)
    .times(Math.pow(10, decimals).toString())
    .gte(1);
  const hasNoBorrows = Number(currentBorrows) <= 0;

  return (
    <TableItem symbol={symbol} isFreezed={isFreezed} isBorrow={true} darkOnDarkMode={true}>
      <TableColumn>
        {!userId || !availableToBorrow ? (
          <NoData color="dark" />
        ) : (
          <Value
            value={Number(availableBorrows)}
            subValue={availableBorrowsInUSD}
            subSymbol="USD"
            maximumSubValueDecimals={2}
            minimumValueDecimals={isAssetStable(symbol) || borrowingEnabled ? 2 : 5}
            maximumValueDecimals={isAssetStable(symbol) || borrowingEnabled ? 2 : 5}
          />
        )}
      </TableColumn>

      {!isFreezed && (
        <TableColumn>
          <LiquidityMiningCard
            value={variableBorrowRate}
            thirtyDaysValue={avg30DaysVariableRate}
            liquidityMiningValue={vincentivesAPR}
            symbol={symbol}
            type="borrow-variable"
          />
        </TableColumn>
      )}

      {/* {!isFreezed && (
        <TableColumn>
          {stableBorrowRateEnabled ? (
            <LiquidityMiningCard
              value={stableBorrowRate}
              liquidityMiningValue={sincentivesAPR}
              symbol={symbol}
              type="borrow-stable"
            />
          ) : (
            <NoData color="dark" />
          )}
        </TableColumn>
      )} */}
      <TableColumn>
        <TableButtonsWrapper>
          <TableButtonCol
            // disabled={!availableToBorrow && availableBorrows !== 0}
            title={'Borrow'}
            linkTo={`/borrow/${symbol}-${id}`}
          />
          <TableButtonCol
            disabled={hasNoBorrows}
            title={'Repay'}
            linkTo={`/repay/${symbol}-${id}`}
          />
        </TableButtonsWrapper>
      </TableColumn>
    </TableItem>
  );
}
