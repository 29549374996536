import React from 'react';
import { useIntl } from 'react-intl';
import {
  calculateHealthFactorFromBalancesBigUnits,
  valueToBigNumber,
  API_ETH_MOCK_ADDRESS,
  Network,
} from '@sturdyfi/sturdy-js';

import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useTxBuilderContext } from '../../../../libs/tx-provider';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';
import NoDataPanel from '../../../../components/NoDataPanel';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import HealthFactor from '../../../../components/HealthFactor';
import DepositCurrencyWrapper from '../../components/ProvideCollateralWrapper';
import { getAssetInfo } from '../../../../helpers/markets/assets';
import { useProvideCollateralContext } from '../../../../components/wrappers/ScreensWrapper';

import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import { mapChainIdToName } from 'src/libs/web3-data-provider';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { AmplitudeEventType, sendAmplitudeEvent } from 'src/helpers/amplitude';
import { getDefaultNetworkNameByString } from 'src/config';
import GeneralVaultInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v2/GeneralVault';

function DepositConfirmation({
  currencySymbol,
  poolReserve,
  amount,
  user,
  userReserve,
  walletBalance,
  walletEthBalance,
}: ValidationWrapperComponentProps) {
  const intl = useIntl();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { networkConfig } = useProtocolDataContext();
  const { chainId } = useWeb3React<providers.Web3Provider>();
  const {
    lidoVault,
    convexFRAX3CRVVault,
    convexDAIUSDCUSDTSUSDVault,
    convexIronBankVault,
    convexFRAXUSDCVault,
    convexMIM3CRVVault,
    convexTUSDFRAXBPVault,
    yearnVault,
    yearnWETHVault,
    yearnWBTCVault,
    yearnBOOVault,
    yearnFBeetsVault,
    yearnLINKVault,
    yearnSPELLVault,
    yearnCRVVault,
    tombFtmBeefyVault,
    tombMiMaticBeefyVault,
    basedMiMaticBeefyVault,
  } = useTxBuilderContext();
  const currentWalletNetwork = mapChainIdToName(chainId as number) as Network;
  const aTokenData = getAtokenInfo({
    address: poolReserve.aTokenAddress,
    symbol: currencySymbol,
    decimals: poolReserve.decimals,
  });
  const assetDetails = getAssetInfo(poolReserve.symbol);
  const symbolToVault: { [key: string]: { [key: string]: GeneralVaultInterface } } = {
    [Network.ftm]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.ftm_test]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.mainnet]: {
      stETH: lidoVault,
      FRAX_3CRV_LP: convexFRAX3CRVVault,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDVault,
      IRON_BANK_LP: convexIronBankVault,
      FRAX_USDC_LP: convexFRAXUSDCVault,
      MIM_3CRV_LP: convexMIM3CRVVault,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPVault,
    },
    [Network.fork]: {
      stETH: lidoVault,
      FRAX_3CRV_LP: convexFRAX3CRVVault,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDVault,
      IRON_BANK_LP: convexIronBankVault,
      FRAX_USDC_LP: convexFRAXUSDCVault,
      MIM_3CRV_LP: convexMIM3CRVVault,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPVault,
    },
  };

  if (!amount) {
    return null;
  }
  if (!user) {
    return (
      <NoDataPanel
        title={intl.formatMessage(messages.connectWallet)}
        description={intl.formatMessage(messages.connectWalletDescription)}
        withConnectButton={true}
      />
    );
  }

  const { tokenSymbol } = useProvideCollateralContext();
  let blockingError = '';
  if (
    (tokenSymbol === networkConfig.baseAsset && walletEthBalance.lt(amount)) ||
    (tokenSymbol !== networkConfig.baseAsset && walletBalance.lt(amount))
  ) {
    blockingError = intl.formatMessage(messages.errorWalletBalanceNotEnough, {
      poolReserveSymbol: assetDetails.formattedSymbol || assetDetails.symbol,
    });
  }

  const amountIntEth = amount.multipliedBy(poolReserve.price.priceInEth);
  const amountInUsd = amountIntEth.dividedBy(marketRefPriceInUsd);
  const totalCollateralETHAfter = valueToBigNumber(user.totalCollateralETH).plus(amountIntEth);

  const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralETH)
    .multipliedBy(user.currentLiquidationThreshold)
    .plus(amountIntEth.multipliedBy(poolReserve.reserveLiquidationThreshold))
    .dividedBy(totalCollateralETHAfter);

  const healthFactorAfterDeposit = calculateHealthFactorFromBalancesBigUnits(
    totalCollateralETHAfter,
    valueToBigNumber(user.totalBorrowsETH),
    liquidationThresholdAfter
  );

  const handleGetTransactions = async () => {
    let vault = lidoVault;
    for (const [key, value] of Object.entries(symbolToVault?.[currentWalletNetwork])) {
      if (networkConfig.collateralAssets?.[key]?.includes(tokenSymbol)) {
        vault = value;
        break;
      }
    }

    return await vault.depositCollateral({
      _user: user.id,
      _asset:
        tokenSymbol === networkConfig.baseAsset
          ? API_ETH_MOCK_ADDRESS
          : networkConfig.collateralAddresses?.[tokenSymbol] || poolReserve.underlyingAsset,
      _amount: amount.toString(),
    });
  };

  const notShowHealthFactor = user.totalBorrowsETH !== '0' && poolReserve.usageAsCollateralEnabled;

  // const usageAsCollateralEnabledOnDeposit =
  //   poolReserve.usageAsCollateralEnabled &&
  //   (!userReserve?.underlyingBalance ||
  //     userReserve.underlyingBalance === '0' ||
  //     userReserve.usageAsCollateralEnabledOnUser);

  const sendAmplitudeEventByType = (eventType: string) => () => {
    sendAmplitudeEvent(user.id, eventType, {
      network: getDefaultNetworkNameByString(),
      reserve: poolReserve.symbol,
      amount: +amount,
      value: +amountInUsd,
    });
  };

  return (
    <DepositCurrencyWrapper
      currencySymbol={currencySymbol}
      walletBalance={walletBalance}
      poolReserve={poolReserve}
      user={user}
      userReserve={userReserve}
    >
      <PoolTxConfirmationView
        mainTxName={intl.formatMessage(defaultMessages.deposit)}
        caption={intl.formatMessage(messages.caption)}
        boxTitle={intl.formatMessage(defaultMessages.deposit)}
        boxDescription={intl.formatMessage(messages.boxDescription)}
        approveDescription={intl.formatMessage(messages.approveDescription)}
        getTransactionsData={handleGetTransactions}
        blockingError={blockingError}
        aTokenData={aTokenData}
        onMainTxConfirmed={sendAmplitudeEventByType(AmplitudeEventType.provide)}
        onSubmitTransaction={sendAmplitudeEventByType(AmplitudeEventType.uncertain_provide)}
      >
        <Row title={intl.formatMessage(messages.valueRowTitle)} withMargin={notShowHealthFactor}>
          <Value
            symbol={tokenSymbol}
            value={amount.toString()}
            tokenIcon={true}
            subValue={amountInUsd.toString()}
            subSymbol="USD"
            tooltipId={currencySymbol}
          />
        </Row>
        {notShowHealthFactor && (
          <HealthFactor
            title={intl.formatMessage(messages.newHealthFactor)}
            withoutModal={true}
            value={healthFactorAfterDeposit.toString()}
          />
        )}
      </PoolTxConfirmationView>
    </DepositCurrencyWrapper>
  );
}

export default routeParamValidationHOC({
  withAmount: true,
  withWalletBalance: true,
})(DepositConfirmation);
