import React from 'react';
import { useIntl } from 'react-intl';
import { CustomTooltip, TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import classNames from 'classnames';

import Value from '../basic/Value';
import Link from '../basic/Link';
import DefaultButton from '../basic/DefaultButton';
import defaultMessages from '../../defaultMessages';
import staticStyles from './style';

import tribeIcon from '../../images/tirbe.svg';
import { REWARD_TYPE } from 'src/helpers/markets/markets-data';

interface IncentiveClaimItemProps {
  symbol: string;
  claimableRewards: string;
  incentiveControllerAddress: string;
  type?: string;
}

export default function IncentiveClaimItem({
  symbol,
  claimableRewards,
  incentiveControllerAddress,
  type,
}: IncentiveClaimItemProps) {
  const intl = useIntl();
  const { currentTheme, xl, sm, isCurrentThemeDark } = useThemeContext();

  const iconSize = xl && !sm ? 16 : 20;

  const rewardClaimLink = !type
    ? `/rewards/confirm/${incentiveControllerAddress}`
    : type === REWARD_TYPE.STABLE
    ? `/stable-rewards/confirm/${incentiveControllerAddress}`
    : `/variable-rewards/confirm/${incentiveControllerAddress}`;
  const tooltipId = `incentiveClaimItem--${symbol}`;

  const disabled = claimableRewards === '0';

  return (
    <div className="IncentiveClaimItem" data-tip={true} data-for={tooltipId}>
      <div className="IncentiveClaimItem__valueInner">
        <Value value={claimableRewards} compact={true} color={sm ? 'dark' : 'white'} />
        {symbol === 'TRIBE' ? (
          <img
            className="IncentiveClaimItem__icon"
            src={tribeIcon}
            style={{ width: iconSize, height: iconSize }}
            alt=""
          />
        ) : (
          <TokenIcon tokenSymbol={symbol} height={iconSize} width={iconSize} />
        )}
      </div>

      <Link to={rewardClaimLink} className="ButtonLink" disabled={disabled}>
        <DefaultButton
          title={intl.formatMessage(defaultMessages.claim)}
          color="white"
          transparent={true}
          className="Button"
          size="small"
          disabled={disabled}
        />
      </Link>
      {!sm && (
        <CustomTooltip
          tooltipId={tooltipId}
          text={`${Number(claimableRewards).toFixed(10)} ${symbol}`}
        />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .IncentiveClaimItem {
          .Link {
            color: ${sm && !isCurrentThemeDark
              ? currentTheme.secondary.hex
              : currentTheme.lightBlue.hex};
            &:hover {
              color: ${currentTheme.secondary.hex};
            }
          }

          &__valueInner {
            .Value__value {
              color: ${currentTheme.cream.hex};
            }
          }
        }
      `}</style>
    </div>
  );
}
